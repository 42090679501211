import { translate } from "@ollie-sports/i18n";
import { SoccerStatKeysObj, SoccerStatModes, GameStatKeys } from "@ollie-sports/models";
import _ from "lodash";
import { StatDefBase } from "./types";

export interface GameStatDef extends StatDefBase {
  type: "game";
  tabCategory: "none";
  statKey: GameStatKeys;
}

export const getGameSoccerStatDefs = _.memoize((locale: string): Record<GameStatKeys, GameStatDef> => {
  return {
    [SoccerStatKeysObj.gPlayingTimeMS]: {
      statKey: SoccerStatKeysObj.gPlayingTimeMS,
      type: "game",
      tabCategory: "none",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "ms",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Total Game Minutes", serverLocale: locale }),
      prettyShortName: translate({ defaultMessage: "Total Game Minutes", serverLocale: locale }),
      prettyDefinition: translate({
        defaultMessage: "The total number of minutes played. Includes time played during stoppage time.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.gDidLose]: {
      statKey: SoccerStatKeysObj.gDidLose,
      type: "game",
      tabCategory: "none",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Team Lost", description: "Our team lost the game", serverLocale: locale }),
      prettyShortName: translate({ defaultMessage: "Team Lost", description: "Our team lost the game", serverLocale: locale }),
      prettyDefinition: translate({ defaultMessage: "If team lost the game. 1 if true.", serverLocale: locale })
    },
    [SoccerStatKeysObj.gDidWin]: {
      statKey: SoccerStatKeysObj.gDidWin,
      type: "game",
      tabCategory: "none",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Team Won", description: "Our team won the game", serverLocale: locale }),
      prettyShortName: translate({ defaultMessage: "Team Won", description: "Our team won the game", serverLocale: locale }),
      prettyDefinition: translate({ defaultMessage: "If team won the game. 1 if true.", serverLocale: locale })
    },
    [SoccerStatKeysObj.gDidTie]: {
      statKey: SoccerStatKeysObj.gDidTie,
      type: "game",
      tabCategory: "none",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Team Tied", description: "Our team tied the game", serverLocale: locale }),
      prettyShortName: translate({ defaultMessage: "Team Tied", description: "Our team tied the game", serverLocale: locale }),
      prettyDefinition: translate({ defaultMessage: "If team tied the game. 1 if true.", serverLocale: locale })
    }
  };
});
// i18n certified - complete

import {
  SoccerPositionTypes,
  SoccerPositionNumber,
  SoccerGame,
  positionNumberToPositionType,
  SGE_substitute,
  SoccerGameEvent,
  SoccerGameEventType,
  SoccerIds,
  SoccerFormationKeys,
  SGE_switchFormation,
  getFormationSize,
  SoccerFormationsByKey,
  SGE_yellowCardInfraction,
  SGE_redCardInfraction,
  PrettyPlayer,
  Player,
  PlayerId
} from "@ollie-sports/models";
import _ from "lodash";
import { isDevelopmentEnv } from "../internal-utils/misc";
import { isOpponentPlayer } from "./SoccerFns";
import { SoccerCardEvent, SoccerSubEvent } from "./SoccerEventCategories";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { translate } from "@ollie-sports/i18n";

export interface ConvenienceSoccerGamePlayer {
  id: string;
  teamId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  firstNamePlus: string; //The players name plus extra characters if there are first name collisions
  jerseyNumber: string;
  isEjected?: true;
  fieldPositionType?: SoccerPositionTypes;
  fieldPositionNumber?: SoccerPositionNumber;
  profileImageUri?: string;
  profileImageUriSmall?: string;
}

function getOpponentFieldPlayer(locale: string): ConvenienceSoccerGamePlayer {
  return {
    id: SoccerIds.opponentFieldPlayer,
    teamId: SoccerIds.opponentTeamId,
    firstName: translate({
      defaultMessage: "Opp. Player",
      description: "Short version of Opponent Player",
      serverLocale: locale
    }),
    firstNamePlus: translate({
      defaultMessage: "Opp. Player",
      description: "Short version of Opponent Player",
      serverLocale: locale
    }),
    fullName: translate({ defaultMessage: "Opp. Player", description: "Short version of Opponent Player", serverLocale: locale }),
    lastName: "",
    jerseyNumber: ""
  };
}

function getOpponentGenericPlayer(locale: string): ConvenienceSoccerGamePlayer {
  return {
    id: SoccerIds.opponentGenericPlayer,
    teamId: SoccerIds.opponentTeamId,
    firstName: translate({
      defaultMessage: "Opp. Team",
      description: "Short version of Opponent Team",
      serverLocale: locale
    }),
    firstNamePlus: translate({
      defaultMessage: "Opp. Team",
      description: "Short version of Opponent Team",
      serverLocale: locale
    }),
    fullName: translate({ defaultMessage: "Opp. Team", description: "Short version of Opponent Team", serverLocale: locale }),
    lastName: "",
    jerseyNumber: ""
  };
}

function getOpponentGoalie(locale: string): ConvenienceSoccerGamePlayer {
  return {
    id: SoccerIds.opponentGoalie,
    teamId: SoccerIds.opponentTeamId,
    firstName: translate({
      defaultMessage: "Opp. GK",
      description: "Short version of Opponent Goalkeeper",
      serverLocale: locale
    }),
    firstNamePlus: translate({
      defaultMessage: "Opp. GK",
      description: "Short version of Opponent Goalkeeper",
      serverLocale: locale
    }),
    fullName: translate({ defaultMessage: "Opp. GK", description: "Short version of Opponent Goalkeeper", serverLocale: locale }),
    lastName: "",
    jerseyNumber: ""
  };
}

function getOwnTeamGenericPlayer(locale: string): ConvenienceSoccerGamePlayer {
  return {
    id: SoccerIds.ownTeamGenericPlayer,
    teamId: "__TO_BE_REPLACED__",
    firstName: translate({
      defaultMessage: "My Team",
      serverLocale: locale
    }),
    firstNamePlus: translate({
      defaultMessage: "My Team",
      serverLocale: locale
    }),
    fullName: translate({ defaultMessage: "My Team", serverLocale: locale }),
    lastName: "",
    jerseyNumber: ""
  };
}

export function GET_STOCK_PLAYERS(locale: string) {
  return {
    opponentFieldPlayer: getOpponentFieldPlayer(locale),
    opponentGenericPlayer: getOpponentGenericPlayer(locale),
    opponentGoalie: getOpponentGoalie(locale),
    ownTeamGenericPlayer: (ownTeamId: string) => ({ ...getOwnTeamGenericPlayer(locale), teamId: ownTeamId })
  };
}

export function computeSoccerGamePlayerRoster(p: {
  players: PrettyPlayer[];
  soccerGame: SoccerGame;
  memoryDB: SoccerEventMemoryDB;
  ignoreEventsAfterTimeMS?: number;
}): {
  roster: ConvenienceSoccerGamePlayer[];
  rosterWithEjectedPlayers: ConvenienceSoccerGamePlayer[];
  rosterWithRecentlyEjectedPlayers: ConvenienceSoccerGamePlayer[];
} {
  const starterIdToPosition = p.soccerGame?.starterIdToPosition || {};

  const firstNamePlusMap = createPlayerIdToFirstNamePlusMap({ players: p.players });

  const playerRoster = p.players.reduce((acc, pl) => {
    let fieldPositionNumber = starterIdToPosition[pl.player.id];
    const fieldPositionType = safePositionNumberToPositionType(fieldPositionNumber, p.soccerGame.startingFormation);

    if (!fieldPositionType) {
      fieldPositionNumber = undefined;
    }

    acc[pl.player.id] = {
      id: pl.player.id,
      teamId: p.soccerGame.teamId,
      firstName: pl.derived.accountInfo.firstName,
      firstNamePlus: firstNamePlusMap[pl.player.id],
      lastName: pl.derived.accountInfo.lastName,
      fullName: [pl.derived.accountInfo.firstName, pl.derived.accountInfo.lastName].join(" "),
      jerseyNumber: pl.player.jerseyNumber,
      fieldPositionNumber,
      fieldPositionType,
      profileImageUri: pl.derived.accountInfo.profileImageUri,
      profileImageUriSmall: pl.derived.accountInfo.profileImageUriSmall
    };
    return acc;
  }, {} as { [id in string]?: ConvenienceSoccerGamePlayer });

  function filterIgnoredEvents(evt: SoccerGameEvent) {
    return p.ignoreEventsAfterTimeMS ? evt.createdAtMS <= p.ignoreEventsAfterTimeMS : true;
  }

  const subEvents = p.memoryDB.presets.subEvents().filter(filterIgnoredEvents);

  const getFormationAtTime = (time: number) => {
    const formationEvents = p.memoryDB.find({ [SoccerGameEventType.switchFormation]: true }).filter(filterIgnoredEvents);

    const changeEvent = formationEvents.find(evt => evt.createdAtMS < time);

    return changeEvent ? changeEvent.newFormation : p.soccerGame.startingFormation;
  };

  subEvents.forEach(evt => {
    Object.keys(evt.playerIdToNewPosition)
      .sort((pid1, pid2) => {
        //Sort to make sure we trigger all the sub outs first so they don't get overwritten
        const a = evt.playerIdToNewPosition[pid1] === "SUB_OUT_MAGIC_CONSTANT" ? 0 : 1;
        const b = evt.playerIdToNewPosition[pid2] === "SUB_OUT_MAGIC_CONSTANT" ? 0 : 1;
        return a < b ? -1 : a > b ? 1 : 0;
      })
      .forEach(playerId => {
        const val = evt.playerIdToNewPosition[playerId];
        const player = playerRoster[playerId];
        if (!player) {
          return;
        }

        if (val === "SUB_OUT_MAGIC_CONSTANT") {
          delete player.fieldPositionNumber;
          delete player.fieldPositionType;
        } else {
          player.fieldPositionNumber = val;
          player.fieldPositionType = safePositionNumberToPositionType(val, getFormationAtTime(evt.createdAtMS));
        }
      });
  });

  const rosterWithEjectedPlayers = _.orderBy(
    Object.values(playerRoster).filter((a): a is ConvenienceSoccerGamePlayer => !!a),
    "firstNamePlus"
  );

  const roster = _.cloneDeep(rosterWithEjectedPlayers);
  const rosterWithRecentlyEjectedPlayers = _.cloneDeep(rosterWithEjectedPlayers);

  const mapOfEjectedPlayers: Record<PlayerId, true> = {};

  //We compute rosterWithRecentlyEjectedPlayers so that its possible to still select a player as a foul recipient when they've also received a red card.
  const recentEjectionCutoff = p.ignoreEventsAfterTimeMS || p.memoryDB.getRawEvents().slice(-2).shift()?.createdAtMS || Infinity;

  const playerIdHasReceivedYellowCard: Record<string, true> = {};
  p.memoryDB.presets
    .cardEvents()
    .filter(filterIgnoredEvents)
    .forEach(evt => {
      if (isOpponentPlayer(evt.playerId)) {
        return;
      }

      if (evt.type === SoccerGameEventType.redCardInfraction) {
        const playerIndex = roster.findIndex(pl => pl.id === evt.playerId);
        if (playerIndex !== -1) {
          const player = roster[playerIndex];
          roster.splice(playerIndex, 1);
          mapOfEjectedPlayers[player.id] = true;
        }

        if (evt.createdAtMS < recentEjectionCutoff) {
          const playerIndex2 = rosterWithRecentlyEjectedPlayers.findIndex(pl => pl.id === evt.playerId);
          playerIndex2 !== -1 && rosterWithRecentlyEjectedPlayers.splice(playerIndex2, 1);
        }
      }

      if (evt.type === SoccerGameEventType.yellowCardInfraction) {
        if (playerIdHasReceivedYellowCard[evt.playerId]) {
          const playerIndex = roster.findIndex(pl => pl.id === evt.playerId);
          if (playerIndex !== -1) {
            const player = roster[playerIndex];
            roster.splice(playerIndex, 1);
            mapOfEjectedPlayers[player.id] = true;
          }
          if (evt.createdAtMS < recentEjectionCutoff) {
            const playerIndex2 = rosterWithRecentlyEjectedPlayers.findIndex(pl => pl.id === evt.playerId);
            playerIndex2 !== -1 && rosterWithRecentlyEjectedPlayers.splice(playerIndex2, 1);
          }
        } else {
          playerIdHasReceivedYellowCard[evt.playerId] = true;
        }
      }
    });

  rosterWithRecentlyEjectedPlayers.concat(rosterWithEjectedPlayers).forEach(pl => {
    if (mapOfEjectedPlayers[pl.id]) {
      pl.isEjected = true;
    }
  });

  return {
    roster,
    rosterWithRecentlyEjectedPlayers,
    rosterWithEjectedPlayers
  };
}

//When a switch in formation happens in a game with FEWER than 11 max field players, this function lets us account
export function getRequiredSubsForSwitchInFormation(p: {
  currFormationKey: SoccerFormationKeys;
  newFormationKey: SoccerFormationKeys;
  currentRoster: ConvenienceSoccerGamePlayer[];
}): Record<string, SoccerPositionNumber> {
  if (getFormationSize(p.currFormationKey) !== getFormationSize(p.newFormationKey)) {
    throw new Error("Formations are not the same size. Unable to compute mapping.");
  }

  const currFormationPositions = Object.keys(SoccerFormationsByKey[p.currFormationKey]);
  const newFormationPositions = Object.keys(SoccerFormationsByKey[p.newFormationKey]);

  const emptyPositions = _.difference(currFormationPositions, newFormationPositions);
  const shadowPositions = _.difference(newFormationPositions, currFormationPositions);

  if (emptyPositions.length !== shadowPositions.length) {
    throw new Error("Empty positions should always be the same length as shadow positions");
  }

  const positionMapping = _.fromPairs(_.zip(emptyPositions, shadowPositions));

  return p.currentRoster.reduce((acc, pl) => {
    const newPosition = positionMapping[pl.fieldPositionNumber as string];
    if (newPosition) {
      acc[pl.id] = newPosition;
    }
    return acc;
  }, {} as Record<string, SoccerPositionNumber>);
}

function safePositionNumberToPositionType(
  fieldPositionNumber: SoccerPositionNumber | undefined,
  formation?: SoccerFormationKeys
) {
  return fieldPositionNumber && formation
    ? positionNumberToPositionType({ formationKey: formation, playerPosition: fieldPositionNumber })
    : undefined;
}

function toTitleCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertPrettyPlayersToBasicConveniencePlayers(p: {
  players: PrettyPlayer[];
  alwaysShowLastInitial?: boolean;
}): ConvenienceSoccerGamePlayer[] {
  const firstNamePlusMap = createPlayerIdToFirstNamePlusMap({
    players: p.players,
    alwaysShowLastInitial: p.alwaysShowLastInitial ?? true
  });
  const conveniencePlayers: ConvenienceSoccerGamePlayer[] = [];

  p.players.map(player => {
    conveniencePlayers.push({
      firstName: player.derived.accountInfo.firstName,
      lastName: player.derived.accountInfo.lastName,
      firstNamePlus: firstNamePlusMap[player.player.id],
      fullName: `${player.derived.accountInfo.firstName} ${player.derived.accountInfo.lastName}`,
      id: player.player.id,
      jerseyNumber: player.player.jerseyNumber,
      teamId: player.player.teamId,
      profileImageUri: player.derived.accountInfo.profileImageUri,
      profileImageUriSmall: player.derived.accountInfo.profileImageUriSmall
    });
  });

  return conveniencePlayers;
}

function createPlayerIdToFirstNamePlusMap(p: {
  players: PrettyPlayer[];
  alwaysShowLastInitial?: boolean;
}): Record<string, string> {
  const playersByFirstName: Record<string, PrettyPlayer[]> = {};
  _.sortBy(p.players, a => a.player.createdAtMS).forEach(pl => {
    const name = pl.derived.accountInfo.firstName.toLowerCase().trim();
    playersByFirstName[name] = playersByFirstName[name] || [];
    playersByFirstName[name].push(pl);
  });

  return p.players.reduce((acc, pl) => {
    let { firstName, lastName } = pl.derived.accountInfo;
    firstName = firstName.trim();
    lastName = lastName.trim();

    let firstNamePlus: string;

    const lcName = firstName.toLowerCase().trim();

    if (lastName && playersByFirstName[lcName] && playersByFirstName[lcName].length > 1) {
      const lastNames = playersByFirstName[lcName].map(pla => pla.derived.accountInfo.lastName.trim().toLowerCase());
      const longest = Math.max(...lastNames.map(a => a.length));
      let initial = String(playersByFirstName[lcName].findIndex(pla => pla.player.id === pl.player.id) + 1);
      for (let i = 0; i < longest; i++) {
        const initials = lastNames.map(ln => ln.slice(0, i + 1));
        if (_.uniq(initials).length === initials.length) {
          initial = toTitleCase(lastName.slice(0, i + 1));
          if (initial.length !== lastName.length) {
            initial += ".";
          }
          break;
        }
      }
      firstNamePlus = `${firstName} ${initial}`;
    } else {
      firstNamePlus = p.alwaysShowLastInitial ? `${firstName} ${lastName[0]}.` : firstName;
    }
    acc[pl.player.id] = firstNamePlus;
    return acc;
  }, {} as Record<string, string>);
}

// i18n certified - complete

import {
  SGE_basicTouch,
  SGE_clearance,
  SoccerGameEvent,
  SoccerGameEventId,
  SoccerGameEventType,
  SoccerPossessionState,
  WhyIncludedInTimelineReasons
} from "@ollie-sports/models";
import _ from "lodash";
import { isSoccerClockEvent, isSoccerCornerKickEvent, isSoccerFreeKickEvent } from "./SoccerEventCategories";
import { isOpponentTeamId } from "./SoccerFns";
import { getEventPossessionState } from "./SoccerPossession";

type EventChecker = {
  id: SoccerGameEventId;
  eventType: SoccerGameEventType;
  type: "offensive" | "defensive";
  isMyTeam: boolean;
  offensiveEventType?: WhyIncludedInTimelineReasons;
};

/** Relevant Offensive Events that warrant Defensive Events to show up in Live Game Mode */
const RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeObject = {
  [SoccerGameEventType.cross]: true,
  [SoccerGameEventType.cornerKickCross]: true,
  [SoccerGameEventType.cornerKickShort]: true,
  [SoccerGameEventType.freeKickCross]: true
};

type RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeType =
  keyof typeof RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeObject;
type RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeEvent = Extract<
  SoccerGameEvent,
  { type: RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeType }
>;
export const isRelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameMode: (
  e: SoccerGameEvent
) => e is RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeEvent = (
  e: SoccerGameEvent
): e is RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeEvent =>
  !!(RelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameModeObject as any)[e.type];

/** Relevant Defensive Events to show up in Live Game Mode following a relevant Offensive Event*/
const RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEventObject = {
  [SoccerGameEventType.basicTouch]: true,
  [SoccerGameEventType.clearance]: true
};

type RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEventType =
  keyof typeof RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEventObject;
type RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent = Extract<
  SoccerGameEvent,
  { type: RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEventType }
>;
export const isRelevantDefensiveEventToToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent: (
  e: SoccerGameEvent
) => e is RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent = (
  e: SoccerGameEvent
): e is RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent =>
  !!(RelevantDefensiveEventToShowUpOnLiveGameModeFollowingRelevantOffensiveEventObject as any)[e.type];

export function findDefensiveEventToIncludeInLiveGameMode(p: { proposedEvents: SoccerGameEvent[] }): {
  soccerGameEventId: SoccerGameEventId;
  whyIncludeInTimeline: WhyIncludedInTimelineReasons;
  eventType: "shot" | "clearance";
} | null {
  let numTaken = 0;
  const last7EventsRaw = _.takeRightWhile(p.proposedEvents, evt => {
    numTaken++;

    if (numTaken > 7 || getEventPossessionState(evt) === SoccerPossessionState.deadBall) {
      return false;
    }

    return true;
  });

  const last7Events = last7EventsRaw
    .map((evt, i) => {
      if (isRelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameMode(evt)) {
        return {
          id: evt.id,
          eventType: evt.type,
          type: "offensive",
          isMyTeam: !isOpponentTeamId(evt.playerTeamId),
          offensiveEventType: isSoccerCornerKickEvent(evt) ? "corner" : isSoccerFreeKickEvent(evt) ? "free-kick" : "cross"
        };
      }
      if (isRelevantDefensiveEventToToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent(evt)) {
        return { id: evt.id, type: "defensive", isMyTeam: !isOpponentTeamId(evt.playerTeamId) };
      }
      return null;
    })
    .filter(e => e !== null) as EventChecker[];

  const lastEvent = last7Events.slice(-1).pop();
  const lastTwoEvents = last7Events.slice(-2);

  if (lastTwoEvents.every(a => a.type === "defensive" && a.isMyTeam === lastEvent?.isMyTeam)) {
    const alreadyClaimedStr = last7Events
      .slice(0, last7Events.length - 1)
      .map(a =>
        a.type === "defensive" && a.isMyTeam === lastEvent?.isMyTeam
          ? "1"
          : a.type === "offensive" && a.isMyTeam !== lastEvent?.isMyTeam
          ? "2"
          : "0"
      )
      .join("");

    // Has to have an offensive event ("2") && either can't have back to back defensive events ("1")
    // or the offensive event has to come after the back to back defensive events.
    if (alreadyClaimedStr.lastIndexOf("2") > alreadyClaimedStr.lastIndexOf("11")) {
      const offensiveEvent = last7Events
        .slice(0, 5)
        .reverse()
        .find(e => e.type === "offensive" && e.isMyTeam !== lastEvent?.isMyTeam);

      if (lastEvent?.eventType === SoccerGameEventType.basicTouch) {
        return {
          eventType: "shot",
          soccerGameEventId: lastTwoEvents[0].id,
          whyIncludeInTimeline: offensiveEvent?.offensiveEventType ?? "cross"
        };
      } else {
        return {
          eventType: "clearance",
          soccerGameEventId: lastTwoEvents[1].id,
          whyIncludeInTimeline: offensiveEvent?.offensiveEventType ?? "cross"
        };
      }
    }
  }
  return null;
}

// i18n certified - complete

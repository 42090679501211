import {
  SoccerGameEventType,
  SoccerGameEvent,
  SoccerIds,
  SoccerGame,
  SoccerStatModes,
  SGE_startOfficialStoppage,
  SGE_pauseGameAndStopClock,
  SoccerGameEventTypeToSGE,
  SoccerPossessionState,
  PauseEventReasons
} from "@ollie-sports/models";
import _ from "lodash";
import { arePlayersSameTeam, isStockPlayer, isOpponentPlayer } from "./SoccerFns";
import {
  isSoccerPassingPossessionEventSetEvent,
  isSoccerNonPassingPossessionEventSetEvent,
  isShotEvent,
  SoccerPassingPossessionEventSetEvent
} from "./SoccerEventCategories";
import { InvertResult } from "../utils";

export { SoccerPossessionState };

const SoccerPossessionMap: {
  [type in SoccerGameEventType]: SoccerPossessionState | ((evt: SoccerGameEventTypeToSGE[type]) => SoccerPossessionState);
} = {
  [SoccerGameEventType.basicTouch]: SoccerPossessionState.player,
  [SoccerGameEventType.cross]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.goaliePunt]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.goalieThrow]: SoccerPossessionState.player,
  [SoccerGameEventType.goalKickLong]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.goalKickPass]: SoccerPossessionState.player,
  [SoccerGameEventType.clearance]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.freeKickLong]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.freeKickCross]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.freeKickPass]: SoccerPossessionState.player,
  [SoccerGameEventType.stab]: SoccerPossessionState.unknown,
  [SoccerGameEventType.dribble]: SoccerPossessionState.unknown,
  [SoccerGameEventType.offsideInfraction]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.foulInfraction]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.handballInfraction]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.yellowCardInfraction]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.redCardInfraction]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.throwIn]: SoccerPossessionState.player,
  [SoccerGameEventType.throwInInfraction]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.cornerKickShort]: SoccerPossessionState.player,
  [SoccerGameEventType.cornerKickCross]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.substitute]: SoccerPossessionState.unknown,
  [SoccerGameEventType.startHalf]: SoccerPossessionState.player,
  [SoccerGameEventType.stopHalf]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.postGoalKickoff]: SoccerPossessionState.player,
  [SoccerGameEventType.outOfBounds]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.looseBallOutOfBounds]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.tackle]: SoccerPossessionState.player,
  [SoccerGameEventType.missedPassTarget]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.shot]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.shotShootoutPK]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.shotWithGoal]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.shotWithGoalManualEntry]: SoccerPossessionState.unknown,
  [SoccerGameEventType.ownTeamGoal]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.forfeitInfractionPK]: SoccerPossessionState.deadBall,
  [SoccerGameEventType.switchFormation]: SoccerPossessionState.unknown,
  [SoccerGameEventType.header]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.pauseGameAndStopClock]: (evt: SGE_pauseGameAndStopClock) => {
    if (isPauseReasonThatMaybeResumesWithPlayerAssignmentOrDropBall(evt.reason)) {
      return SoccerPossessionState.deadBall;
    } else {
      return SoccerPossessionState.unknown;
    }
  },

  [SoccerGameEventType.startOfficialStoppage]: (evt: SGE_startOfficialStoppage) => {
    if (isPauseReasonThatMaybeResumesWithPlayerAssignmentOrDropBall(evt.reason)) {
      return SoccerPossessionState.deadBall;
    } else {
      return SoccerPossessionState.unknown;
    }
  },
  [SoccerGameEventType.resumeGameWithDropBallOnStoppedClock]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: SoccerPossessionState.player,
  [SoccerGameEventType.resumeGameOnStoppedClock]: SoccerPossessionState.unknown,
  [SoccerGameEventType.stopOfficialStoppageWithDropBall]: SoccerPossessionState.looseBall,
  [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: SoccerPossessionState.player,
  [SoccerGameEventType.resumeGameOnRunningClock]: SoccerPossessionState.unknown
} as const;

export type SoccerPossessionInfo = {
  possessionState: SoccerPossessionState;
  possessingPlayerId?: string;
  previouslyPossessingPlayerId?: string;
  lastKnownPossessionEvent: undefined | SoccerGameEvent;
};

export type SoccerPossessionEventSetInfo = {
  passChainPlayerIds: string[];
  startIndex: number;
  events: SoccerGameEvent[];
  type: "own-team" | "opp-team" | null;
};

const isNotUnknown = (e: SoccerGameEvent) => getEventPossessionState(e) !== SoccerPossessionState.unknown;

export function computeSoccerPossessionInfo(events: SoccerGameEvent[]): SoccerPossessionInfo {
  const index = _.findLastIndex(events, isNotUnknown);
  const action = events[index];
  const possessionState = getEventPossessionState(action);

  const prevIndex = _.findLastIndex(events.slice(0, index), isNotUnknown);
  const prevAction = events[prevIndex];
  const prevPossessionState = getEventPossessionState(prevAction);

  return {
    possessionState,
    possessingPlayerId: possessionState === SoccerPossessionState.player && "playerId" in action ? action.playerId : undefined,
    previouslyPossessingPlayerId:
      prevPossessionState === SoccerPossessionState.player && "playerId" in prevAction ? prevAction.playerId : undefined,
    lastKnownPossessionEvent: action
  };
}

export function computeSoccerPossessionInfoForEachEvent(events: SoccerGameEvent[]): SoccerPossessionInfo[] {
  return events.map((evt, i) => {
    return computeSoccerPossessionInfo(events.slice(0, i + 1));
  });
}

export function computeEventIdToPossesionEventSet(events: SoccerGameEvent[]): Record<string, SoccerPossessionEventSetInfo> {
  let currEvents = events.slice();
  const eventIdToSet: Record<string, SoccerPossessionEventSetInfo> = {};
  while (true) {
    const eventSet = computePossessionEventSetInfo(currEvents);

    if (!eventSet.events.length) {
      break;
    }

    eventSet.events.forEach(evt => {
      eventIdToSet[evt.id] = eventSet;
    });

    currEvents = currEvents.slice(0, eventSet.startIndex);
  }

  return eventIdToSet;
}

export function computePossessionEventSetInfo(events: SoccerGameEvent[]): SoccerPossessionEventSetInfo {
  const lastEvent = events[events.length - 1];
  const lastEventPossState = getEventPossessionState(lastEvent);

  const possessingPlayerId =
    lastEvent && (isSoccerPassingPossessionEventSetEvent(lastEvent) || isSoccerNonPassingPossessionEventSetEvent(lastEvent))
      ? lastEvent.playerId
      : null;

  if (!possessingPlayerId) {
    return {
      type:
        lastEvent && lastEventPossState === SoccerPossessionState.player && "playerId" in lastEvent
          ? isOpponentPlayer(lastEvent.playerId)
            ? "opp-team"
            : "own-team"
          : null,
      startIndex: events.length - 1,
      passChainPlayerIds: [],
      events: lastEvent ? [lastEvent] : []
    };
  }

  const possessionEvents: SoccerGameEvent[] = [];

  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];
    if (
      getEventPossessionState(event) === SoccerPossessionState.unknown ||
      shouldNonPassingEventBeAddedToSet({ event, possessingPlayerId, currentPossessionSetLength: possessionEvents.length }) ||
      shouldPassingEventBeAddedToSet({ event, possessingPlayerId })
    ) {
      possessionEvents.unshift(event);
    } else {
      break;
    }
  }

  const passingEvents = possessionEvents.filter(e =>
    shouldPassingEventBeAddedToSet({ event: e, possessingPlayerId: possessingPlayerId })
  ) as SoccerPassingPossessionEventSetEvent[];

  const passChainPlayerIds = passingEvents
    .filter((event, i, arr) => {
      const prevEvent = arr[i - 1];

      //Ignore a super niche case where a player clears the ball and then is the next person to touch it.
      if (prevEvent && event.playerId === prevEvent.playerId && !isStockPlayer(event.playerId)) {
        return false;
      }

      return true;
    })
    .map(e => e.playerId);

  const firstPassEvent = passingEvents[0];
  const startIndex = Math.max(events.length - possessionEvents.length, 0);
  return {
    type: firstPassEvent ? (isOpponentPlayer(firstPassEvent.playerId) ? "opp-team" : "own-team") : null,
    passChainPlayerIds,
    startIndex,
    events: events.slice(startIndex)
  };
}

export function computeAllEventSetInfo(events: SoccerGameEvent[]): SoccerPossessionEventSetInfo[] {
  const eventSets: SoccerPossessionEventSetInfo[] = [];
  let currEvents = events.slice();
  while (true) {
    const eventSet = computePossessionEventSetInfo(currEvents);

    if (!eventSet.events.length) {
      break;
    }

    eventSets.push(eventSet);

    currEvents = currEvents.slice(0, eventSet.startIndex);
  }

  return eventSets;
}

function shouldPassingEventBeAddedToSet(p: { event: SoccerGameEvent; possessingPlayerId: string }) {
  return isSoccerPassingPossessionEventSetEvent(p.event) && arePlayersSameTeam(p.possessingPlayerId, p.event.playerId);
}

function shouldNonPassingEventBeAddedToSet(p: {
  event: SoccerGameEvent;
  possessingPlayerId: string;
  currentPossessionSetLength: number;
}) {
  if (isShotEvent(p.event) && p.currentPossessionSetLength !== 0) {
    return false;
  }
  return isSoccerNonPassingPossessionEventSetEvent(p.event) && arePlayersSameTeam(p.event.playerId, p.possessingPlayerId);
}

function isPauseReasonThatMaybeResumesWithPlayerAssignmentOrDropBall(pauseReason: PauseEventReasons) {
  return pauseReason === "auto-card" || pauseReason === "game-delay" || pauseReason === "manual-main-official-stoppage";
}

//Some players create a lot of "chaos" by losing the ball then immediately regaining it.
//This function detects if an event belongs to one of those chaotic moments of a turnover
//immediately followed by a recovery by the same player
export function isTurnoverWithQuickSelfRecovery(p: {
  playerId: string;
  game: SoccerGame;
  eventIndex: number;
  allEvents: SoccerGameEvent[];
}) {
  if (p.game.statMode === SoccerStatModes.team) {
    return false;
  }

  const evt = p.allEvents[p.eventIndex];
  const possState = getEventPossessionState(evt);

  if (possState !== SoccerPossessionState.player || ("playerId" in evt && evt.playerId !== p.playerId)) {
    return false;
  }

  const twiceNextEvent = p.allEvents[p.eventIndex + 2];
  const nextEvent = p.allEvents[p.eventIndex + 1];

  if (
    evt &&
    nextEvent &&
    twiceNextEvent &&
    "playerId" in evt &&
    "playerId" in nextEvent &&
    "playerId" in twiceNextEvent &&
    getEventPossessionState(evt) === SoccerPossessionState.player &&
    getEventPossessionState(nextEvent) === SoccerPossessionState.player &&
    getEventPossessionState(twiceNextEvent) === SoccerPossessionState.player &&
    !isStockPlayer(evt.playerId) &&
    !arePlayersSameTeam(evt.playerId, nextEvent.playerId) &&
    !arePlayersSameTeam(nextEvent.playerId, twiceNextEvent.playerId) &&
    evt.playerId === twiceNextEvent.playerId
  ) {
    return true;
  }

  return false;
}

export function getEventPossessionState(action: SoccerGameEvent) {
  let possessionState: SoccerPossessionState;
  if (action) {
    const actionVal = SoccerPossessionMap[action.type];
    if (typeof actionVal === "function") {
      possessionState = actionVal(action as any);
    } else {
      possessionState = actionVal;
    }
  } else {
    possessionState = SoccerPossessionState.deadBall;
  }
  return possessionState;
}

// i18n certified - complete

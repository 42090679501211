import { SoccerStatKeysObj, GameStatKeys, SoccerGameEvent } from "@ollie-sports/models";
import { GameStatComputation } from "./types";
import { computeSoccerGamePlayingTimeMS } from "../SoccerGameClock";

export const GameSoccerStatComputations: Record<GameStatKeys, GameStatComputation> = {
  [SoccerStatKeysObj.gPlayingTimeMS]: {
    statKey: SoccerStatKeysObj.gPlayingTimeMS,
    statType: "game",
    compute: ({ game, memoryDB, allEvents }) => {
      const lastEvent = allEvents.slice(-1).pop();
      if (!lastEvent) {
        return 0;
      }

      return computeSoccerGamePlayingTimeMS({
        events: memoryDB.presets.clockEvents(),
        game,
        nowMS: lastEvent.createdAtMS
      });
    }
  },
  [SoccerStatKeysObj.gDidLose]: {
    statKey: SoccerStatKeysObj.gDidLose,
    statType: "game",
    compute: ({ game }) => (game.gameStage === "ended" && game.officiallyEndedGameSummaryStats.gameResult === "loss" ? 1 : 0)
  },
  [SoccerStatKeysObj.gDidWin]: {
    statKey: SoccerStatKeysObj.gDidWin,
    statType: "game",
    compute: ({ game }) => (game.gameStage === "ended" && game.officiallyEndedGameSummaryStats.gameResult === "win" ? 1 : 0)
  },
  [SoccerStatKeysObj.gDidTie]: {
    statKey: SoccerStatKeysObj.gDidTie,
    statType: "game",
    compute: ({ game }) => (game.gameStage === "ended" && game.officiallyEndedGameSummaryStats.gameResult === "tie" ? 1 : 0)
  }
};

// i18n certified - complete

import {
  SoccerGameEventType,
  SoccerGame,
  SoccerHalfNumber,
  PostSetupSoccerGame,
  SoccerPositionTypes,
  PrettyPlayer,
  PlayerId,
  SoccerGameEvent
} from "@ollie-sports/models";

import _ from "lodash";
import { isDevelopmentEnv } from "../internal-utils/misc";
import { computeSoccerGamePlayerRoster } from "./SoccerGamePlayerRoster";
import {
  isSoccerStartClockEvent,
  isStopClockEvent,
  SoccerClockEvent,
  SoccerPauseEventTypeObject,
  isPauseEvent,
  isSoccerResumeEvent
} from "./SoccerEventCategories";
import { SoccerEventMemoryDB, createSoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { minutesAndSecondsStringToMinutes, msToMinutesString } from "../utils/time-strings";
import { ObjectKeys } from "../utils";

export interface BasicSoccerClockData {
  lastCompletedHalf: SoccerHalfNumber | 0;
  lastStartedHalf: SoccerHalfNumber | 0;
  isClockRunning: boolean;
  isBetweenFirstAndLastWhistle: boolean;
  hasGameStarted: boolean;
  hasGameEnded: boolean;
}

export function computeBasicSoccerClockData(p: { events: SoccerClockEvent[]; game: SoccerGame }): BasicSoccerClockData {
  const hasGameStarted = !!p.events.find(e => e.type === SoccerGameEventType.startHalf && e.startsGame);
  const hasGameEnded = !!p.events.find(e => e.type === SoccerGameEventType.stopHalf && e.endsGame);

  return {
    lastCompletedHalf: p.events.filter(e => e.type === SoccerGameEventType.stopHalf).length,
    lastStartedHalf: p.events.filter(e => e.type === SoccerGameEventType.startHalf).length,
    isClockRunning: p.events.filter(isSoccerStartClockEvent).length !== p.events.filter(isStopClockEvent).length,
    hasGameEnded,
    hasGameStarted,
    isBetweenFirstAndLastWhistle: hasGameStarted && !hasGameEnded
  };
}

export function getBestFitPositionTypeInfo(p: {
  game: SoccerGame;
  memoryDB: SoccerEventMemoryDB;
  players: PrettyPlayer[];
  nowMS: number;
}): {
  playerIdToPositionType: Record<PlayerId, SoccerPositionTypes>;
  positionTypeToPlayerIds: Record<SoccerPositionTypes, Record<PlayerId, true>>;
  timeSpentAtPositionByPlayerId: Record<
    PlayerId,
    {
      defender: number;
      forward: number;
      goalkeeper: number;
      midfielder: number;
    }
  >;
} {
  const playerIdHasPlayingTime = _.mapValues(p.game.roster, () => false);
  const timeSpentAtPositionByPlayerId = _.mapValues(p.game.roster, () => ({
    [SoccerPositionTypes.defender]: 0,
    [SoccerPositionTypes.forward]: 0,
    [SoccerPositionTypes.goalkeeper]: 0,
    [SoccerPositionTypes.midfielder]: 0
  }));

  iteratePlayerStartStopPairs({
    ...p,
    fn: ({ playerId, startStopPair, positionType }) => {
      if (!positionType) {
        return;
      }
      playerIdHasPlayingTime[playerId] = true;

      const prevTime = timeSpentAtPositionByPlayerId[playerId][positionType];

      timeSpentAtPositionByPlayerId[playerId][positionType] = prevTime + (startStopPair[1] - startStopPair[0]);
    }
  });

  const playerIdsWithPlayingTime = _.pickBy(playerIdHasPlayingTime, hasTime => hasTime);

  const playerIdToPositionType = _.mapValues(playerIdsWithPlayingTime, (__, playerId) => {
    let maxPositionType = SoccerPositionTypes.midfielder as SoccerPositionTypes;
    Object.keys(timeSpentAtPositionByPlayerId[playerId]).forEach(positionType => {
      const thisVal = timeSpentAtPositionByPlayerId[playerId][positionType as SoccerPositionTypes];
      const maxVal = timeSpentAtPositionByPlayerId[playerId][maxPositionType];
      if (thisVal > maxVal) {
        maxPositionType = positionType as SoccerPositionTypes;
      }
    });

    return maxPositionType;
  });

  const positionTypeToPlayerIds: Record<SoccerPositionTypes, Record<PlayerId, true>> = {
    [SoccerPositionTypes.defender]: {},
    [SoccerPositionTypes.forward]: {},
    [SoccerPositionTypes.goalkeeper]: {},
    [SoccerPositionTypes.midfielder]: {}
  };

  Object.keys(playerIdToPositionType).forEach(playerId => {
    positionTypeToPlayerIds[playerIdToPositionType[playerId]][playerId] = true;
  });

  return {
    playerIdToPositionType,
    positionTypeToPlayerIds,
    timeSpentAtPositionByPlayerId
  };
}

//We need to optimize this function so that it's not expensive... But will be tricky.
export function expensiveComputeSoccerPlayerIdToPlayingTimeMS(p: {
  game: SoccerGame;
  memoryDB: SoccerEventMemoryDB;
  players: PrettyPlayer[];
  nowMS: number;
}): Record<string, number> {
  const playingTimeByPlayerId = _.mapValues(p.game.roster, () => 0);
  iteratePlayerStartStopPairs({
    ...p,
    fn: ({ playerId, startStopPair }) => {
      playingTimeByPlayerId[playerId] += startStopPair[1] - startStopPair[0];
    }
  });

  return playingTimeByPlayerId;
}

export function computeSoccerGamePlayingTimeMS(p: { events: SoccerClockEvent[]; game: SoccerGame; nowMS: number }) {
  if (!p.events.length) {
    return 0;
  }

  const lastStart = p.events.slice(-1).pop()?.createdAtMS;
  if (!lastStart) {
    return 0;
  }

  //If timer is running, get time between now and last start
  const timeSinceLastStart = p.events.length % 2 === 1 ? p.nowMS - lastStart : 0;

  //Add on start-stop pairs
  const pastTime = p.events.reduce((acc, action, i) => {
    if (i % 2 === 0) {
      //Ignore even entries
      return acc;
    }
    const prevAction = p.events[i - 1];

    return acc + (action.createdAtMS - prevAction.createdAtMS);
  }, 0);

  return timeSinceLastStart + pastTime;
}

export function computeSoccerGameClockTimeMS(p: {
  events: SoccerClockEvent[];
  halfLengthMinutes?: number;
  overtimeHalfLengthMinutes?: number;
  timestampMS: number;
}): { primaryTimeMS: number; stoppageTimeMS: number; expectedEndOfHalfMS: number; legacyGameClockTimeMS: number } {
  const lastRelevantClockEventIndex = _.findLastIndex(p.events, e =>
    //Stop clock events are a little weird in that they actually belong to the just barely ended half.
    isStopClockEvent(e) ? e.createdAtMS < p.timestampMS : e.createdAtMS <= p.timestampMS
  );

  const relevantClockEvents = p.events.slice(0, lastRelevantClockEventIndex + 1);
  const overtimeHalfLengthMinutes = p.overtimeHalfLengthMinutes || 5;
  const halfLengthMinutes = p.halfLengthMinutes || 30;
  const lastClockEvent = relevantClockEvents.slice(-1).pop();
  const gameHasEnded = lastClockEvent?.type === SoccerGameEventType.stopHalf && lastClockEvent.endsGame;

  const stopHalfEvents = relevantClockEvents.filter(a => a.type === SoccerGameEventType.stopHalf);
  const currentOrUpcomingHalf = Math.min(stopHalfEvents.length + (gameHasEnded ? 0 : 1), 4);
  const numFullyElapsedNormalHalves = Math.max(0, Math.min(2, currentOrUpcomingHalf - 1));
  const numFullyElapsedOvertimeHalves = Math.max(0, Math.min(2, currentOrUpcomingHalf - 3));

  const expectedMSForCurrentOrUpcomingHalf =
    currentOrUpcomingHalf <= 2 ? halfLengthMinutes * 1000 * 60 : overtimeHalfLengthMinutes * 1000 * 60;

  const previousHalvesExpectedMS =
    (numFullyElapsedNormalHalves * halfLengthMinutes + numFullyElapsedOvertimeHalves * overtimeHalfLengthMinutes) * 1000 * 60;
  const expectedEndOfHalfMS = expectedMSForCurrentOrUpcomingHalf + previousHalvesExpectedMS;

  const emptyState = {
    primaryTimeMS: 0,
    stoppageTimeMS: 0,
    legacyGameClockTimeMS: 0,
    expectedEndOfHalfMS
  };

  if (!relevantClockEvents.length || !lastClockEvent) {
    return emptyState;
  }

  const lastStartHalfActionIndex = _.findLastIndex(relevantClockEvents, e => e.type === SoccerGameEventType.startHalf);

  //Shouldn't be necessary but just in case...
  if (lastStartHalfActionIndex === -1) {
    return emptyState;
  }

  const thisHalfClockActions = relevantClockEvents.slice(lastStartHalfActionIndex);

  const thisHalfStartStopPairs = thisHalfClockActions.map(a => a.createdAtMS);
  if (thisHalfStartStopPairs.length % 2 === 1) {
    thisHalfStartStopPairs.push(p.timestampMS);
  }

  const thisHalfElapsedMS = thisHalfStartStopPairs.reduce((acc, evtMS, i) => {
    if (i % 2 === 0) {
      return acc;
    }

    const prevEventMS = thisHalfStartStopPairs[i - 1];

    return acc + (evtMS - prevEventMS);
  }, 0);

  let primaryTimeMS = Math.min(
    thisHalfElapsedMS + previousHalvesExpectedMS,
    previousHalvesExpectedMS + expectedMSForCurrentOrUpcomingHalf
  );

  const isGameBetweenHalves =
    lastClockEvent.type === SoccerGameEventType.stopHalf &&
    !lastClockEvent.endsGame &&
    p.timestampMS !== lastClockEvent.createdAtMS;

  let stoppageTimeMS = Math.max(thisHalfElapsedMS + previousHalvesExpectedMS - expectedEndOfHalfMS, 0);

  if (isGameBetweenHalves) {
    primaryTimeMS = previousHalvesExpectedMS;
    stoppageTimeMS = 0;
  }

  return {
    primaryTimeMS,
    stoppageTimeMS,
    expectedEndOfHalfMS,
    legacyGameClockTimeMS: primaryTimeMS + stoppageTimeMS
  };
}

function formatGameClockTime(p: {
  clockMode: SoccerGame["clockMode"];
  primaryTimeMS: number;
  stoppageTimeMS: number;
  expectedEndOfHalfMS: number;
  showMinutesOnly?: boolean;
}) {
  const secondaryTimePrefix = p.clockMode !== "nfhs" ? "+" : "-";

  let primaryTimeMS = p.clockMode !== "nfhs" ? p.primaryTimeMS : p.expectedEndOfHalfMS - p.primaryTimeMS;
  let secondaryTimeMS = p.stoppageTimeMS;

  if (p.showMinutesOnly) {
    //Soccer is weird. Events that happen between 0 and 60 seconds are classified as the "first minute"
    primaryTimeMS = Math.ceil(primaryTimeMS / 60000) * 60000;
    secondaryTimeMS = Math.ceil(secondaryTimeMS / 60000) * 60000;
  }

  let primaryTimeStr = msToMinutesString(primaryTimeMS);
  let secondaryTimeStr = secondaryTimeMS ? `${secondaryTimePrefix}${msToMinutesString(secondaryTimeMS)}` : "";

  if (p.showMinutesOnly) {
    primaryTimeStr = primaryTimeStr.split(":")[0];
    secondaryTimeStr = secondaryTimeStr ? secondaryTimeStr.split(":")[0] : "";
  }

  return { primaryTimeStr, secondaryTimeStr };
}

export function computeAndFormatSoccerGameClockTimeMS(p: {
  createdAtMS: number;
  allClockEvents: SoccerClockEvent[];
  halfLengthMinutes?: number;
  overtimeHalfLengthMinutes?: number;
  clockMode?: SoccerGame["clockMode"];
  showMinutesOnly?: boolean;
}) {
  const { primaryTimeMS, stoppageTimeMS, expectedEndOfHalfMS } = computeSoccerGameClockTimeMS({
    events: p.allClockEvents,
    halfLengthMinutes: p.halfLengthMinutes,
    overtimeHalfLengthMinutes: p.overtimeHalfLengthMinutes,
    timestampMS: p.createdAtMS
  });

  const { primaryTimeStr, secondaryTimeStr } = formatGameClockTime({
    clockMode: p.clockMode,
    primaryTimeMS,
    stoppageTimeMS,
    expectedEndOfHalfMS,
    showMinutesOnly: p.showMinutesOnly
  });

  return { primaryTimeStr, secondaryTimeStr };
}

export function computeClockTimePrettyStringForLGM(p: {
  createdAtMS: number;
  allClockEvents: SoccerClockEvent[];
  game: SoccerGame;
}): string {
  const { primaryTimeStr, secondaryTimeStr } = computeAndFormatSoccerGameClockTimeMS({
    allClockEvents: p.allClockEvents,
    createdAtMS: p.createdAtMS,
    clockMode: "default",
    halfLengthMinutes: p.game.halfLengthMinutes,
    overtimeHalfLengthMinutes: p.game.overtimeHalfLengthMinutes,
    showMinutesOnly: true
  });

  return secondaryTimeStr ? `${primaryTimeStr}' ${secondaryTimeStr}'` : `${primaryTimeStr}'`;
}

function getSoccerHalfDurationMS(p: { game: PostSetupSoccerGame; halfNumber: number }) {
  const halfLength = p.game.halfLengthMinutes;
  const overtimeHalfLength = 5; //TODO: Get this from the game and the UI

  switch (p.halfNumber) {
    case 1:
    case 2:
      return halfLength * 1000 * 60;
    case 3:
    case 4:
      return overtimeHalfLength * 1000 * 60;
    default:
      return 0;
  }
}

function getCompletedHalvesTime(p: { events: SoccerClockEvent[]; game: PostSetupSoccerGame }) {
  const lastStoppedHalf = p.events.filter(e => e.type === SoccerGameEventType.stopHalf).length;
  const lastStartedHalf = p.events.filter(e => e.type === SoccerGameEventType.startHalf).length;

  const lastCompletedHalf = lastStartedHalf >= lastStoppedHalf ? lastStoppedHalf : lastStoppedHalf - 1;

  return Array.apply(null, { length: lastCompletedHalf } as any)
    .map((a, i) => i + 1)
    .reduce((acc, half) => {
      return acc + getSoccerHalfDurationMS({ halfNumber: half, game: p.game });
    }, 0);
}

function iteratePlayerStartStopPairs(p: {
  game: SoccerGame;
  memoryDB: SoccerEventMemoryDB;
  players: PrettyPlayer[];
  nowMS: number;
  fn: (a: { playerId: string; positionType?: SoccerPositionTypes; startStopPair: [number, number] }) => any;
}): void {
  const clockEvents = p.memoryDB.presets.clockEvents();
  const subEvents = p.memoryDB.presets.subEvents();
  const cardEvents = p.memoryDB.presets.cardEvents();

  if (p.game.gameStage === "pre-setup" || p.game.gameStage === "setup" || !clockEvents.length) {
    return;
  }

  const allRosterEvents = _.orderBy([...subEvents, ...cardEvents], "createdAtMS");

  function getRosterAtTime(timeMS: number) {
    const theseEvents = allRosterEvents.filter(evt => evt.createdAtMS <= timeMS);
    return computeSoccerGamePlayerRoster({
      soccerGame: p.game,
      memoryDB: createSoccerEventMemoryDB(theseEvents),
      players: p.players
    });
  }

  const clockStopStartPairs = clockEvents.reduce((acc, evt, i, arr) => {
    if (i % 2 === 1) {
      acc.push([clockEvents[i - 1].createdAtMS, clockEvents[i].createdAtMS]);
    } else if (i % 2 === 0 && i === arr.length - 1) {
      acc.push([evt.createdAtMS, p.nowMS]);
    }

    return acc;
  }, [] as [number, number][]);

  //Find all the subs that happened during play (e.g. not in between halfs) and replace that halfs stop-start pair with two start stop pairs with the sub at the middle.
  const playingTimeStartStopPairs = subEvents.reduce((acc, evt) => {
    const index = acc.findIndex(pair => evt.createdAtMS >= pair[0] && evt.createdAtMS < pair[1]);
    if (index === -1) {
      return acc;
    }

    const origStartStopPair = acc[index];

    acc.splice(index, 1, [origStartStopPair[0], evt.createdAtMS], [evt.createdAtMS, origStartStopPair[1]]);

    return acc;
  }, clockStopStartPairs);

  playingTimeStartStopPairs.forEach(pair => {
    const { roster } = getRosterAtTime(pair[0]);

    roster
      .filter(pl => pl.fieldPositionNumber)
      .forEach(pl => {
        p.fn({ playerId: pl.id, positionType: pl.fieldPositionType, startStopPair: pair });
      });
  });
}

export function getTimeElapsedInHalfAtSpecificGameClockTime(p: {
  playingTimeMS: number;
  events: SoccerClockEvent[];
  game: SoccerGame;
}) {
  const lastStartedHalfEvent = p.events.filter(e => e.playingTimeMS < p.playingTimeMS).pop();
  if (lastStartedHalfEvent) {
    return p.playingTimeMS - lastStartedHalfEvent.playingTimeMS;
  }
  return 0;
}

export function computeTimeValuesForProposedNewGoalInEditScore(p: {
  half: number;
  minute: number;
  events: SoccerClockEvent[];
  game: SoccerGame;
}) {
  const startHalfEvent = p.events.filter(e => e.type === SoccerGameEventType.startHalf)[p.half];
  const MSValueOfMinute = (p.minute - 1) * 60 * 1000 + 1000;
  if (startHalfEvent) {
    return {
      gamePlayingTimeMS: startHalfEvent.playingTimeMS + MSValueOfMinute,
      gameClockTimeMS: startHalfEvent.gameClockTimeMS + MSValueOfMinute,
      createdAtMS: startHalfEvent.createdAtMS + MSValueOfMinute
    };
  }
  return { gamePlayingTimeMS: 0, gameClockTimeMS: 0, createdAtMS: 0 };
}

export function computeLargestAllowedMinuteForNewAddedGoal(p: {
  half: number;
  events: SoccerClockEvent[];
  game: SoccerGame;
  nowMS: number;
}) {
  const startHalfEvent = p.events.filter(e => e.type === SoccerGameEventType.startHalf)[p.half];
  const stopHalfEvent = p.events.filter(e => e.type === SoccerGameEventType.stopHalf)[p.half];

  if (!startHalfEvent && !stopHalfEvent) {
    return 0;
  }
  if (!stopHalfEvent) {
    return Math.ceil((p.nowMS - startHalfEvent.createdAtMS) / (1000 * 60));
  } else {
    return Math.ceil((stopHalfEvent.playingTimeMS - startHalfEvent.playingTimeMS) / (1000 * 60));
  }
}

// i18n certified - complete

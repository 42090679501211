import {
  SoccerStatModes,
  PostSetupSoccerGame,
  SoccerGameEventType,
  SoccerPositionNumber,
  SoccerFormationKeys,
  getFormationSize,
  SoccerFormationsByKey,
  SoccerPositionNumberWithSubOut,
  SUB_OUT_MAGIC_CONSTANT,
  SGE_stopHalf,
  CalendarEntryGameScrimmageEndGameDetails
} from "@ollie-sports/models";
import _ from "lodash";
import { ConvenienceSoccerGamePlayer } from "./SoccerGamePlayerRoster";
import { isOpponentPlayer } from "./SoccerFns";
import { SoccerGoalEvent } from "./SoccerEventCategories";

export function soccerGameHasGoalies(p: { game: PostSetupSoccerGame; roster: ConvenienceSoccerGamePlayer[] }) {
  return p.game.statMode !== SoccerStatModes.team ? !!p.roster.find(pl => pl.fieldPositionNumber === "1") : true;
}

export function computeSoccerGameScore(p: { goalEvents: SoccerGoalEvent[] }) {
  return {
    ownTeam: p.goalEvents.reduce((acc, e) => acc + Number(!isOpponentPlayer(e.playerId)), 0),
    opponentTeam: p.goalEvents.reduce((acc, e) => acc + Number(isOpponentPlayer(e.playerId)), 0)
  };
}

export function computeSoccerGameEndGameDetails(p: {
  goalEvents: SoccerGoalEvent[];
  endGameEvent: SGE_stopHalf & { endsGame: true };
  statMode: SoccerStatModes;
}): CalendarEntryGameScrimmageEndGameDetails {
  const score = computeSoccerGameScore({ goalEvents: p.goalEvents });
  let gameResult: "win" | "loss" | "tie" =
    score.ownTeam > score.opponentTeam ? "win" : score.opponentTeam > score.ownTeam ? "loss" : "tie";
  if (gameResult === "tie" && p.endGameEvent.pkScore) {
    gameResult = p.endGameEvent.pkScore.ownTeam > p.endGameEvent.pkScore.opponentTeam ? "win" : "loss";
  }

  const val: CalendarEntryGameScrimmageEndGameDetails = {
    score,
    gameResult,
    source: p.statMode
  };

  if (p.endGameEvent.pkScore) {
    val.pkScore = p.endGameEvent.pkScore;
  }

  return val;
}

export function computeStarterPlayerIdsNotValidForFormation(p: {
  starterIdToPosition: { [playerId in string]?: SoccerPositionNumber };
  formation: SoccerFormationKeys;
}) {
  const starterPositions = Object.values(p.starterIdToPosition);

  const invalidPlayerIds: string[] = [];
  starterPositions.forEach((pos, i) => {
    if (pos && !SoccerFormationsByKey[p.formation][pos]) {
      Object.keys(p.starterIdToPosition).forEach(pid => {
        if (p.starterIdToPosition[pid] === pos) {
          invalidPlayerIds.push(pid);
        }
      });
    }
  });

  return invalidPlayerIds;
}

export function getNewStartersFromPlayerPositionChanges(p: {
  origStarters: { [playerId in string]?: SoccerPositionNumber };
  changes: { [playerId in string]: SoccerPositionNumberWithSubOut };
  formation: SoccerFormationKeys;
}) {
  const newStarters = _.cloneDeep(p.origStarters);
  Object.keys(p.changes).forEach(playerId => {
    const val = p.changes[playerId];
    if (val === SUB_OUT_MAGIC_CONSTANT) {
      delete newStarters[playerId];
    } else {
      newStarters[playerId] = val;
    }
  });

  //Remove all starting players that don't exist on this formation. Should be extremely rare...
  return _.pickBy(newStarters, (a, b) => {
    if (!a) {
      return false;
    }

    return !!SoccerFormationsByKey[p.formation][a];
  });
}

// i18n certified - complete

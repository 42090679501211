import {
  PauseEventReasons,
  PauseEventReasonsThatResumeWithAssignmentOrDropBall,
  SoccerGameEvent,
  SoccerGameEventType,
  SoccerIds,
  SoccerPossessionState,
  SoccerShotTargetArea
} from "@ollie-sports/models";
import _ from "lodash";
import { isGoalEvent, isPendingPassEvent, isShotEvent, SoccerGoalEvent, SoccerPauseEvent } from "./SoccerEventCategories";
import { getEventPossessionState } from "./SoccerPossession";

//NOTE: These dimensions must be kept in sync with the TouchableField.svg component
const PENALTY_BOX_PERCENT_DIMENSIONS = {
  width: 0.1255,
  height: 0.684
};

//The following constants are derived from the SVG. Keep them in sync always
const VERTICAL_FRAME_INFO = {
  crossbarThickness: 0.053, // e.g. crossbarPixels / heightPixels
  crossbarStart: 0.611 //The distance from the bottom border to the start of the crossbar. e.g. bottomToStartPixels / heightPixels
};

const HORIZ_FRAME_INFO = {
  postThickness: 0.0184, // e.g. crossbarPixels / heightPixels
  leftPostStart: 0.138, // e.g. leftBorderToLeftPostStartPixels / widthPixels
  rightPostStart: 0.843 // e.g. leftBorderToRightPostStartPixels / widthPixels
};

export function isOwnTeamPlayer(playerId: string | { id: string }) {
  return !isOpponentPlayer(playerId);
}

export function isOpponentPlayer(playerId: string | { id: string }) {
  if (typeof playerId === "object") {
    playerId = playerId.id;
  }

  return (
    playerId === SoccerIds.opponentFieldPlayer ||
    playerId === SoccerIds.opponentGoalie ||
    playerId === SoccerIds.opponentGenericPlayer
  );
}

export function isOpponentFieldPlayer(playerId: string | { id: string }) {
  if (typeof playerId === "object") {
    playerId = playerId.id;
  }

  return playerId === SoccerIds.opponentFieldPlayer;
}

export function isOpponentGoaliePlayer(playerId: string | { id: string }) {
  if (typeof playerId === "object") {
    playerId = playerId.id;
  }

  return playerId === SoccerIds.opponentGoalie;
}

export function isOpponentTeamId(teamId: string) {
  return teamId === SoccerIds.opponentTeamId;
}

export function arePlayersSameTeam(playerId1: string | { id: string }, playerId2: string | { id: string }) {
  const v1 = isOpponentPlayer(playerId1);
  const v2 = isOpponentPlayer(playerId2);

  return (v1 && v2) || (!v1 && !v2);
}

export function isPlayerGeneric(playerId: string | { id: string }) {
  if (typeof playerId === "object") {
    playerId = playerId.id;
  }

  return playerId === SoccerIds.opponentGenericPlayer || playerId === SoccerIds.ownTeamGenericPlayer;
}

export function isStockPlayer(playerId: string | { id: string }) {
  if (typeof playerId === "object") {
    playerId = playerId.id;
  }

  return Object.values(SoccerIds).includes(playerId as any);
}

export function getPlayerTeamId(playerId: string | { id: string }, ownTeamId: string) {
  return isOpponentPlayer(playerId) ? SoccerIds.opponentTeamId : ownTeamId;
}

export function getFieldEdge(p: { x: number; y: number }) {
  const { x, y } = p;
  if (x === 1) {
    return "right";
  } else if (x === 0) {
    return "left";
  } else if (y === 0) {
    return "top";
  } else if (y === 1) {
    return "bottom";
  } else {
    return "";
  }
}

export function convertFieldLocationToAttackingThirdLocation(coords: { x: number; y: number }, shootingPlayerId: string) {
  //if shootingPlayer is opponent (e.g. on our goal):
  //(0.33, 0) Field Location === (0, 0) AttackingThirdLocation
  //(0, 0) Field Location === (0, 1) AttackingThirdLocation
  //(0, 1) Field Location === (1, 1) AttackingThirdLocation
  //(0.33, 1) Field Location === (1, 0) AttackingThirdLocation

  //else
  //(0.66, 0) Field Location === (1, 0) AttackingThirdLocation
  //(1, 0) Field Location === (1, 1) AttackingThirdLocation
  //(1, 1) Field Location === (0, 1) AttackingThirdLocation
  //(0.66, 1) Field Location === (0, 0) AttackingThirdLocation

  if (isOpponentPlayer(shootingPlayerId)) {
    return {
      x: coords.y,
      y: 1 - Math.min(coords.x, 0.3333) * 3
    };
  } else {
    return {
      x: 1 - coords.y,
      y: 1 - (1 - Math.max(0.6666, coords.x)) * 3
    };
  }
}

export function getOutOfBoundsResult(args: { fieldLocation: { x: number; y: number }; takingPlayerId: string }): {
  resultType: "throw-in" | "goal-kick" | "corner-kick";
  takingLocation: { x: number; y: number };
} {
  const { fieldLocation: outFieldLocation, takingPlayerId } = args;

  const { x, y } = outFieldLocation;

  const edge = getFieldEdge({ x, y });

  if (["top", "bottom"].includes(edge)) {
    return {
      resultType: "throw-in",
      takingLocation: { x, y }
    };
  } else {
    const isOpponent = isOpponentPlayer(takingPlayerId);
    if ((x === 1 && isOpponent) || (x === 0 && !isOpponent)) {
      return {
        resultType: "goal-kick",
        takingLocation: { x, y: 0.5 }
      };
    } else {
      return {
        resultType: "corner-kick",
        takingLocation: { x, y: Math.round(y) }
      };
    }
  }
}

export function isFieldLocationInsidePlayerPenaltyBox(infractingPlayerId: string, p: { x: number; y: number }) {
  const { x, y } = p;
  const isOpponentInfraction = isOpponentPlayer(infractingPlayerId);
  const { width, height } = PENALTY_BOX_PERCENT_DIMENSIONS;
  const isOwnSide = isOpponentInfraction ? x > 1 - width : x < width;

  const vertOffset = (1 - height) / 2;

  return isOwnSide && y > vertOffset && y < 1 - vertOffset;
}

export function computeShotTargetArea(goalShotLocation?: { x: number; y: number }) {
  if (!goalShotLocation) {
    return SoccerShotTargetArea.unknown;
  }

  const { x, y } = goalShotLocation;

  let { crossbarThickness, crossbarStart } = VERTICAL_FRAME_INFO;
  let { postThickness, leftPostStart, rightPostStart } = HORIZ_FRAME_INFO;

  const crossbarScaleFactor = crossbarThickness * 0.4;
  const postScaleFactor = postThickness * 0.4;

  //Transform the image derived constants to make the frame fatter, to make the frame easier to select
  crossbarStart -= crossbarScaleFactor;
  crossbarThickness += crossbarScaleFactor * 2;
  leftPostStart -= postScaleFactor;
  rightPostStart -= postScaleFactor;
  postThickness += postScaleFactor * 2;

  const onLeftPost = x >= leftPostStart && x <= leftPostStart + postThickness && y < crossbarStart;
  const onRightPost = x >= rightPostStart && x <= rightPostStart + postThickness && y < crossbarStart;
  const onCrossbar =
    y >= crossbarStart && y <= crossbarStart + crossbarThickness && x > leftPostStart && x < rightPostStart + postThickness;
  const isInside = x > leftPostStart + postThickness && x < rightPostStart && y < crossbarStart;

  if (onLeftPost || onRightPost || onCrossbar) {
    return SoccerShotTargetArea.woodwork;
  } else if (isInside) {
    return SoccerShotTargetArea.inside;
  } else {
    return SoccerShotTargetArea.out;
  }
}

export function convertShotTargetLocationToFieldLocation(p: { shotTargetLocation: { x: number }; shootingPlayerId: string }) {
  const { x } = p.shotTargetLocation;

  const isOwnTeamGoalSide = isOpponentPlayer(p.shootingPlayerId);
  return {
    x: isOwnTeamGoalSide ? 0 : 1,
    y: isOwnTeamGoalSide ? x : 1 - x
  };
}

interface getKeysGoalsReturn {
  tieGoal: null | SoccerGoalEvent;
  gameWinningGoal: null | SoccerGoalEvent;
}

export function getKeyGoals(goalEvents: SoccerGoalEvent[]): getKeysGoalsReturn {
  let gameWinningGoal = null as null | SoccerGoalEvent;
  let tieGoal = null as null | SoccerGoalEvent;
  let goalDifferential = 0;
  goalEvents.forEach(evt => {
    goalDifferential += isOpponentTeamId(evt.playerTeamId) ? -1 : 1;
    if (goalDifferential === 1 || goalDifferential === -1) {
      gameWinningGoal = evt;
      tieGoal = null;
    } else if (goalDifferential > 1) {
      //Do nothing
    } else if (goalDifferential === 0) {
      gameWinningGoal = null;
      tieGoal = evt;
    }
  });

  let r: getKeysGoalsReturn = {
    tieGoal,
    gameWinningGoal
  };

  return r;
}

export function getTeamStatType(teamId: string) {
  return teamId === SoccerIds.opponentTeamId ? ("opponentTeam" as const) : ("ownTeam" as const);
}

// i18n certified - complete

import {
  SoccerStatSnapshot,
  PlayerStatKeys,
  SoccerGameEventType,
  SoccerGame,
  PrettyPlayer,
  SoccerPositionTypes,
  SoccerStatModes
} from "@ollie-sports/models";
import { ConvenienceSoccerGamePlayer, computeSoccerGamePlayerRoster } from "./SoccerGamePlayerRoster";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { isOpponentTeamId, getKeyGoals } from "./SoccerFns";
import { ObjectKeys } from "../utils";
import { getSoccerStatDefinitions } from "./SoccerStatDefinitions";

const MAX_PREPS_PARTNER_ID = "2c7ee207-5679-49f6-a7c1-2c18a9fb83f6";

const EXPORT_FIELDS: Record<
  string,
  | {
      type: "general";
      statKey: PlayerStatKeys;
    }
  | {
      type: "max-preps-specific";
      statModes: SoccerStatModes[];
      fn: (a: {
        playerId: string;
        snapshot: SoccerStatSnapshot;
        players: PrettyPlayer[];
        memoryDB: SoccerEventMemoryDB;
        soccerGame: SoccerGame;
      }) => number | string;
    }
> = {
  Jersey: {
    fn: ({ playerId, players }) => players.find(pl => pl.player.id === playerId)?.player.jerseyNumber || "000",
    type: "max-preps-specific",
    statModes: [SoccerStatModes.team, SoccerStatModes.individual]
  },
  FieldMinutesPlayed: {
    fn: ({ snapshot, playerId }) =>
      !snapshot.playerStats[playerId][PlayerStatKeys.pGoaliePlayingTimeMS]
        ? ((snapshot.playerStats[playerId][PlayerStatKeys.pPlayingTimeMS] || 0) / (1000 * 60)).toFixed(2)
        : 0,
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },
  Goals: {
    type: "general",
    statKey: PlayerStatKeys.pGoals
  },
  Assists: {
    type: "general",
    statKey: PlayerStatKeys.pAssists
  },
  Shots: {
    type: "general",
    statKey: PlayerStatKeys.pShots
  },
  ShotsOnGoal: {
    type: "general",
    statKey: PlayerStatKeys.pShotsOnTarget
  },
  Steals: {
    type: "general",
    statKey: PlayerStatKeys.pRecoveries
  },
  PenaltyKicksMade: {
    type: "general",
    statKey: PlayerStatKeys.pPenaltyKicksScored
  },
  PenaltyKicksAttempted: {
    type: "general",
    statKey: PlayerStatKeys.pPenaltyKicksAttempted
  },
  CornerKicks: {
    type: "general",
    statKey: PlayerStatKeys.pCornerKicks
  },
  GameWinningGoal: {
    type: "general",
    statKey: PlayerStatKeys.pGameWinningGoals
  },
  YellowCards: {
    type: "general",
    statKey: PlayerStatKeys.pYellowCards
  },
  RedCards: {
    type: "general",
    statKey: PlayerStatKeys.pRedCards
  },
  MinutesPlayed: {
    fn: ({ snapshot, playerId }) =>
      snapshot.playerStats[playerId][PlayerStatKeys.pGoaliePlayingTimeMS]
        ? ((snapshot.playerStats[playerId][PlayerStatKeys.pGoaliePlayingTimeMS] || 0) / (1000 * 60)).toFixed(2)
        : 0,
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },
  GoalsAgainst: {
    type: "general",
    statKey: PlayerStatKeys.pGoalsConceded
  },
  Saves: {
    type: "general",
    statKey: PlayerStatKeys.pShotsSaved
  },
  ShutOuts: {
    type: "general",
    statKey: PlayerStatKeys.pGoalieShutouts
  },
  OpponentShotsOnGoal: {
    fn: ({ playerId, memoryDB, snapshot }) => {
      if (!snapshot.timeSpentAtPositionByPlayerId[playerId]?.goalkeeper) {
        return "";
      }

      return memoryDB.presets.shotEvents().filter(evt => evt.defendingGoaliePlayerId === playerId && evt.shotIsOnTarget).length;
    },
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },

  OpponentPenaltyKickSaves: {
    fn: ({ playerId, snapshot, memoryDB }) => {
      if (!snapshot.timeSpentAtPositionByPlayerId[playerId]?.goalkeeper) {
        return "";
      }
      return memoryDB.presets
        .shotEvents()
        .filter(
          evt =>
            evt.shotReason === "infraction-pk" &&
            evt.defendingGoaliePlayerId === playerId &&
            evt.shotIsOnTarget &&
            evt.type !== SoccerGameEventType.shotWithGoal
        ).length;
    },
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },
  OpponentPenaltyKickAttempts: {
    fn: ({ playerId, snapshot, memoryDB }) => {
      if (!snapshot.timeSpentAtPositionByPlayerId[playerId]?.goalkeeper) {
        return "";
      }

      return memoryDB.presets
        .shotEvents()
        .filter(evt => evt.shotReason === "infraction-pk" && evt.defendingGoaliePlayerId === playerId).length;
    },
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },
  Win: {
    fn: ({ playerId, snapshot, memoryDB, players, soccerGame }) => {
      if (!snapshot.timeSpentAtPositionByPlayerId[playerId]?.goalkeeper) {
        return "";
      }

      const { gameWinningGoal } = getKeyGoals(memoryDB.presets.goalEvents());

      if (gameWinningGoal && !isOpponentTeamId(gameWinningGoal.playerTeamId)) {
        const goalie = getGoalieAtTime({ soccerGame, memoryDB, players, timeMS: gameWinningGoal.createdAtMS });
        if (goalie && goalie.id === playerId) {
          return 1;
        }
      }

      return 0;
    },
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },
  Loss: {
    fn: ({ playerId, snapshot, memoryDB, players, soccerGame }) => {
      if (!snapshot.timeSpentAtPositionByPlayerId[playerId]?.goalkeeper) {
        return "";
      }

      const { gameWinningGoal } = getKeyGoals(memoryDB.presets.goalEvents());

      if (gameWinningGoal && isOpponentTeamId(gameWinningGoal.playerTeamId)) {
        const goalie = getGoalieAtTime({ soccerGame, memoryDB, players, timeMS: gameWinningGoal.createdAtMS });
        if (goalie && goalie.id === playerId) {
          return 1;
        }
      }

      return 0;
    },
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  },
  Tie: {
    fn: ({ playerId, snapshot, memoryDB, players, soccerGame }) => {
      if (!snapshot.timeSpentAtPositionByPlayerId[playerId]?.goalkeeper) {
        return "";
      }

      const { tieGoal } = getKeyGoals(memoryDB.presets.goalEvents());

      if (tieGoal) {
        const goalie = getGoalieAtTime({ soccerGame, memoryDB, players, timeMS: tieGoal.createdAtMS });
        if (goalie && goalie.id === playerId) {
          return 1;
        }
      }

      return 0;
    },
    type: "max-preps-specific",
    statModes: [SoccerStatModes.individual]
  }
};

export function createMaxPrepsTxtFileExport(p: {
  snapshot: SoccerStatSnapshot;
  players: PrettyPlayer[];
  memoryDB: SoccerEventMemoryDB;
  soccerGame: SoccerGame;
  locale: string;
}): string {
  const filteredExportKeys = ObjectKeys(EXPORT_FIELDS).filter(fieldStr => {
    const field = EXPORT_FIELDS[fieldStr];
    const statModes = field.type === "general" ? getSoccerStatDefinitions(p.locale)[field.statKey].statModes : field.statModes;

    return statModes.find(a => a === p.soccerGame.statMode);
  });
  const playersWithPlayingTime =
    p.soccerGame.statMode === SoccerStatModes.team
      ? p.players
      : p.players.filter(pl => p.snapshot.playerStats?.[pl.player.id]?.[PlayerStatKeys.pPlayingTimeMS]);

  const playerStats = playersWithPlayingTime
    .map(pl => {
      return filteredExportKeys
        .map(fieldStr => {
          const field = EXPORT_FIELDS[fieldStr];

          if (field.type === "max-preps-specific") {
            return field.fn({ playerId: pl.player.id, ...p });
          } else {
            return p.snapshot.playerStats[pl.player.id][field.statKey] ?? "";
          }
        })
        .join("|");
    })
    .join("\n");

  return `
${MAX_PREPS_PARTNER_ID}
${filteredExportKeys.join("|")}
${playerStats}
  `;
}

function getGoalieAtTime(p: {
  soccerGame: SoccerGame;
  timeMS: number;
  memoryDB: SoccerEventMemoryDB;
  players: PrettyPlayer[];
}): ConvenienceSoccerGamePlayer | undefined {
  const { timeMS, ...rest } = p;
  const roster = computeSoccerGamePlayerRoster({
    ...rest,
    ignoreEventsAfterTimeMS: timeMS
  });

  return roster.roster.find(pl => pl.fieldPositionType === SoccerPositionTypes.goalkeeper);
}

// i18n certified - complete

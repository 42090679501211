import {
  PrettyPlayer,
  Team,
  CalendarEntryGameScrimmage,
  SoccerGameEventType,
  PlayerStatKeys,
  PostStartedSoccerGame,
  SoccerStatSnapshot,
  TeamStatKeys
} from "@ollie-sports/models";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { getTeamStatType, isOpponentPlayer, isOpponentTeamId } from "./SoccerFns";
import { msToOrdinalMinuteString } from "../utils";
import { SoccerGoalEvent } from "./SoccerEventCategories";
import { computeSoccerGameScore } from "./SoccerGameMiscLogic";
import { translate } from "@ollie-sports/i18n";

export function createPressRelease(p: {
  statSnapshot: SoccerStatSnapshot;
  eventsDB: SoccerEventMemoryDB;
  prettyPlayers: PrettyPlayer[];
  team: Team;
  game: PostStartedSoccerGame;
  calendarEntry: CalendarEntryGameScrimmage;
  isIos: boolean;
  locale: string;
}) {
  const getTeamName = (teamId: string) => {
    return isOpponentTeamId(teamId) ? p.calendarEntry.opponentName : p.team.name;
  };

  const startHalftimeTimestamp = p.eventsDB.find({ [SoccerGameEventType.stopHalf]: true }).shift()?.createdAtMS;
  const goalEvents = p.eventsDB.presets.goalEvents();

  let bullets: string[] = [];

  const processGoalEvent = (evt: SoccerGoalEvent) => {
    const playerAccountInfo = isOpponentPlayer(evt.playerId)
      ? undefined
      : p.prettyPlayers.find(pl => pl.player.id === evt.playerId)?.derived.accountInfo;

    const teamName = getTeamName(evt.playerTeamId);
    const ordinalMinute = msToOrdinalMinuteString(evt.gameClockTimeMS, p.locale);

    if (playerAccountInfo) {
      return translate(
        { defaultMessage: `{firstName} {lastName} scored for {teamName} in the {ordinalMinute} minute`, serverLocale: p.locale },
        { firstName: playerAccountInfo.firstName, lastName: playerAccountInfo.lastName, teamName, ordinalMinute }
      );
    } else {
      return translate(
        { defaultMessage: `{teamName} scored in the {ordinalMinute} minute`, serverLocale: p.locale },
        { teamName, ordinalMinute }
      );
    }
  };
  const pkData: { winner: "ownTeam" | "opponentTeam"; scoreString: string } | undefined =
    p.game.gameStage === "ended" && p.game.officiallyEndedGameSummaryStats.pkScore
      ? {
          winner:
            p.game.officiallyEndedGameSummaryStats.pkScore.ownTeam > p.game.officiallyEndedGameSummaryStats.pkScore.opponentTeam
              ? "ownTeam"
              : "opponentTeam",
          scoreString: `${p.game.officiallyEndedGameSummaryStats.pkScore.ownTeam}-${p.game.officiallyEndedGameSummaryStats.pkScore.opponentTeam}`
        }
      : undefined;

  const finalScore = computeSoccerGameScore({ goalEvents: goalEvents });

  const finalGameStatus =
    finalScore.ownTeam > finalScore.opponentTeam || pkData?.winner === "ownTeam"
      ? "beat"
      : finalScore.ownTeam < finalScore.opponentTeam || pkData?.winner === "opponentTeam"
      ? "lost to"
      : "tied";

  //Final score

  let finalStatusString = ``;
  if (finalGameStatus === "beat") {
    if (pkData) {
      finalStatusString = translate(
        {
          defaultMessage: `{teamName} beat {opponentName} in a penalty kick shootout ({pkScoreString}) after a {ownTeam}-{opponentTeam} battle`,
          serverLocale: p.locale
        },
        {
          teamName: p.team.name,
          opponentName: p.calendarEntry.opponentName,
          pkScoreString: pkData.scoreString,
          ownTeam: finalScore.ownTeam,
          opponentTeam: finalScore.opponentTeam
        }
      );
    } else {
      finalStatusString = translate(
        {
          defaultMessage: `{teamName} beat {opponentName} with a final score of {ownTeam}-{opponentTeam} `,
          serverLocale: p.locale
        },
        {
          teamName: p.team.name,
          opponentName: p.calendarEntry.opponentName,
          ownTeam: finalScore.ownTeam,
          opponentTeam: finalScore.opponentTeam
        }
      );
    }
  } else if (finalGameStatus === "lost to") {
    if (pkData) {
      finalStatusString = translate(
        {
          defaultMessage: `{teamName} lost to {opponentName} in a penalty kick shootout ({pkScoreString}) after a {ownTeam}-{opponentTeam} battle`,
          serverLocale: p.locale
        },
        {
          teamName: p.team.name,
          opponentName: p.calendarEntry.opponentName,
          pkScoreString: pkData.scoreString,
          ownTeam: finalScore.ownTeam,
          opponentTeam: finalScore.opponentTeam
        }
      );
    } else {
      finalStatusString = translate(
        {
          defaultMessage: `{teamName} lost to {opponentName} with a final score of {ownTeam}-{opponentTeam} `,
          serverLocale: p.locale
        },
        {
          teamName: p.team.name,
          opponentName: p.calendarEntry.opponentName,
          ownTeam: finalScore.ownTeam,
          opponentTeam: finalScore.opponentTeam
        }
      );
    }
  } else {
    if (pkData) {
      finalStatusString = translate(
        {
          defaultMessage: `{teamName} tied {opponentName} in a penalty kick shootout ({pkScoreString}) after a {ownTeam}-{opponentTeam} battle`,
          serverLocale: p.locale
        },
        {
          teamName: p.team.name,
          opponentName: p.calendarEntry.opponentName,
          pkScoreString: pkData.scoreString,
          ownTeam: finalScore.ownTeam,
          opponentTeam: finalScore.opponentTeam
        }
      );
    } else {
      finalStatusString = translate(
        {
          defaultMessage: `{teamName} tied {opponentName} with a final score of {ownTeam}-{opponentTeam} `,
          serverLocale: p.locale
        },
        {
          teamName: p.team.name,
          opponentName: p.calendarEntry.opponentName,
          ownTeam: finalScore.ownTeam,
          opponentTeam: finalScore.opponentTeam
        }
      );
    }
  }

  bullets = bullets.concat(finalStatusString);

  function formatPercentPressRelease(percent: number) {
    return Math.round((Math.round(percent * 100) / 100) * 100) + `${p.isIos ? "%" : "percent"}`;
  }

  //Possession percent
  const possTeamIds = Object.keys(p.statSnapshot.teamStats);

  const possPercents = possTeamIds.map(
    teamId => p.statSnapshot.teamStats[getTeamStatType(teamId)][TeamStatKeys.tPossessionPerc] as number
  );

  const bestPossIndex = possPercents[0] > possPercents[1] ? 0 : 1;
  const worstPossIndex = Number(!bestPossIndex);
  if (possPercents[bestPossIndex] > possPercents[worstPossIndex]) {
    bullets = bullets.concat(
      translate(
        {
          defaultMessage: `{team1} out possessed {team2} with a possession percent of {percent1} to {percent2}`,
          serverLocale: p.locale
        },
        {
          team1: getTeamName(possTeamIds[bestPossIndex]),
          team2: getTeamName(possTeamIds[worstPossIndex]),
          percent1: formatPercentPressRelease(possPercents[bestPossIndex]),
          percent2: formatPercentPressRelease(possPercents[worstPossIndex])
        }
      )
    );
  }

  //Pre half goals
  if (startHalftimeTimestamp) {
    const preHalfGoalEvents = goalEvents.filter(evt => evt.createdAtMS < startHalftimeTimestamp);
    bullets = bullets.concat(preHalfGoalEvents.map(processGoalEvent));

    //Half score
    const halfScore = computeSoccerGameScore({ goalEvents: preHalfGoalEvents });
    bullets = bullets.concat(
      translate(
        {
          defaultMessage: `At halftime, the score was {ownTeam}-{opponentTeam}`,
          serverLocale: p.locale
        },
        {
          ownTeam: halfScore.ownTeam,
          opponentTeam: halfScore.opponentTeam
        }
      )
    );
  }

  //Post half goals
  bullets = bullets.concat(
    goalEvents.filter(evt => (startHalftimeTimestamp ? evt.createdAtMS > startHalftimeTimestamp : true)).map(processGoalEvent)
  );

  if (finalScore.ownTeam > finalScore.opponentTeam) {
    const gkIds = Object.keys(p.statSnapshot.bestFitPositionTypeToPlayerIds.goalkeeper);
    const gkPlayingTime = gkIds.map(playerId => p.statSnapshot.playerStats[playerId][PlayerStatKeys.pPlayingTimeMS] as number);
    const mainGKPlayerId = gkIds[gkPlayingTime.indexOf(Math.max(...gkPlayingTime))];

    const gkInfo = p.prettyPlayers.find(pl => pl.player.id === mainGKPlayerId)?.derived.accountInfo;

    if (gkInfo) {
      bullets = bullets.concat(
        translate(
          {
            defaultMessage: `{firstName} {lastName} was the goalkeeper for the {teamName} team.`,
            serverLocale: p.locale
          },
          {
            firstName: gkInfo.firstName,
            lastName: gkInfo.lastName,
            teamName: p.team.name
          }
        )
      );
    }
  }

  return {
    title: translate(
      { defaultMessage: `{teamName} vs {opponentName} Press Release`, serverLocale: p.locale },
      { teamName: p.team.name, opponentName: p.calendarEntry.opponentName }
    ),
    bullets: bullets
  };
}

// i18n certified - complete

import { SoccerStatKeysObj, PositionNumStatKeys } from "@ollie-sports/models";
import { PositionNumStatComputation } from "./types";
import { isTouchEvent } from "../SoccerEventCategories";
import { isRecovery, isTurnover } from "./shared-computation-fns";

export const PositionNumSoccerStatComputations: Record<PositionNumStatKeys, PositionNumStatComputation> = {
  [SoccerStatKeysObj.pnTouches]: {
    statKey: SoccerStatKeysObj.pnTouches,
    statType: "positionNumber",
    reduce: ({ acc, positionNum, event, currentPlayerIdToFieldPositionNum }) => {
      if (isTouchEvent(event) && currentPlayerIdToFieldPositionNum[event.playerId] === positionNum) {
        return (acc ?? 0) + 1;
      }

      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.pnRecoveries]: {
    statKey: SoccerStatKeysObj.pnRecoveries,
    statType: "positionNumber",
    reduce: ({ acc, positionNum, event, currentPlayerIdToFieldPositionNum, ...rest }) => {
      if (
        isRecovery({ event, currentPlayerIdToFieldPositionNum, ...rest }) &&
        "playerId" in event &&
        currentPlayerIdToFieldPositionNum[event.playerId] === positionNum
      ) {
        return (acc ?? 0) + 1;
      }

      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.pnTurnovers]: {
    statKey: SoccerStatKeysObj.pnTurnovers,
    statType: "positionNumber",
    reduce: ({ acc, positionNum, event, currentPlayerIdToFieldPositionNum, ...rest }) => {
      if (
        isTurnover({ event, currentPlayerIdToFieldPositionNum, ...rest }) &&
        "playerId" in event &&
        currentPlayerIdToFieldPositionNum[event.playerId] === positionNum
      ) {
        return (acc ?? 0) + 1;
      }

      return acc ?? 0;
    }
  }
};

// i18n certified - complete

import {
  SoccerStatKeysObj,
  SoccerStatModes,
  TeamStatKeys,
  SoccerIds,
  SoccerGameEventType,
  SoccerPositionNumber,
  SoccerGameEvent,
  ShotResultType
} from "@ollie-sports/models";
import { StatComputation } from "./types";
import _ from "lodash";
import {
  isGoalEvent,
  isSoccerBasicFreeKickEvent,
  SoccerCrossEventTypeObject,
  isSoccerCrossEvent,
  isShotEvent,
  isSoccerCardEvent,
  isSoccerFreeKickEvent,
  isSoccerCornerKickEvent,
  isSoccerGoalKickEvent,
  isTouchEvent,
  isStopClockEvent,
  SoccerTouchEvent
} from "../SoccerEventCategories";
import { isTurnover, isRecovery } from "./shared-computation-fns";
import { ObjectKeys } from "../../utils";
import { getTeamStatType, isOpponentTeamId } from "../SoccerFns";

export const TeamSoccerStatComputations: Record<TeamStatKeys, StatComputation> = {
  [SoccerStatKeysObj.tPossessions]: {
    statKey: SoccerStatKeysObj.tPossessions,
    statType: "team",
    compute: ({ thisTeamFinalPossessionSets }) => {
      return thisTeamFinalPossessionSets.length;
    }
  },
  [SoccerStatKeysObj.tTouches]: {
    statKey: SoccerStatKeysObj.tTouches,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isTouchEvent(event) && event.playerTeamId === teamId);
    }
  },

  [SoccerStatKeysObj.tPkShootoutKicksScored]: {
    statKey: SoccerStatKeysObj.tPkShootoutKicksScored,
    statType: "team",
    compute: v => {
      const lastEvent = v.allEvents[v.allEvents.length - 1];
      if (lastEvent && lastEvent.type === SoccerGameEventType.stopHalf && lastEvent.pkScore) {
        if (isOpponentTeamId(v.teamId)) {
          return lastEvent.pkScore.opponentTeam;
        } else {
          return lastEvent.pkScore.ownTeam;
        }
      } else {
        return 0;
      }
    }
  },

  // If the possession contains a shot or cross
  [SoccerStatKeysObj.tPossessionsWithShotChance]: {
    statKey: SoccerStatKeysObj.tPossessionsWithShotChance,
    statType: "team",
    compute: ({ thisTeamFinalPossessionSets }) => {
      return thisTeamFinalPossessionSets.filter(poss => {
        return !!_.findLast(poss.events, evt => isShotEvent(evt) || isSoccerCrossEvent(evt));
      }).length;
    }
  },

  [SoccerStatKeysObj.tSuccessRateWithBallPerc]: {
    statKey: SoccerStatKeysObj.tSuccessRateWithBallPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const theseStats = stats.teamStats[getTeamStatType(teamId)];
      const touches = theseStats[TeamStatKeys.tTouches] || 0;
      const bottom = touches;
      const top = touches - (theseStats[TeamStatKeys.tTurnovers] || 0);

      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const touches = teamTotalsAndAverages.total[SoccerStatKeysObj.tTouches] || 0;
      const turnovers = teamTotalsAndAverages.total[SoccerStatKeysObj.tTurnovers] || 0;
      const bottom = touches;
      const top = touches - turnovers;
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },

  [SoccerStatKeysObj.tGoals]: {
    statKey: SoccerStatKeysObj.tGoals,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isGoalEvent(event) && event.playerTeamId === teamId);
    }
  },
  //If the possession set after the corner kick contains a shot
  [SoccerStatKeysObj.tSuccessfulCornerKicks]: {
    statKey: SoccerStatKeysObj.tSuccessfulCornerKicks,
    statType: "team",
    reduce: ({ acc, teamId, event, currentPossessionSetDetails }) => {
      if (isSoccerCornerKickEvent(event) && event.playerTeamId === teamId) {
        const { eventIndex, info } = currentPossessionSetDetails;
        return (acc ?? 0) + Number(!!_.findLast(info.events.slice(eventIndex + 1), evt => isShotEvent(evt)));
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.tCompletedPasses]: {
    //NOTE: Keep this logic in sync with the possession logic in StatsEntryMemoryController.tsx that is used to set possession percent in live game mode
    statKey: SoccerStatKeysObj.tCompletedPasses,
    statType: "team",
    compute: ({ thisTeamFinalPossessionSets }) => {
      return thisTeamFinalPossessionSets.reduce((acc, set) => acc + set.passChainPlayerIds.length - 1, 0);
    }
  },
  [SoccerStatKeysObj.tTurnovers]: {
    statKey: SoccerStatKeysObj.tTurnovers,
    statType: "team",
    reduce: ({ acc, ...rest }) => {
      return (acc ?? 0) + Number(isTurnover(rest));
    }
  },
  [SoccerStatKeysObj.tRecoveries]: {
    statKey: SoccerStatKeysObj.tRecoveries,
    statType: "team",
    reduce: ({ acc, ...rest }) => {
      return (acc ?? 0) + Number(isRecovery(rest));
    }
  },
  [SoccerStatKeysObj.tCrosses]: {
    statKey: SoccerStatKeysObj.tCrosses,
    statType: "team",
    compute: ({ memoryDB, teamId }) => {
      return memoryDB.find(SoccerCrossEventTypeObject).filter(evt => evt.playerTeamId === teamId).length;
    }
  },
  [SoccerStatKeysObj.tSuccessfulCrosses]: {
    statKey: SoccerStatKeysObj.tSuccessfulCrosses,
    statType: "team",
    reduce: ({ acc, teamId, event, currentPossessionSetDetails }) => {
      if (isSoccerCrossEvent(event) && event.playerTeamId === teamId) {
        const { eventIndex, info } = currentPossessionSetDetails;
        //Was successful if the cross's possession included a shot.
        return (acc ?? 0) + Number(!!_.findLast(info.events.slice(eventIndex + 1), evt => isShotEvent(evt)));
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.tShots]: {
    statKey: SoccerStatKeysObj.tShots,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isShotEvent(event) && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tShotsOnTarget]: {
    statKey: SoccerStatKeysObj.tShotsOnTarget,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isShotEvent(event) && event.playerTeamId === teamId && event.shotIsOnTarget);
    }
  },
  [SoccerStatKeysObj.tGoalieBlocks]: {
    statKey: SoccerStatKeysObj.tGoalieBlocks,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      if (
        event.type === SoccerGameEventType.shot &&
        event.shotResultType === ShotResultType.goalieShotBlock &&
        event.playerTeamId !== teamId
      ) {
        return (acc ?? 0) + 1;
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.tGoalieCatches]: {
    statKey: SoccerStatKeysObj.tGoalieCatches,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      if (
        event.type === SoccerGameEventType.shot &&
        event.shotResultType === ShotResultType.goalieShotCatch &&
        event.playerTeamId !== teamId
      ) {
        return (acc ?? 0) + 1;
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.tFouls]: {
    statKey: SoccerStatKeysObj.tFouls,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.foulInfraction && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tOffsides]: {
    statKey: SoccerStatKeysObj.tOffsides,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.offsideInfraction && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tCards]: {
    statKey: SoccerStatKeysObj.tCards,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isSoccerCardEvent(event) && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tYellowCards]: {
    statKey: SoccerStatKeysObj.tYellowCards,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.yellowCardInfraction && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tRedCards]: {
    statKey: SoccerStatKeysObj.tRedCards,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.redCardInfraction && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tHandballs]: {
    statKey: SoccerStatKeysObj.tHandballs,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.handballInfraction && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tFreeKicks]: {
    statKey: SoccerStatKeysObj.tFreeKicks,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isSoccerFreeKickEvent(event) && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tThrowIns]: {
    statKey: SoccerStatKeysObj.tThrowIns,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.throwIn && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tSuccessfulThrowIns]: {
    statKey: SoccerStatKeysObj.tSuccessfulThrowIns,
    statType: "team",
    reduce: ({ acc, teamId, event, currentPossessionSetDetails }) => {
      if (event.type === SoccerGameEventType.throwIn && event.playerTeamId === teamId) {
        //It was a successful throw-in if the throw-in wasn't the the last event in the possession set.
        return (acc ?? 0) + Number(currentPossessionSetDetails.info.events.slice(-1).pop()?.id !== event.id);
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.tThrowInInfractions]: {
    statKey: SoccerStatKeysObj.tThrowInInfractions,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.throwInInfraction && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tCornerKicks]: {
    statKey: SoccerStatKeysObj.tCornerKicks,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isSoccerCornerKickEvent(event) && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tGoalKicks]: {
    statKey: SoccerStatKeysObj.tGoalKicks,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isSoccerGoalKickEvent(event) && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tSuccessfulGoalKicks]: {
    statKey: SoccerStatKeysObj.tSuccessfulGoalKicks,
    statType: "team",
    reduce: ({ acc, teamId, event, currentPossessionSetDetails }) => {
      if (isSoccerGoalKickEvent(event) && event.playerTeamId === teamId) {
        //It was a successful goal kick if it wasn't the the last event in the possession set.
        return (acc ?? 0) + Number(currentPossessionSetDetails.info.events.slice(-1).pop()?.id !== event.id);
      }
      return acc ?? 0;
    }
  },

  [SoccerStatKeysObj.tPossessionPerc]: {
    statKey: SoccerStatKeysObj.tPossessionPerc,
    statType: "team",
    //NOTE: Keep this logic in sync with the possession logic in StatsEntryMemoryController.tsx that is used to set possession percent in live game mode
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tCompletedPasses];
      const bottom = ObjectKeys(stats.teamStats).reduce(
        (acc, thisTeamId) => acc + (stats.teamStats[thisTeamId][TeamStatKeys.tCompletedPasses] || 0),
        0
      );
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      return teamTotalsAndAverages.avg[SoccerStatKeysObj.tPossessionPerc] || null;
    }
  },
  [SoccerStatKeysObj.tTurnoverPerc]: {
    statKey: SoccerStatKeysObj.tTurnoverPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      return 1 - (stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tSuccessRateWithBallPerc] || 0);
    },
    computePriority: 1,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const touches = teamTotalsAndAverages.total[SoccerStatKeysObj.tTouches];
      const turnovers = teamTotalsAndAverages.total[SoccerStatKeysObj.tTurnovers];
      const bottom = touches;
      const top = turnovers;
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tPossessionsWithShotChancePerc]: {
    statKey: SoccerStatKeysObj.tPossessionsWithShotChancePerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tPossessionsWithShotChance];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tPossessions];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tPossessionsWithShotChance];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tPossessions];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tAvgPassesPerPoss]: {
    statKey: SoccerStatKeysObj.tAvgPassesPerPoss,
    statType: "team",
    computeStageTwo: ({ thisTeamFinalPossessionSets }) => {
      if (thisTeamFinalPossessionSets.length) {
        return (
          thisTeamFinalPossessionSets.reduce((acc, set) => acc + Math.max(set.passChainPlayerIds.length - 1, 0), 0) /
          thisTeamFinalPossessionSets.length
        );
      }
      return null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.tAvgPassesPerPossStartingWithGoalKick]: {
    statKey: SoccerStatKeysObj.tAvgPassesPerPossStartingWithGoalKick,
    statType: "team",
    compute: ({ thisTeamFinalPossessionSets }) => {
      const possessionsStartingFromAGoalKick = thisTeamFinalPossessionSets.filter(a => isSoccerGoalKickEvent(a.events[0]));

      if (!possessionsStartingFromAGoalKick.length) {
        return null;
      }

      return (
        possessionsStartingFromAGoalKick.reduce((acc, set) => acc + Math.max(set.passChainPlayerIds.length - 1, 0), 0) /
        possessionsStartingFromAGoalKick.length
      );
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.tAvgPassesAfterGoalieTouch]: {
    statKey: SoccerStatKeysObj.tAvgPassesAfterGoalieTouch,
    statType: "team",
    complexReduce: {
      initialAcc: () => ({ numPossessionSets: 0, numPasses: 0 }),
      reduce: ({ acc, event, currentPossessionSetDetails, currentPlayerIdToFieldPositionNum, teamId }) => {
        //total passes after goalie touch / number of possessions with goalie touch
        const isGoalieTouch = (thisEvent: SoccerGameEvent): thisEvent is SoccerTouchEvent =>
          isTouchEvent(thisEvent) &&
          (currentPlayerIdToFieldPositionNum[thisEvent.playerId] === SoccerPositionNumber.n1 ||
            thisEvent.playerId === SoccerIds.opponentGoalie);

        if (isGoalieTouch(event) && event.playerTeamId === teamId) {
          const possessionSetEvents = currentPossessionSetDetails.info.events;
          const eventIndex = possessionSetEvents.findIndex(a => a.id === event.id);
          if (possessionSetEvents.slice(0, eventIndex).find(a => isGoalieTouch(a))) {
            return acc; //An earlier goalie touch happened in this possession. Ignore.
          }

          const passChain = currentPossessionSetDetails.info.passChainPlayerIds;
          const passChainAfterEvent = passChain.slice(passChain.findIndex(a => a === event.playerId));

          return {
            numPossessionSets: acc.numPossessionSets + 1,
            numPasses: acc.numPasses + (passChainAfterEvent.length - 1)
          };
        }

        return acc;
      },
      finalizeAcc: acc => {
        return acc.numPossessionSets ? acc.numPasses / acc.numPossessionSets : null;
      }
    }
  },
  [SoccerStatKeysObj.tLongestPassingChain]: {
    statKey: SoccerStatKeysObj.tLongestPassingChain,
    statType: "team",
    computeStageTwo: ({ thisTeamFinalPossessionSets }) => {
      if (!thisTeamFinalPossessionSets.length) {
        return null;
      }
      return Math.max(Math.max(...thisTeamFinalPossessionSets.map(set => set.passChainPlayerIds.length)) - 1, 0);
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.tAvgPassesOnGoals]: {
    statKey: SoccerStatKeysObj.tAvgPassesOnGoals,
    statType: "team",
    compute: ({ memoryDB, finalEventIdToPossessionSetDetails, teamId }) => {
      const goals = memoryDB.find({ [SoccerGameEventType.shotWithGoal]: true }).filter(evt => evt.playerTeamId === teamId);

      if (!goals.length) {
        return null;
      }

      return (
        goals.reduce((acc, evt) => {
          return Math.max(acc + finalEventIdToPossessionSetDetails[evt.id].info.passChainPlayerIds.length - 1, 0);
        }, 0) / goals.length
      );
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.tSuccessfulGoalKickPerc]: {
    statKey: SoccerStatKeysObj.tSuccessfulGoalKickPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tSuccessfulGoalKicks];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tGoalKicks];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tSuccessfulGoalKicks];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tGoalKicks];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tSuccessfulThrowInPerc]: {
    statKey: SoccerStatKeysObj.tSuccessfulThrowInPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tSuccessfulThrowIns];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tThrowIns];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tSuccessfulThrowIns];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tThrowIns];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tSuccessfulCornerKickPerc]: {
    statKey: SoccerStatKeysObj.tSuccessfulCornerKickPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tSuccessfulCornerKicks];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tCornerKicks];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tSuccessfulCornerKicks];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tCornerKicks];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tSuccessfulCrossPerc]: {
    statKey: SoccerStatKeysObj.tSuccessfulCrossPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tSuccessfulCrosses];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tCrosses];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tSuccessfulCrosses];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tCrosses];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tShotsOnTargetPerc]: {
    statKey: SoccerStatKeysObj.tShotsOnTargetPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tShotsOnTarget];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tShotsOnTarget];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tShotFinishingPerc]: {
    statKey: SoccerStatKeysObj.tShotFinishingPerc,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const top = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tGoals];
      const bottom = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ teamTotalsAndAverages }) => {
      const top = teamTotalsAndAverages.total[SoccerStatKeysObj.tGoals];
      const bottom = teamTotalsAndAverages.total[SoccerStatKeysObj.tShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.tSaves]: {
    statKey: SoccerStatKeysObj.tSaves,
    statType: "team",
    computeStageTwo: ({ stats, teamId }) => {
      const catches = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tGoalieCatches];
      const blocks = stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tGoalieBlocks];
      return typeof catches === "number" || typeof blocks === "number" ? (blocks ?? 0) + (catches ?? 0) : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.tPenaltyKicksScored]: {
    statKey: SoccerStatKeysObj.tPenaltyKicksScored,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      if (
        event.type === SoccerGameEventType.shotWithGoal &&
        event.shotReason === "infraction-pk" &&
        event.playerTeamId === teamId
      ) {
        return (acc ?? 0) + 1;
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.tPenaltyKicksAttempted]: {
    statKey: SoccerStatKeysObj.tPenaltyKicksAttempted,
    statType: "team",
    reduce: ({ acc, teamId, event }) => {
      return (acc ?? 0) + Number(isShotEvent(event) && event.shotReason === "infraction-pk" && event.playerTeamId === teamId);
    }
  },
  [SoccerStatKeysObj.tGoalieShutouts]: {
    statKey: SoccerStatKeysObj.tGoalieShutouts,
    statType: "team",
    computeStageTwo: ({ stats, oppositeTeamId }) => {
      return Number(!stats.teamStats[getTeamStatType(oppositeTeamId)][TeamStatKeys.tGoals]);
    }
  },
  [SoccerStatKeysObj.tGoalsConceded]: {
    statKey: SoccerStatKeysObj.tGoalsConceded,
    statType: "team",
    computeStageTwo: ({ stats, oppositeTeamId }) => {
      return stats.teamStats[getTeamStatType(oppositeTeamId)][TeamStatKeys.tGoals] || 0;
    }
  },
  [SoccerStatKeysObj.tGoalDifferential]: {
    statKey: SoccerStatKeysObj.tGoalDifferential,
    statType: "team",
    computeStageTwo: ({ stats, oppositeTeamId, teamId }) => {
      return (
        (stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tGoals] || 0) -
        (stats.teamStats[getTeamStatType(oppositeTeamId)][TeamStatKeys.tGoals] || 0)
      );
    }
  },
  [SoccerStatKeysObj.tShotDifferential]: {
    statKey: SoccerStatKeysObj.tShotDifferential,
    statType: "team",
    computeStageTwo: ({ stats, oppositeTeamId, teamId }) => {
      return (
        (stats.teamStats[getTeamStatType(teamId)][TeamStatKeys.tShots] || 0) -
        (stats.teamStats[getTeamStatType(oppositeTeamId)][TeamStatKeys.tShots] || 0)
      );
    }
  }
};

// i18n certified - complete

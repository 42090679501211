import { SoccerPositionTypes, SoccerStatKeysObj, PlayerStatKeys, SoccerStatSnapshot } from "@ollie-sports/models";
import { ObjectKeys } from "../utils";
import _ from "lodash";

//NOTE: Stats with a weight of less than MIN_NEGATIVE_WEIGHT do not show up on the belowAverage list

const DefenderWeights = {
  [PlayerStatKeys.pRecoveriesPerHour]: 1,
  [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
  [PlayerStatKeys.pTouchesPerHour]: 0.8,
  [PlayerStatKeys.pFoulsPerHour]: 0.8,
  [PlayerStatKeys.pCompletedPassesPerHour]: 0.7,
  [PlayerStatKeys.pPossessionParticipationPerc]: 0.6,
  [PlayerStatKeys.pAssistsPerHour]: 0.6,
  [PlayerStatKeys.pKeyPassesPerHour]: 0.6,
  [PlayerStatKeys.pCrossesPerHour]: 0.4,
  [PlayerStatKeys.pShotsPerHour]: 0.4,
  [PlayerStatKeys.pShotsOnTargetPerHour]: 0.4,
  [PlayerStatKeys.pGoalsPerHour]: 0.4,
  [PlayerStatKeys.pSuccessfulCrossesPerc]: 0.4
};

const MidfielderWeights = {
  [PlayerStatKeys.pRecoveriesPerHour]: 1,
  [PlayerStatKeys.pFoulsPerHour]: 1,
  [PlayerStatKeys.pAssistsPerHour]: 1,
  [PlayerStatKeys.pKeyPassesPerHour]: 1,
  [PlayerStatKeys.pCompletedPassesPerHour]: 1,
  [PlayerStatKeys.pCrossesPerHour]: 1,
  [PlayerStatKeys.pPossessionParticipationPerc]: 1,
  [PlayerStatKeys.pShotsPerHour]: 1,
  [PlayerStatKeys.pShotsOnTargetPerHour]: 1,
  [PlayerStatKeys.pGoalsPerHour]: 1,
  [PlayerStatKeys.pSuccessfulCrossesPerc]: 1,
  [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
  [PlayerStatKeys.pTouchesPerHour]: 1
};

const ForwardWeights = {
  [PlayerStatKeys.pRecoveriesPerHour]: 1,
  [PlayerStatKeys.pFoulsPerHour]: 1,
  [PlayerStatKeys.pAssistsPerHour]: 1,
  [PlayerStatKeys.pKeyPassesPerHour]: 1,
  [PlayerStatKeys.pCompletedPassesPerHour]: 1,
  [PlayerStatKeys.pCrossesPerHour]: 1,
  [PlayerStatKeys.pShotsPerHour]: 1,
  [PlayerStatKeys.pShotsOnTargetPerHour]: 1,
  [PlayerStatKeys.pGoalsPerHour]: 1,
  [PlayerStatKeys.pSuccessfulCrossesPerc]: 1,
  [PlayerStatKeys.pTouchesPerHour]: 0.8,
  [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
  [PlayerStatKeys.pPossessionParticipationPerc]: 0.8
};

const GoalieWeights = {
  [PlayerStatKeys.pShotsSaved]: 1,
  [PlayerStatKeys.pShotSavedPerc]: 1,
  [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
  [PlayerStatKeys.pShotsCaught]: 1
};

const weights: Record<SoccerPositionTypes, { [str in PlayerStatKeys]?: number }> = {
  [SoccerPositionTypes.defender]: DefenderWeights,
  [SoccerPositionTypes.midfielder]: MidfielderWeights,
  [SoccerPositionTypes.forward]: ForwardWeights,
  [SoccerPositionTypes.goalkeeper]: GoalieWeights
};

export interface SoccerGameStatHighlights {
  aboveAverage: {
    key: PlayerStatKeys;
    val: number;
  }[];
  belowAverage:
    | {
        key: PlayerStatKeys;
        val: number;
      }[]
    | null;
}

export function computeSoccerGameHighlights(p: { snapshot: SoccerStatSnapshot; playerId: string }): SoccerGameStatHighlights {
  const position = p.snapshot.bestFitPlayerIdToPositionType[p.playerId];

  if (!position) {
    return { aboveAverage: [], belowAverage: null };
  }

  const playerStats = p.snapshot.playerStats;
  const normStats = p.snapshot.normalizedPlayerStats;
  const normPosStats = p.snapshot.normalizedPositionStats;

  const playerScores: {
    weightedVal: number;
    positionNormValDiff: number;
    val: number;
    key: PlayerStatKeys;
    weight: number;
  }[] = [];
  ObjectKeys(weights[position]).forEach(statKey => {
    const normValDiff = (normStats[p.playerId][statKey] || 0) - (normPosStats[position][statKey] || 0);
    const weight = weights[position][statKey] || 0;

    playerScores.push({
      key: statKey,
      positionNormValDiff: normValDiff,
      weightedVal: normValDiff * weight,
      weight,
      val: playerStats[p.playerId][statKey] as number
    });
  });

  const sortedPlayerScores = _.orderBy(playerScores, ["weightedVal", "weight"], "desc");

  const aboveAveragePairs = sortedPlayerScores.filter(a => a.val).slice(0, 4);
  const belowAveragePairs = sortedPlayerScores
    .filter(a => !aboveAveragePairs.find(b => b.key === a.key))
    .slice(-4)
    .reverse();

  return {
    aboveAverage: aboveAveragePairs,
    belowAverage: belowAveragePairs.length ? belowAveragePairs : null
  };
}

// i18n certified - complete

import {
  SoccerStatSnapshot,
  PlayerId,
  SoccerStatKeysObj,
  SoccerIds,
  PostStartedSoccerGame,
  SoccerGameLeaderboardTypes,
  PlayerStatKeys,
  SoccerStatKeys,
  MvpTypes,
  SoccerPositionTypes,
  PrettyPlayer
} from "@ollie-sports/models";
import { ObjectKeys } from "../utils";
import _ from "lodash";
import { getSoccerStatDefinitions } from "./SoccerStatDefinitions";
import { translate } from "@ollie-sports/i18n";

//Re-export this for backwards compat. This enum used to be defined in this file.
export { SoccerGameLeaderboardTypes } from "@ollie-sports/models";

export type SoccerGameMVPs = Record<MvpTypes, { playerId: PlayerId; value: number }[]>;
export type SoccerGameLeaderboards = {
  [type in SoccerGameLeaderboardTypes]?: { playerId: PlayerId; value: number }[];
};

export type AwardInfo = {
  mvps: Record<
    MvpTypes,
    {
      playerId: string;
      value: number;
    }[]
  >;
  leaderboards: SoccerGameLeaderboards;
};

export function computeSoccerGameAwards(p: {
  snapshot: SoccerStatSnapshot;
  game: PostStartedSoccerGame;
  showAllPlayersAsMVPNominees?: boolean; //If the user has a setting or something...
  locale: string;
}): AwardInfo {
  let mvps = computeSoccerGameMVPs(p.snapshot);
  let leaderboards = computeSoccerGameLeaderboards({
    snapshot: p.snapshot,
    game: p.game,
    soccerGameMVPs: mvps,
    locale: p.locale
  });

  const showAllPlayersAsMVPNominees = p.game.statMode === "team" || p.showAllPlayersAsMVPNominees || p.game.votingMode === "all"; //Have to show all nominees in team mode because there are no stats
  mvps = showAllPlayersAsMVPNominees ? mvps : _.mapValues(mvps, (arr, key) => arr.slice(0, (key as MvpTypes) === "mvp" ? 5 : 4));
  leaderboards = _.mapValues(leaderboards, arr => arr?.slice(0, 5));

  if (p.game.gameStage === "ended" && p.game.leaderboardWinners) {
    //Ensure the first person on the list is always the official leaderboard winner, even if definitions have changed and they no longer are
    const winners = p.game.leaderboardWinners;
    ObjectKeys(p.game.leaderboardWinners).forEach(leaderboard => {
      const firstPlacePlayerId = winners[leaderboard];
      if (firstPlacePlayerId) {
        leaderboards[leaderboard] = leaderboards[leaderboard]?.sort((a, b) => {
          const aVal = a.playerId === firstPlacePlayerId ? Infinity : a.value;
          return b.value - aVal;
        });
      }
    });
  }

  return {
    mvps,
    leaderboards
  };
}

const OffenseRelevantStatWeights = {
  [PlayerStatKeys.pCompletedPassesPerHour]: 3,
  [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
  [PlayerStatKeys.pShotsPerHour]: 1,
  [PlayerStatKeys.pShotsOnTargetPerHour]: 2,
  [PlayerStatKeys.pGoalsPerHour]: 5,
  [PlayerStatKeys.pKeyPassesPerHour]: 3,
  [PlayerStatKeys.pAssistsPerHour]: 4,
  [PlayerStatKeys.pCrossesPerHour]: 1,
  [PlayerStatKeys.pSuccessfulCrossesPerc]: 1
};

const DefenseRelevantStatWeights = {
  [PlayerStatKeys.pRecoveriesPerHour]: 2,
  [PlayerStatKeys.pTouchesPerHour]: 1,
  [PlayerStatKeys.pCompletedPassesPerHour]: 1,
  [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
  [PlayerStatKeys.pShotSavedPerc]: 1
};

export const getSoccerGameMVPMeta = _.memoize(
  (
    locale: string
  ): Record<
    MvpTypes,
    {
      prettyDefinition: string;
      relevantStatWeights: {
        [key in PlayerStatKeys]?: number;
      };
      compute: (a: {
        snapshot: SoccerStatSnapshot;
        playerId: string;
        awardComputationOnlyStats: AwardComputationOnlyStats;
        standardizedBestOffense: {
          playerId: string;
          value: number;
        }[];
        standardizedBestDefense: {
          playerId: string;
          value: number;
        }[];
      }) => number;
    }
  > => {
    return {
      bestOffense: {
        prettyDefinition: translate({ defaultMessage: "Best Overall Offensive Contribution", serverLocale: locale }),
        relevantStatWeights: OffenseRelevantStatWeights,
        compute: ({ snapshot, playerId }) => {
          const normStats = snapshot.normalizedPlayerStats[playerId] || {};
          const regularStats = snapshot.playerStats[playerId];

          if (regularStats[SoccerStatKeysObj.pRedCards] === 1 || regularStats[SoccerStatKeysObj.pYellowCards] === 2) {
            return 0;
          }

          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);

          let passContribution =
            (normStats[SoccerStatKeysObj.pCompletedPassesPerHour] || 0) *
            (regularStats[SoccerStatKeysObj.pSuccessRateWithBallPerc] || 0) *
            OffenseRelevantStatWeights[SoccerStatKeysObj.pCompletedPassesPerHour];
          const timeSpentAtPositions = snapshot.timeSpentAtPositionByPlayerId[playerId];
          if (timeSpentAtPositions.goalkeeper) {
            const totalTime = snapshot.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0;
            const percTimeAsGK = timeSpentAtPositions.goalkeeper / totalTime;
            passContribution = passContribution * (1 - percTimeAsGK);
          }

          //TODO: Determine if we need to adjust all these per hour stats up for players who split time between goalies and offense
          let score =
            playingTimeScale *
            (passContribution +
              (normStats[SoccerStatKeysObj.pShotsPerHour] || 0) * OffenseRelevantStatWeights[SoccerStatKeysObj.pShotsPerHour] +
              (normStats[SoccerStatKeysObj.pShotsOnTargetPerHour] || 0) *
                OffenseRelevantStatWeights[SoccerStatKeysObj.pShotsOnTargetPerHour] +
              (normStats[SoccerStatKeysObj.pGoalsPerHour] || 0) * OffenseRelevantStatWeights[SoccerStatKeysObj.pGoalsPerHour] +
              (normStats[SoccerStatKeysObj.pKeyPassesPerHour] || 0) *
                OffenseRelevantStatWeights[SoccerStatKeysObj.pKeyPassesPerHour] +
              (normStats[SoccerStatKeysObj.pAssistsPerHour] || 0) *
                OffenseRelevantStatWeights[SoccerStatKeysObj.pAssistsPerHour] +
              (normStats[SoccerStatKeysObj.pCrossesPerHour] || 0) *
                OffenseRelevantStatWeights[SoccerStatKeysObj.pCrossesPerHour] *
                (regularStats[SoccerStatKeysObj.pSuccessfulCrossesPerc] || 0) *
                OffenseRelevantStatWeights[SoccerStatKeysObj.pSuccessfulCrossesPerc]);

          return score;
        }
      },
      bestDefense: {
        prettyDefinition: translate({ defaultMessage: "Best Overall Defensive Contribution", serverLocale: locale }),
        relevantStatWeights: DefenseRelevantStatWeights,
        compute: ({ snapshot, playerId, awardComputationOnlyStats }) => {
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          const normStats = snapshot.normalizedPlayerStats[playerId] || {};
          const numericStats = snapshot.playerStats[playerId] || {};

          if (numericStats[SoccerStatKeysObj.pRedCards] === 1 || numericStats[SoccerStatKeysObj.pYellowCards] === 2) {
            return 0;
          }

          const recoveriesPerHr =
            (awardComputationOnlyStats.normalizedAdjustedRecoveriesPerHour[playerId] || 0) *
            DefenseRelevantStatWeights[SoccerStatKeysObj.pRecoveriesPerHour];

          const touchesPerHr =
            (normStats[SoccerStatKeysObj.pTouchesPerHour] || 0) * DefenseRelevantStatWeights[SoccerStatKeysObj.pTouchesPerHour];
          const goodPassesPerHr =
            (normStats[SoccerStatKeysObj.pCompletedPassesPerHour] || 0) *
            DefenseRelevantStatWeights[SoccerStatKeysObj.pCompletedPassesPerHour] *
            (numericStats[SoccerStatKeysObj.pSuccessRateWithBallPerc] || 0);

          //Goes from zero to one
          const normalizedFieldPlayerScore =
            (recoveriesPerHr + goodPassesPerHr + touchesPerHr) /
            ObjectKeys(DefenseRelevantStatWeights).reduce((a, b) => a + DefenseRelevantStatWeights[b], 0);

          const fieldPlayerScore = normalizedFieldPlayerScore;

          let finalScore = fieldPlayerScore;
          const timeSpentAtPositions = snapshot.timeSpentAtPositionByPlayerId[playerId];

          if (timeSpentAtPositions.goalkeeper) {
            const stats = snapshot.playerStats[playerId] || {};

            const saveRate = stats[SoccerStatKeysObj.pShotSavedPerc] || 0;
            const saveRateScore = saveRate >= 0.6 ? (saveRate - 0.6) * 1.25 : 0; //Linear function going from 0 to 0.5, with 0 at 60% save percent, 0.25 at 80%, and 0.5 at 100% clean sheet

            const totalSaves = stats[SoccerStatKeysObj.pShotsSaved] || 0;
            //http://www.wolframalpha.com/input/?i=0.5988898+-+0.8585903+*+e%5E%28-0.1801072+*+x%29+from+0+to+15
            //Exponential function going from 0 to 0.5, with 0 at 2 shots saved, 0.25 at 5 shots saved, and 0.5 at 15 shots saved
            let totalSavesScore = Math.max(Math.min(0.5988898 - 0.8585903 * Math.exp(-0.1801072 * totalSaves), 0.5), 0);

            if (saveRate >= 0.99 && totalSaves > 0) {
              //But if they have a clean sheet and saved at least one shot, add an additional .20 to their totalSavesScore
              //since theoretically they could have won us the game as long as our offense did their job
              totalSavesScore = Math.min(totalSavesScore + 0.1, 0.5);
            }

            const gkScore = saveRateScore + totalSavesScore;

            const goalieTime = timeSpentAtPositions.goalkeeper;
            const fieldTime = timeSpentAtPositions.defender + timeSpentAtPositions.midfielder + timeSpentAtPositions.forward;
            const totalTime = goalieTime + fieldTime;
            const goalieTimePercent = goalieTime / totalTime;
            const fieldTimePercent = fieldTime / totalTime;

            finalScore = gkScore * goalieTimePercent + fieldPlayerScore * fieldTimePercent;
          }

          return finalScore * playingTimeScale;
        }
      },
      mvp: {
        prettyDefinition: translate({ defaultMessage: "Best Overall Contribution", serverLocale: locale }),
        relevantStatWeights: { ...DefenseRelevantStatWeights, ...OffenseRelevantStatWeights },
        compute: ({ snapshot, playerId, standardizedBestDefense, standardizedBestOffense }) => {
          //NOTE: We use standardized values rather than normalized values so that an offense and defense aren't always equally weighted if someone has just a ridiculous score in one of them.
          const defense = standardizedBestDefense.find(pl => pl.playerId === playerId)?.value || 0;
          const offense = standardizedBestOffense.find(pl => pl.playerId === playerId)?.value || 0;

          return defense + offense;
        }
      }
    };
  }
);

export const getSoccerGameLeaderboardMeta = _.memoize(
  (
    locale: string
  ): Record<
    SoccerGameLeaderboardTypes,
    {
      prettyTitle: string;
      mostRelevantStats: {
        [key in PlayerStatKeys]?: number;
      };
      stringFormatHint: "number" | "percent";
      prettyDefinition: string;
      prettyDefinitionModifier: string;
      compute: (a: {
        snapshot: SoccerStatSnapshot;
        playerId: string;
        game: PostStartedSoccerGame;
        mvps: SoccerGameMVPs;
        awardComputationOnlyStats: AwardComputationOnlyStats;
      }) => number;
    }
  > => {
    return {
      passEfficiency: {
        prettyTitle: translate({ defaultMessage: "Architect", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pSuccessRateWithBallPerc]: 1,
          [PlayerStatKeys.pCompletedPassesPerHour]: 1
        },
        stringFormatHint: "number",
        prettyDefinition: translate(
          { defaultMessage: `Adjusted {stat}`, serverLocale: locale },
          { stat: getStatTitle(SoccerStatKeysObj.pCompletedPassesPerHour, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down for turnover % and if playing time < 60%",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          const turnoverAdjustment = (stats[SoccerStatKeysObj.pSuccessRateWithBallPerc] || 0) + 0.2; //A 80% possession success rate is the top
          return (stats[SoccerStatKeysObj.pCompletedPassesPerHour] || 0) * turnoverAdjustment * playingTimeScale;
        }
      },
      possessionSuccess: {
        prettyTitle: translate({
          defaultMessage: "Guardian",
          description: "For award of player who has the most success keeping the ball",
          serverLocale: locale
        }),
        mostRelevantStats: {
          [PlayerStatKeys.pSuccessRateWithBallPerc]: 1
        },
        stringFormatHint: "percent",
        prettyDefinition: translate(
          { defaultMessage: `Adjusted {stat}`, serverLocale: locale },
          { stat: getStatTitle(PlayerStatKeys.pSuccessRateWithBallPerc, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down if played less than 60% of game",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }

          const stats = snapshot.playerStats[playerId] || {};
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          return (stats[SoccerStatKeysObj.pSuccessRateWithBallPerc] || 0) * playingTimeScale;
        }
      },
      shotEfficiency: {
        prettyTitle: translate({ defaultMessage: "Marksman", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pShotsPerHour]: 1,
          [PlayerStatKeys.pShotsOnTargetPerHour]: 3,
          [PlayerStatKeys.pGoalsPerHour]: 6
        },
        stringFormatHint: "number",
        prettyDefinition: translate({ defaultMessage: `Shot and Goal Efficiency Index`, serverLocale: locale }),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down if played less than 60% of game",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          return (
            ((stats[SoccerStatKeysObj.pShotsPerHour] || 0) +
              (stats[SoccerStatKeysObj.pShotsOnTargetPerHour] || 0) * 3 +
              (stats[SoccerStatKeysObj.pGoalsPerHour] || 0) * 6) *
            playingTimeScale
          );
        }
      },
      crosses: {
        prettyTitle: translate({ defaultMessage: "Bombardier", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pCrossesPerHour]: 1,
          [PlayerStatKeys.pSuccessfulCrossesPerc]: 1
        },
        stringFormatHint: "number",
        prettyDefinition: translate(
          { defaultMessage: `Adjusted {stat}`, serverLocale: locale },
          { stat: getStatTitle(SoccerStatKeysObj.pCrossesPerHour, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted for cross success % and if playing time < 60%",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};
          const regularStats = snapshot.playerStats[playerId];
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          return (
            (stats[SoccerStatKeysObj.pCrossesPerHour] || 0) *
            ((regularStats[SoccerStatKeysObj.pSuccessfulCrossesPerc] || 0) + 0.5) * //a 50% cross success rate is considered pretty good
            playingTimeScale
          );
        }
      },
      assists: {
        prettyTitle: translate({ defaultMessage: "Playmaker", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pAssistsPerHour]: 3,
          [PlayerStatKeys.pKeyPassesPerHour]: 1
        },
        stringFormatHint: "number",
        prettyDefinition: translate({ defaultMessage: `Passes Leading to Shots and Goals / Hour`, serverLocale: locale }),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted for number of goals and if playing time < 60%",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          return (
            ((stats[SoccerStatKeysObj.pAssistsPerHour] || 0) * 3 + (stats[SoccerStatKeysObj.pKeyPassesPerHour] || 0)) *
            playingTimeScale
          );
        }
      },
      touches: {
        prettyTitle: translate({ defaultMessage: "Dynamo", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pTouchesPerHour]: 1
        },
        stringFormatHint: "number",
        prettyDefinition: translate(
          {
            defaultMessage: `Adjusted {stat}`,
            serverLocale: locale
          },
          { stat: getStatTitle(SoccerStatKeysObj.pTouchesPerHour, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down for turnovers and if playing time < 60%",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          const stats = snapshot.playerStats[playerId] || {};
          const playingTime = stats[SoccerStatKeysObj.pPlayingTimeMS];

          if (isKeeper(snapshot, playerId) || !playingTime) {
            return 0;
          }

          const touches = stats[SoccerStatKeysObj.pTouches] || 0;
          const qsRecoveries = stats[SoccerStatKeysObj.pQuickSelfTurnoversAndRecoveries] || 0;
          const adjustedTouchesPerHour = (touches - qsRecoveries) / (playingTime / (1000 * 60 * 60));
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);

          return adjustedTouchesPerHour * playingTimeScale;
        }
      },
      possessionParticipation: {
        prettyTitle: translate({ defaultMessage: "Commander", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pPossessionParticipationPerc]: 1
        },
        stringFormatHint: "percent",
        prettyDefinition: translate(
          {
            defaultMessage: `Adjusted {stat}`,
            serverLocale: locale
          },
          { stat: getStatTitle(SoccerStatKeysObj.pPossessionParticipationPerc, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down if played less than 60% of game",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};

          if ((stats[SoccerStatKeysObj.pTouches] || 0) < 10) {
            return 0;
          }

          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);

          return (stats[SoccerStatKeysObj.pPossessionParticipationPerc] || 0) * playingTimeScale;
        }
      },
      recoveries: {
        prettyTitle: translate({ defaultMessage: "Bandit", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pRecoveriesPerHour]: 1
        },
        stringFormatHint: "number",
        prettyDefinition: translate(
          {
            defaultMessage: `Adjusted {stat}`,
            serverLocale: locale
          },
          { stat: getStatTitle(SoccerStatKeysObj.pRecoveriesPerHour, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down for recovered sloppy touches and if playing time < 60%",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId, awardComputationOnlyStats }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          const val = (awardComputationOnlyStats.adjustedRecoveriesPerHour[playerId] || 0) * playingTimeScale;

          return val;
        }
      },
      fouls: {
        prettyTitle: translate({ defaultMessage: "Enforcer", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pFoulsPerHour]: 1
        },
        stringFormatHint: "number",
        prettyDefinition: translate(
          {
            defaultMessage: `Adjusted {stat}`,
            serverLocale: locale
          },
          { stat: getStatTitle(SoccerStatKeysObj.pFoulsPerHour, locale) }
        ),
        prettyDefinitionModifier: translate({
          defaultMessage: "Adjusted down if played less than 60% of game",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId }): number => {
          if (isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};
          const playingTimeScale = computePlayingTimeScaleFactor(snapshot, playerId);
          return (stats[SoccerStatKeysObj.pFoulsPerHour] || 0) * playingTimeScale;
        }
      },
      goalieSaves: {
        prettyTitle: translate({ defaultMessage: "Sentinel", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pShotSavedPerc]: 1
        },
        stringFormatHint: "percent",
        prettyDefinition: translate(
          { defaultMessage: `{stat} > 80%,`, serverLocale: locale },
          { stat: getStatTitle(SoccerStatKeysObj.pShotSavedPerc, locale) }
        ),
        prettyDefinitionModifier: translate({ defaultMessage: "Minimum of two saves", serverLocale: locale }),
        compute: ({ snapshot, playerId }): number => {
          if (!isKeeper(snapshot, playerId)) {
            return 0;
          }
          const stats = snapshot.playerStats[playerId] || {};
          const saveRate = stats[SoccerStatKeysObj.pShotSavedPerc] || 0;
          const savesAdjustment = (stats[SoccerStatKeysObj.pShotsBlockedByGoalieSelf] || 0) * 0.000001; //tie breaker

          const moreThan2Shots = (snapshot.teamStats.opponentTeam[SoccerStatKeysObj.tShotsOnTarget] || 0) > 2;

          return saveRate >= 0.8 && moreThan2Shots ? saveRate + savesAdjustment : 0;
        }
      },
      goalieDistribution: {
        prettyTitle: translate({ defaultMessage: "Spark", serverLocale: locale }),
        mostRelevantStats: {
          [PlayerStatKeys.pSuccessRateWithBallPerc]: 1
        },
        stringFormatHint: "percent",
        prettyDefinition: translate({ defaultMessage: `Distribution Success > 90%`, serverLocale: locale }),
        prettyDefinitionModifier: translate({ defaultMessage: "Must play 20% of game", serverLocale: locale }),
        compute: ({ snapshot, playerId }): number => {
          const stats = snapshot.playerStats[playerId] || {};

          if (!isKeeper(snapshot, playerId)) {
            return 0;
          }

          if ((stats[SoccerStatKeysObj.pPlayingTimePerc] || 0) <= 0.2) {
            return 0;
          }

          const possSuccess = stats[SoccerStatKeysObj.pSuccessRateWithBallPerc] || 0;

          return possSuccess >= 0.9 ? possSuccess : 0;
        }
      },
      subImpact: {
        mostRelevantStats: {
          [PlayerStatKeys.pCompletedPassesPerHour]: 1
        },
        prettyTitle: translate({ defaultMessage: "12th Man", serverLocale: locale }),
        stringFormatHint: "number",
        prettyDefinition: translate({ defaultMessage: "Most Impactful Sub Index", serverLocale: locale }),
        prettyDefinitionModifier: translate({
          defaultMessage: "Calculated using multiple defensive and offensive stats",
          serverLocale: locale
        }),
        compute: ({ snapshot, playerId, game, mvps }): number => {
          if (isKeeper(snapshot, playerId) || game.starterIdToPosition?.[playerId]) {
            return 0;
          }

          // 20 is a constant to make the number larger so the values seem more impressive.
          return (mvps.mvp.find(d => d.playerId === playerId)?.value || 0) * 20;
        }
      }
    };
  }
);

function computeSoccerGameLeaderboards(p: {
  snapshot: SoccerStatSnapshot;
  game: PostStartedSoccerGame;
  soccerGameMVPs: SoccerGameMVPs;
  locale: string;
}): SoccerGameLeaderboards {
  const awardComputationOnlyStats = computeAwardComputationOnlyStats(p.snapshot);
  const leaderboards: SoccerGameLeaderboards = {};

  ObjectKeys(getSoccerGameLeaderboardMeta(p.locale)).forEach(type => {
    ObjectKeys(p.snapshot.playerStats).forEach(playerId => {
      leaderboards[type] = leaderboards[type] || [];
      (leaderboards[type] as any).push({
        playerId,
        value: getSoccerGameLeaderboardMeta(p.locale)[type].compute({
          snapshot: p.snapshot,
          playerId,
          game: p.game,
          mvps: p.soccerGameMVPs,
          awardComputationOnlyStats
        })
      });
    });

    leaderboards[type] = _.orderBy(leaderboards[type] || [], a => a.value, "desc").filter(a => a.value > 0);

    if ((leaderboards[type] || []).length === 0) {
      delete leaderboards[type];
    }
  });

  return leaderboards;
}

function computeSoccerGameMVPs(snapshot: SoccerStatSnapshot): SoccerGameMVPs {
  const awardComputationOnlyStats = computeAwardComputationOnlyStats(snapshot);

  function computePositionAveragedValue(playerId: string, value: number, weights: Record<SoccerPositionTypes, number>) {
    const percentThisPlayerPlayedAtPositions = snapshot.timeSpentAtPositionByPlayerId[playerId];

    let tempValue = 0;
    ObjectKeys(percentThisPlayerPlayedAtPositions).forEach(pos => {
      tempValue += value * percentThisPlayerPlayedAtPositions[pos] * weights[pos];
    });

    return tempValue;
  }

  const bestDefense = normalizeObjArray(
    Object.keys(snapshot.playerStats).map(playerId => {
      let baseValue = getSoccerGameMVPMeta("en-us").bestDefense.compute({
        snapshot,
        playerId,
        awardComputationOnlyStats,
        standardizedBestDefense: [],
        standardizedBestOffense: []
      });

      return {
        playerId,
        value: computePositionAveragedValue(playerId, baseValue, {
          goalkeeper: 1,
          defender: 1.2,
          midfielder: 1,
          forward: 1
        })
      };
    })
  );

  const bestOffense = normalizeObjArray(
    Object.keys(snapshot.playerStats).map(playerId => {
      return {
        playerId,
        value: getSoccerGameMVPMeta("en-us").bestOffense.compute({
          snapshot,
          playerId,
          awardComputationOnlyStats,
          standardizedBestDefense: [],
          standardizedBestOffense: []
        })
      };
    })
  );

  const mvpPositionWeights = {
    goalkeeper: 1.5,
    defender: 1.1,
    midfielder: 1.1,
    forward: 1
  };

  const weightedBestDefenseForMvp = bestDefense.map(v => {
    return {
      ...v,
      value: computePositionAveragedValue(v.playerId, v.value, mvpPositionWeights)
    };
  });

  const weightedBestOffenseForMvp = bestOffense.map(v => {
    return {
      ...v,
      value: computePositionAveragedValue(v.playerId, v.value, mvpPositionWeights)
    };
  });

  const standardizedBestOffenseForMVP = standardizeObjArray(weightedBestOffenseForMvp);
  const standardizedBestDefenseForMVP = standardizeObjArray(weightedBestDefenseForMvp);
  const mvp = normalizeObjArray(
    Object.keys(snapshot.playerStats).map(playerId => {
      return {
        playerId,
        value: getSoccerGameMVPMeta("en-us").mvp.compute({
          snapshot,
          playerId,
          awardComputationOnlyStats,
          standardizedBestOffense: standardizedBestOffenseForMVP,
          standardizedBestDefense: standardizedBestDefenseForMVP
        })
      };
    })
  );

  return {
    bestDefense: _.orderBy(bestDefense, a => a.value, "desc"),
    bestOffense: _.orderBy(bestOffense, a => a.value, "desc"),
    mvp: _.orderBy(mvp, a => a.value, "desc")
  };
}

function normalizeObjArray(
  objArr: {
    playerId: string;
    value: number;
  }[]
) {
  const min = Math.min(...objArr.map(v => v.value));
  const max = Math.max(...objArr.map(v => v.value));

  return objArr.map(v => ({ playerId: v.playerId, value: (v.value - min) / (max - min) })).filter(a => !isNaN(a.value));
}

function objArrayToObject(arr: { playerId: string; value: number }[]): Record<string, number> {
  return arr.reduce((acc, { playerId, value }) => {
    acc[playerId] = value;
    return acc;
  }, {} as Record<string, number>);
}

function standardizeObjArray(
  objArr: {
    playerId: string;
    value: number;
  }[]
) {
  const avg = mean(objArr.map(v => v.value));
  const sd = stdDev(
    objArr.map(v => v.value),
    avg
  );

  return objArr.map(({ value: origVal, playerId }) => {
    return {
      value: (origVal - avg) / sd,
      playerId
    };
  });
}

function stdDev(array: number[], arrayAvg: number) {
  return Math.sqrt(_.sum(_.map(array, i => Math.pow(i - arrayAvg, 2))) / array.length);
}

function mean(array: number[]) {
  if (array.length === 0) {
    return 0;
  }
  return _.sum(array) / array.length;
}

function isKeeper(snapshot: SoccerStatSnapshot, playerId: string) {
  return !!snapshot.bestFitPositionTypeToPlayerIds.goalkeeper[playerId];
}

function computePlayingTimeScaleFactor(snapshot: SoccerStatSnapshot, playerId: string) {
  const stats = snapshot.playerStats[playerId] || {};
  return Math.min((stats[SoccerStatKeysObj.pPlayingTimePerc] || 0) + 0.4, 1);
}

type AwardComputationOnlyStats = {
  adjustedRecoveriesPerHour: Record<PlayerId, number>;
  normalizedAdjustedRecoveriesPerHour: Record<PlayerId, number>;
};

function computeAwardComputationOnlyStats(snapshot: SoccerStatSnapshot): AwardComputationOnlyStats {
  //We can't just count all recoveries, since some recoveries are turnovers by the player followed immediately by a recovery. This is to account for the chaos "Shiner" effect
  const adjustedRecoveries = Object.keys(snapshot.playerStats).map(playerId => {
    const recoveries = snapshot.playerStats[playerId][SoccerStatKeysObj.pRecoveries] || 0;
    const qsRecoveries = snapshot.playerStats[playerId][SoccerStatKeysObj.pQuickSelfTurnoversAndRecoveries] || 0;

    return { playerId, value: recoveries - qsRecoveries * 0.75 };
  });

  const adjustedRecoveriesPerHour = adjustedRecoveries.map(({ playerId, value }) => {
    const time = (snapshot.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / (1000 * 60 * 60);

    return {
      playerId,
      value: value && time ? value / time : 0
    };
  });

  const normalizedAdjustedRecoveriesPerHour = normalizeObjArray(adjustedRecoveriesPerHour);

  return {
    adjustedRecoveriesPerHour: objArrayToObject(adjustedRecoveriesPerHour),
    normalizedAdjustedRecoveriesPerHour: objArrayToObject(normalizedAdjustedRecoveriesPerHour)
  };
}

export function getStatTitle(statKey: SoccerStatKeys | SoccerStatKeys[], locale: string) {
  if (statKey instanceof Array) {
    return statKey.map(key => getSoccerStatDefinitions(locale)[key].prettyName).join(" + ");
  }
  return getSoccerStatDefinitions(locale)[statKey].prettyName;
}

export function computePlayerLeaderboardRankings(p: {
  playerId: string;
  leaderboards: SoccerGameLeaderboards;
}): { placementNumber: number; award: SoccerGameLeaderboardTypes }[] {
  return ObjectKeys(p.leaderboards)
    .map(award => {
      const index = p.leaderboards[award]?.findIndex(v => v.playerId === p.playerId);
      return index !== -1
        ? {
            placementNumber: index,
            award
          }
        : null;
    })
    .filter((a): a is { placementNumber: number; award: SoccerGameLeaderboardTypes } => !!a);
}

// i18n certified - complete

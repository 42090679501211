import { SoccerStatKeysObj, SoccerStatModes, PositionNumStatKeys } from "@ollie-sports/models";
import _ from "lodash";

import { StatDefBase } from "./types";

export interface PositionNumStatDef extends StatDefBase {
  type: "positionNumber";
  tabCategory: "none";
  statKey: PositionNumStatKeys;
}

export const getPositionNumSoccerStatDefs = _.memoize((locale: string): Record<PositionNumStatKeys, PositionNumStatDef> => {
  return {
    [SoccerStatKeysObj.pnRecoveries]: {
      statKey: SoccerStatKeysObj.pnRecoveries,
      type: "positionNumber",
      tabCategory: "none",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },
    [SoccerStatKeysObj.pnTouches]: {
      statKey: SoccerStatKeysObj.pnTouches,
      type: "positionNumber",
      tabCategory: "none",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },
    [SoccerStatKeysObj.pnTurnovers]: {
      statKey: SoccerStatKeysObj.pnTurnovers,
      type: "positionNumber",
      tabCategory: "none",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    }
  };
});
// i18n certified - complete

import { SoccerGameEventType, SoccerGameEvent, SGE_shot, SGE_basicTouch, SGE_clearance } from "@ollie-sports/models";
import _ from "lodash";
import { isDevelopmentEnv } from "../internal-utils/misc";

type TypeEqualsType<T, S> = [T] extends [S] ? ([S] extends [T] ? true : false) : false;

/*** Shot Events **/
export type SoccerShotEventType =
  | SoccerGameEventType.shot
  | SoccerGameEventType.shotWithGoal
  | SoccerGameEventType.shotWithGoalManualEntry;

export const SoccerShotEventTypeObject: Record<SoccerShotEventType, true> = {
  [SoccerGameEventType.shot]: true,
  [SoccerGameEventType.shotWithGoal]: true,
  [SoccerGameEventType.shotWithGoalManualEntry]: true
};

export type SoccerShotEvent = Extract<SoccerGameEvent, { type: SoccerShotEventType }>;
//Note: We need these verbose definitions because Typescript can't re-export from ollie-models automatically
export const isShotEvent: (e: SoccerGameEvent) => e is SoccerShotEvent = (e: SoccerGameEvent): e is SoccerShotEvent =>
  !!(SoccerShotEventTypeObject as any)[e.type];

/*** Goal Events **/

export type SoccerGoalEventType =
  | SoccerGameEventType.shotWithGoal
  | SoccerGameEventType.ownTeamGoal
  | SoccerGameEventType.shotWithGoalManualEntry;

export const SoccerGoalEventTypeObject: Record<SoccerGoalEventType, true> = {
  [SoccerGameEventType.shotWithGoal]: true,
  [SoccerGameEventType.ownTeamGoal]: true,
  [SoccerGameEventType.shotWithGoalManualEntry]: true
};

export type SoccerGoalEvent = Extract<SoccerGameEvent, { type: SoccerGoalEventType }>;
export const isGoalEvent: (e: SoccerGameEvent) => e is SoccerGoalEvent = (e: SoccerGameEvent): e is SoccerGoalEvent =>
  !!(SoccerGoalEventTypeObject as any)[e.type];

/*** Start Clock Events **/
export type SoccerStartClockEventType =
  | SoccerGameEventType.startHalf
  | SoccerGameEventType.resumeGameWithDropBallOnStoppedClock
  | SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock
  | SoccerGameEventType.resumeGameOnStoppedClock;

export const SoccerStartClockEventTypeObject: Record<SoccerStartClockEventType, true> = {
  [SoccerGameEventType.startHalf]: true,
  [SoccerGameEventType.resumeGameWithDropBallOnStoppedClock]: true,
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: true,
  [SoccerGameEventType.resumeGameOnStoppedClock]: true
};
export type SoccerStartClockEvent = Extract<SoccerGameEvent, { type: SoccerStartClockEventType }>;
export const isSoccerStartClockEvent: (e: SoccerGameEvent) => e is SoccerStartClockEvent = (
  e: SoccerGameEvent
): e is SoccerStartClockEvent => !!(SoccerStartClockEventTypeObject as any)[e.type];
export const isSoccerStopClockEvent: (e: SoccerGameEvent) => e is SoccerStopClockEvent = (
  e: SoccerGameEvent
): e is SoccerStopClockEvent => !!(SoccerStopClockEventTypeObject as any)[e.type];

/*** Stop Clock Events **/
export type SoccerStopClockEventType = SoccerGameEventType.stopHalf | SoccerGameEventType.pauseGameAndStopClock;
export const SoccerStopClockEventTypeObject: Record<SoccerStopClockEventType, true> = {
  [SoccerGameEventType.stopHalf]: true,
  [SoccerGameEventType.pauseGameAndStopClock]: true
};

export type SoccerStopClockEvent = Extract<SoccerGameEvent, { type: SoccerStopClockEventType }>;
export const isStopClockEvent: (e: SoccerGameEvent) => e is SoccerStopClockEvent = (
  e: SoccerGameEvent
): e is SoccerStopClockEvent => !!(SoccerStopClockEventTypeObject as any)[e.type];

/*** Clock Events **/
export type SoccerClockEventType = SoccerStartClockEventType | SoccerStopClockEventType;
export const SoccerClockEventTypeObject: Record<SoccerClockEventType, true> = {
  ...SoccerStartClockEventTypeObject,
  ...SoccerStopClockEventTypeObject
};

export type SoccerClockEvent = Extract<SoccerGameEvent, { type: SoccerClockEventType }>;
export const isSoccerClockEvent: (e: SoccerGameEvent) => e is SoccerClockEvent = (e: SoccerGameEvent): e is SoccerClockEvent =>
  !!(SoccerClockEventTypeObject as any)[e.type];

/*** Pause Events **/
export type SoccerPauseEventType = SoccerGameEventType.pauseGameAndStopClock | SoccerGameEventType.startOfficialStoppage;
export const SoccerPauseEventTypeObject: Record<SoccerPauseEventType, true> = {
  [SoccerGameEventType.pauseGameAndStopClock]: true,
  [SoccerGameEventType.startOfficialStoppage]: true
};

export type SoccerPauseEvent = Extract<SoccerGameEvent, { type: SoccerPauseEventType }>;
export const isPauseEvent: (e: SoccerGameEvent) => e is SoccerPauseEvent = (e: SoccerGameEvent): e is SoccerPauseEvent =>
  !!(SoccerPauseEventTypeObject as any)[e.type];

/*** Resume Events **/
export type SoccerResumeEventType =
  | SoccerGameEventType.resumeGameWithDropBallOnStoppedClock
  | SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock
  | SoccerGameEventType.resumeGameOnStoppedClock
  | SoccerGameEventType.stopOfficialStoppageWithDropBall
  | SoccerGameEventType.stopOfficialStoppageWithStartingPlayer
  | SoccerGameEventType.resumeGameOnRunningClock;

export const SoccerResumeEventTypeObject: Record<SoccerResumeEventType, true> = {
  [SoccerGameEventType.resumeGameWithDropBallOnStoppedClock]: true,
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: true,
  [SoccerGameEventType.resumeGameOnStoppedClock]: true,
  [SoccerGameEventType.stopOfficialStoppageWithDropBall]: true,
  [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: true,
  [SoccerGameEventType.resumeGameOnRunningClock]: true
};

export type SoccerResumeEvent = Extract<SoccerGameEvent, { type: SoccerResumeEventType }>;
export const isSoccerResumeEvent: (e: SoccerGameEvent) => e is SoccerResumeEvent = (e: SoccerGameEvent): e is SoccerResumeEvent =>
  !!(SoccerResumeEventTypeObject as any)[e.type];

/**** Pause and Resume Events **/
export type SoccerPauseOrResumeEventType = SoccerPauseEventType | SoccerResumeEventType;
export const SoccerPauseOrResumeEventTypeObject: Record<SoccerPauseOrResumeEventType, true> = {
  ...SoccerPauseEventTypeObject,
  ...SoccerResumeEventTypeObject
};
export type SoccerPauseOrResumeEvent = Extract<SoccerGameEvent, { type: SoccerPauseOrResumeEventType }>;
export const isSoccerPauseOrResumeEvent: (e: SoccerGameEvent) => e is SoccerPauseOrResumeEvent = (
  e: SoccerGameEvent
): e is SoccerPauseOrResumeEvent => !!(SoccerPauseOrResumeEventTypeObject as any)[e.type];

/*** Touch Events **/
export type SoccerTouchEventType =
  | SoccerGameEventType.startHalf
  | SoccerGameEventType.basicTouch
  | SoccerGameEventType.tackle
  | SoccerGameEventType.header
  | SoccerGameEventType.cornerKickCross
  | SoccerGameEventType.cornerKickShort
  | SoccerGameEventType.freeKickCross
  | SoccerGameEventType.freeKickLong
  | SoccerGameEventType.freeKickPass
  | SoccerGameEventType.goalKickLong
  | SoccerGameEventType.goalKickPass
  | SoccerGameEventType.goalieThrow
  | SoccerGameEventType.header
  | SoccerGameEventType.ownTeamGoal
  | SoccerGameEventType.postGoalKickoff
  | SoccerGameEventType.stopOfficialStoppageWithStartingPlayer
  | SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock
  | SoccerGameEventType.tackle
  | SoccerGameEventType.throwIn;

export const SoccerTouchEventTypeObject: Record<SoccerTouchEventType, true> = {
  [SoccerGameEventType.startHalf]: true,
  [SoccerGameEventType.basicTouch]: true,
  [SoccerGameEventType.tackle]: true,
  [SoccerGameEventType.header]: true,
  [SoccerGameEventType.cornerKickCross]: true,
  [SoccerGameEventType.cornerKickShort]: true,
  [SoccerGameEventType.freeKickCross]: true,
  [SoccerGameEventType.freeKickLong]: true,
  [SoccerGameEventType.freeKickPass]: true,
  [SoccerGameEventType.goalKickLong]: true,
  [SoccerGameEventType.goalKickPass]: true,
  [SoccerGameEventType.goalieThrow]: true,
  [SoccerGameEventType.ownTeamGoal]: true,
  [SoccerGameEventType.postGoalKickoff]: true,
  [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: true,
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: true,
  [SoccerGameEventType.throwIn]: true
};

export type SoccerTouchEvent = Extract<SoccerGameEvent, { type: SoccerTouchEventType }>;
export const isTouchEvent: (e: SoccerGameEvent) => e is SoccerTouchEvent = (e: SoccerGameEvent): e is SoccerTouchEvent =>
  !!(SoccerTouchEventTypeObject as any)[e.type];

/** Passing Possession Event Set Events **/
export type SoccerPassingPossessionEventSetEventType =
  | SoccerGameEventType.basicTouch
  | SoccerGameEventType.goalKickLong
  | SoccerGameEventType.goalKickPass
  | SoccerGameEventType.freeKickCross
  | SoccerGameEventType.freeKickPass
  | SoccerGameEventType.throwIn
  | SoccerGameEventType.cornerKickShort
  | SoccerGameEventType.cornerKickCross
  | SoccerGameEventType.startHalf
  | SoccerGameEventType.postGoalKickoff
  | SoccerGameEventType.stopOfficialStoppageWithStartingPlayer
  | SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock
  | SoccerGameEventType.tackle
  | SoccerGameEventType.freeKickLong
  | SoccerGameEventType.freeKickCross
  | SoccerGameEventType.cornerKickCross
  | SoccerGameEventType.goalKickLong
  | SoccerGameEventType.goaliePunt
  | SoccerGameEventType.goalieThrow
  | SoccerGameEventType.header;

export const SoccerPassingPossessionEventSetTypeObject: Record<SoccerPassingPossessionEventSetEventType, true> = {
  [SoccerGameEventType.basicTouch]: true,
  [SoccerGameEventType.goalKickLong]: true,
  [SoccerGameEventType.goalKickPass]: true,
  [SoccerGameEventType.freeKickCross]: true,
  [SoccerGameEventType.freeKickPass]: true,
  [SoccerGameEventType.throwIn]: true,
  [SoccerGameEventType.cornerKickShort]: true,
  [SoccerGameEventType.cornerKickCross]: true,
  [SoccerGameEventType.startHalf]: true,
  [SoccerGameEventType.postGoalKickoff]: true,
  [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: true,
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: true,
  [SoccerGameEventType.tackle]: true,
  [SoccerGameEventType.freeKickLong]: true,
  [SoccerGameEventType.goaliePunt]: true,
  [SoccerGameEventType.goalieThrow]: true,
  [SoccerGameEventType.header]: true
};

export type SoccerPassingPossessionEventSetEvent = Extract<SoccerGameEvent, { type: SoccerPassingPossessionEventSetEventType }>;

export const isSoccerPassingPossessionEventSetEvent: (e: SoccerGameEvent) => e is SoccerPassingPossessionEventSetEvent = (
  e: SoccerGameEvent
): e is SoccerPassingPossessionEventSetEvent => !!(SoccerPassingPossessionEventSetTypeObject as any)[e.type];

if (isDevelopmentEnv()) {
  const assertAllPassChainEventsHavePlayerId: TypeEqualsType<
    Extract<SoccerPassingPossessionEventSetEvent, { playerId: string }>,
    SoccerPassingPossessionEventSetEvent
  > = true;
}

/** Non Passing Possession Event Set Events **/
export type SoccerNonPassingPossessionEventSetType =
  | SoccerGameEventType.outOfBounds
  | SoccerGameEventType.cross
  | SoccerGameEventType.shot
  | SoccerGameEventType.shotWithGoal;
export const SoccerNonPassingPossessionEventSetTypeObject: Record<SoccerNonPassingPossessionEventSetType, true> = {
  [SoccerGameEventType.outOfBounds]: true,
  [SoccerGameEventType.cross]: true,
  [SoccerGameEventType.shot]: true,
  [SoccerGameEventType.shotWithGoal]: true
};

export type SoccerNonPassingPossessionEventSetEvent = Extract<SoccerGameEvent, { type: SoccerNonPassingPossessionEventSetType }>;
export const isSoccerNonPassingPossessionEventSetEvent: (e: SoccerGameEvent) => e is SoccerNonPassingPossessionEventSetEvent = (
  e: SoccerGameEvent
): e is SoccerNonPassingPossessionEventSetEvent => !!(SoccerNonPassingPossessionEventSetTypeObject as any)[e.type];

/** Infraction Events **/
export type SoccerInfractionEventType =
  | SoccerGameEventType.foulInfraction
  | SoccerGameEventType.handballInfraction
  | SoccerGameEventType.offsideInfraction
  | SoccerGameEventType.redCardInfraction
  | SoccerGameEventType.yellowCardInfraction;

export const SoccerInfractionEventTypeObject: Record<SoccerInfractionEventType, true> = {
  [SoccerGameEventType.foulInfraction]: true,
  [SoccerGameEventType.handballInfraction]: true,
  [SoccerGameEventType.offsideInfraction]: true,
  [SoccerGameEventType.redCardInfraction]: true,
  [SoccerGameEventType.yellowCardInfraction]: true
};

export type SoccerInfractionEvent = Extract<SoccerGameEvent, { type: SoccerInfractionEventType }>;

if (isDevelopmentEnv()) {
  const assertAllSoccerInfractionEventsHavePlayerId: TypeEqualsType<
    Extract<SoccerInfractionEvent, { playerId: string }>,
    SoccerInfractionEvent
  > = true;
}

/** Sub Events **/
export type SoccerSubEventType = SoccerGameEventType.switchFormation | SoccerGameEventType.substitute;
export const SoccerSubEventTypeObject: Record<SoccerSubEventType, true> = {
  [SoccerGameEventType.switchFormation]: true,
  [SoccerGameEventType.substitute]: true
};

export type SoccerSubEvent = Extract<SoccerGameEvent, { type: SoccerSubEventType }>;
export const isSoccerSubEvent: (e: SoccerGameEvent) => e is SoccerSubEvent = (e: SoccerGameEvent): e is SoccerSubEvent =>
  !!(SoccerSubEventTypeObject as any)[e.type];

/** Card Events **/
export type SoccerCardEventType = SoccerGameEventType.redCardInfraction | SoccerGameEventType.yellowCardInfraction;
export const SoccerCardEventTypeObject: Record<SoccerCardEventType, true> = {
  [SoccerGameEventType.redCardInfraction]: true,
  [SoccerGameEventType.yellowCardInfraction]: true
};

export type SoccerCardEvent = Extract<SoccerGameEvent, { type: SoccerCardEventType }>;
export const isSoccerCardEvent: (e: SoccerGameEvent) => e is SoccerCardEvent = (e: SoccerGameEvent): e is SoccerCardEvent =>
  !!(SoccerCardEventTypeObject as any)[e.type];

/** Goal Kick Events **/
export type SoccerGoalKickEventType = SoccerGameEventType.goalKickLong | SoccerGameEventType.goalKickPass;
export const SoccerGoalKickEventTypeObject: Record<SoccerGoalKickEventType, true> = {
  [SoccerGameEventType.goalKickLong]: true,
  [SoccerGameEventType.goalKickPass]: true
};

export type SoccerGoalKickEvent = Extract<SoccerGameEvent, { type: SoccerGoalKickEventType }>;
export const isSoccerGoalKickEvent: (e: SoccerGameEvent) => e is SoccerGoalKickEvent = (
  e: SoccerGameEvent
): e is SoccerGoalKickEvent => !!(SoccerGoalKickEventTypeObject as any)[e.type];

/** Pending Pass Events **/
export type PendingPassEventType =
  | SoccerGameEventType.goalieThrow
  | SoccerGameEventType.goalKickPass
  | SoccerGameEventType.freeKickPass
  | SoccerGameEventType.throwIn
  | SoccerGameEventType.cornerKickShort;

export const PendingPassEventTypeObject: Record<PendingPassEventType, true> = {
  [SoccerGameEventType.goalieThrow]: true,
  [SoccerGameEventType.goalKickPass]: true,
  [SoccerGameEventType.freeKickPass]: true,
  [SoccerGameEventType.throwIn]: true,
  [SoccerGameEventType.cornerKickShort]: true
};

export type PendingPassEvent = Extract<SoccerGameEvent, { type: PendingPassEventType }>;
export const isPendingPassEvent: (e: SoccerGameEvent) => e is PendingPassEvent = (e: SoccerGameEvent): e is PendingPassEvent =>
  !!(PendingPassEventTypeObject as any)[e.type];

/** Basic Free Kick Events **/
export type SoccerBasicFreeKickEventType =
  | SoccerGameEventType.freeKickLong
  | SoccerGameEventType.freeKickCross
  | SoccerGameEventType.freeKickPass;
export const SoccerBasicFreeKickEventTypeObject: Record<SoccerBasicFreeKickEventType, true> = {
  [SoccerGameEventType.freeKickLong]: true,
  [SoccerGameEventType.freeKickCross]: true,
  [SoccerGameEventType.freeKickPass]: true
};

export type SoccerBasicFreeKickEvent = Extract<SoccerGameEvent, { type: SoccerBasicFreeKickEventType }>;
export const isSoccerBasicFreeKickEvent: (e: SoccerGameEvent) => e is SoccerBasicFreeKickEvent = (
  e: SoccerGameEvent
): e is SoccerBasicFreeKickEvent => !!(SoccerBasicFreeKickEventTypeObject as any)[e.type];

/** Free Kick Events **/
export const isSoccerFreeKickEvent: (e: SoccerGameEvent) => e is SoccerBasicFreeKickEvent | SGE_shot = (
  event: SoccerGameEvent
): event is SoccerBasicFreeKickEvent =>
  isSoccerBasicFreeKickEvent(event) || (event.type === SoccerGameEventType.shot && event.shotReason === "free-kick");

/** Cross Events **/
export type SoccerCrossEventType =
  | SoccerGameEventType.cross
  | SoccerGameEventType.cornerKickCross
  | SoccerGameEventType.freeKickCross;
export const SoccerCrossEventTypeObject: Record<SoccerCrossEventType, true> = {
  [SoccerGameEventType.cross]: true,
  [SoccerGameEventType.cornerKickCross]: true,
  [SoccerGameEventType.freeKickCross]: true
};

export type SoccerCrossEvent = Extract<SoccerGameEvent, { type: SoccerCrossEventType }>;
export const isSoccerCrossEvent: (e: SoccerGameEvent) => e is SoccerCrossEvent = (e: SoccerGameEvent): e is SoccerCrossEvent =>
  !!(SoccerCrossEventTypeObject as any)[e.type];

/** Corner Kick Events **/
export type SoccerCornerKickEventType = SoccerGameEventType.cornerKickCross | SoccerGameEventType.cornerKickShort;
export const SoccerCornerKickEventTypeObject: Record<SoccerCornerKickEventType, true> = {
  [SoccerGameEventType.cornerKickCross]: true,
  [SoccerGameEventType.cornerKickShort]: true
};

export type SoccerCornerKickEvent = Extract<SoccerGameEvent, { type: SoccerCornerKickEventType }>;
export const isSoccerCornerKickEvent: (e: SoccerGameEvent) => e is SoccerCornerKickEvent = (
  e: SoccerGameEvent
): e is SoccerCornerKickEvent => !!(SoccerCornerKickEventTypeObject as any)[e.type];

/*** Timeline Events (For Live Game Mode) */
export type SoccerLiveGameTimelineEventType =
  | SoccerGameEventType.yellowCardInfraction
  | SoccerGameEventType.redCardInfraction
  | SoccerGameEventType.cornerKickShort
  | SoccerGameEventType.cornerKickCross
  | SoccerGameEventType.substitute
  | SoccerGameEventType.startHalf
  | SoccerGameEventType.stopHalf
  | SoccerGameEventType.pauseGameAndStopClock
  | SoccerGameEventType.startOfficialStoppage
  | SoccerGameEventType.resumeGameWithDropBallOnStoppedClock
  | SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock
  | SoccerGameEventType.resumeGameOnStoppedClock
  | SoccerGameEventType.stopOfficialStoppageWithDropBall
  | SoccerGameEventType.stopOfficialStoppageWithStartingPlayer
  | SoccerGameEventType.resumeGameOnRunningClock
  | SoccerGameEventType.shot
  | SoccerGameEventType.shotWithGoal
  | SoccerGameEventType.shotShootoutPK
  | SoccerGameEventType.ownTeamGoal
  | SoccerGameEventType.foulInfraction
  | SoccerGameEventType.handballInfraction
  | SoccerGameEventType.offsideInfraction
  | SoccerGameEventType.clearance
  | SoccerGameEventType.basicTouch
  | SoccerGameEventType.forfeitInfractionPK
  | SoccerGameEventType.freeKickCross
  | SoccerGameEventType.freeKickLong
  | SoccerGameEventType.freeKickPass
  | SoccerGameEventType.cross
  | SoccerGameEventType.postGoalKickoff
  | SoccerGameEventType.shotWithGoalManualEntry;

export const SoccerLiveGameTimelineEventObject: Record<SoccerLiveGameTimelineEventType, true> = {
  [SoccerGameEventType.yellowCardInfraction]: true,
  [SoccerGameEventType.redCardInfraction]: true,
  [SoccerGameEventType.cornerKickShort]: true,
  [SoccerGameEventType.cornerKickCross]: true,
  [SoccerGameEventType.substitute]: true,
  [SoccerGameEventType.startHalf]: true,
  [SoccerGameEventType.stopHalf]: true,
  [SoccerGameEventType.pauseGameAndStopClock]: true,
  [SoccerGameEventType.startOfficialStoppage]: true,
  [SoccerGameEventType.resumeGameWithDropBallOnStoppedClock]: true,
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: true,
  [SoccerGameEventType.resumeGameOnStoppedClock]: true,
  [SoccerGameEventType.stopOfficialStoppageWithDropBall]: true,
  [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: true,
  [SoccerGameEventType.resumeGameOnRunningClock]: true,
  [SoccerGameEventType.shot]: true,
  [SoccerGameEventType.shotWithGoal]: true,
  [SoccerGameEventType.shotShootoutPK]: true,
  [SoccerGameEventType.ownTeamGoal]: true,
  [SoccerGameEventType.foulInfraction]: true,
  [SoccerGameEventType.handballInfraction]: true,
  [SoccerGameEventType.offsideInfraction]: true,
  [SoccerGameEventType.clearance]: true,
  [SoccerGameEventType.basicTouch]: true,
  [SoccerGameEventType.forfeitInfractionPK]: true,
  [SoccerGameEventType.freeKickCross]: true,
  [SoccerGameEventType.freeKickLong]: true,
  [SoccerGameEventType.freeKickPass]: true,
  [SoccerGameEventType.cross]: true,
  [SoccerGameEventType.postGoalKickoff]: true,
  [SoccerGameEventType.shotWithGoalManualEntry]: true
};

export type SoccerLiveGameTimelineEvent = Extract<SoccerGameEvent, { type: SoccerLiveGameTimelineEventType }>;
export const isLiveGameTimelineEvent: (e: SoccerGameEvent) => e is SoccerLiveGameTimelineEvent = (
  e: SoccerGameEvent
): e is SoccerLiveGameTimelineEvent => !!(SoccerLiveGameTimelineEventObject as any)[e.type];
export const isLiveGameTimelineEventType: (type: SoccerGameEventType) => type is SoccerLiveGameTimelineEventType = (
  type: SoccerGameEventType
): type is SoccerLiveGameTimelineEventType => !!(SoccerLiveGameTimelineEventObject as any)[type];

if (isDevelopmentEnv()) {
  const assertAllTimelineEventsHaveTimelineProperty: TypeEqualsType<
    Extract<SoccerGameEvent, { includeInTimeline: true }>,
    Exclude<SoccerLiveGameTimelineEvent, SGE_basicTouch | SGE_clearance>
  > = true;
}

// i18n certified - complete

import {
  SoccerStatKeysObj,
  SoccerStatModes,
  PlayerStatKeys,
  SoccerGameEventType,
  SoccerGameEvent,
  SGE_shotWithGoal,
  SoccerIds,
  TeamStatKeys,
  GameStatKeys,
  ShotResultType
} from "@ollie-sports/models";
import { StatComputation } from "./types";
import _ from "lodash";
import { expensiveComputeSoccerPlayerIdToPlayingTimeMS } from "../SoccerGameClock";
import { isTurnoverWithQuickSelfRecovery } from "../SoccerPossession";
import {
  isTouchEvent,
  isShotEvent,
  isGoalEvent,
  isSoccerGoalKickEvent,
  isSoccerCrossEvent,
  isSoccerCornerKickEvent,
  SoccerGoalEvent
} from "../SoccerEventCategories";
import { isOpponentPlayer, getPlayerTeamId, isOpponentTeamId, getKeyGoals } from "../SoccerFns";
import { isTurnover, isRecovery } from "./shared-computation-fns";
import { computeSoccerGameScore } from "../SoccerGameMiscLogic";

const ONE_HOUR = 1000 * 60 * 60;

export const PlayerSoccerStatComputations: Record<PlayerStatKeys, StatComputation> = {
  [SoccerStatKeysObj.pPlayingTimeMS]: {
    statKey: SoccerStatKeysObj.pPlayingTimeMS,
    statType: "player",
    compute: ({ memoryDB, game, playerId, allEvents, rosterPrettyPlayers }) => {
      const nowMS =
        "officialEndOfGameMS" in game ? game.officialEndOfGameMS : allEvents.slice(-1).pop()?.createdAtMS || Date.now();
      const idToMS = expensiveComputeSoccerPlayerIdToPlayingTimeMS({
        memoryDB,
        players: rosterPrettyPlayers,
        nowMS,
        game
      });

      return idToMS[playerId];
    }
  },
  [SoccerStatKeysObj.pPlayingTimePerc]: {
    statKey: SoccerStatKeysObj.pPlayingTimePerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS];
      const bottom = stats.gameStats[SoccerStatKeysObj.gPlayingTimeMS];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      return playerTotalsAndAverages.avg[PlayerStatKeys.pPlayingTimePerc] || null;
    }
  },
  [SoccerStatKeysObj.pCompletedPasses]: {
    statKey: SoccerStatKeysObj.pCompletedPasses,
    statType: "player",
    compute: ({ finalPossessionSets, playerId }) => {
      return finalPossessionSets
        .filter(set => set.type === "own-team")
        .reduce((acc, possSet) => {
          const pids = possSet.passChainPlayerIds;
          let addlCount = 0;

          //If the next person in the passing chain is not the player, then increment their completed pass count
          for (let i = 0; i < pids.length; i++) {
            if (pids[i] === playerId && pids[i + 1] && pids[i + 1] !== playerId) {
              addlCount++;
            }
          }

          return acc + addlCount;
        }, 0);
    }
  },
  [SoccerStatKeysObj.pTouches]: {
    statKey: SoccerStatKeysObj.pTouches,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isTouchEvent(event) && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pShots]: {
    statKey: SoccerStatKeysObj.pShots,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isShotEvent(event) && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pShotsOnTarget]: {
    statKey: SoccerStatKeysObj.pShotsOnTarget,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isShotEvent(event) && event.shotIsOnTarget && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pGoals]: {
    statKey: SoccerStatKeysObj.pGoals,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isGoalEvent(event) && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pAssists]: {
    statKey: SoccerStatKeysObj.pAssists,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isGoalEvent(event) && "assistingPlayerId" in event && event.assistingPlayerId === playerId);
    }
  },
  [SoccerStatKeysObj.pDribbles]: {
    statKey: SoccerStatKeysObj.pDribbles,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.dribble && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pCrosses]: {
    statKey: SoccerStatKeysObj.pCrosses,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isSoccerCrossEvent(event) && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pSuccessfulCrosses]: {
    statKey: SoccerStatKeysObj.pSuccessfulCrosses,
    statType: "player",
    reduce: ({ acc, playerId, event, currentPossessionSetDetails }) => {
      if (isSoccerCrossEvent(event) && event.playerId === playerId) {
        const { eventIndex, info } = currentPossessionSetDetails;
        //Was successful if the cross's possession included a shot
        if (!!_.findLast(info.events.slice(eventIndex + 1), evt => isShotEvent(evt))) {
          return (acc ?? 0) + 1;
        }
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.pSuccessfulCrossesPerc]: {
    statKey: SoccerStatKeysObj.pSuccessfulCrossesPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const bottom = stats.playerStats[playerId][SoccerStatKeysObj.pCrosses];
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pSuccessfulCrosses];

      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pSuccessfulCrosses];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pCrosses];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pTurnovers]: {
    statKey: SoccerStatKeysObj.pTurnovers,
    statType: "player",
    reduce: ({ acc, ...rest }) => {
      return (acc ?? 0) + Number(isTurnover(rest));
    }
  },
  [SoccerStatKeysObj.pRecoveries]: {
    statKey: SoccerStatKeysObj.pRecoveries,
    statType: "player",
    reduce: ({ acc, ...rest }) => {
      return (acc ?? 0) + Number(isRecovery(rest));
    }
  },
  [SoccerStatKeysObj.pFouls]: {
    statKey: SoccerStatKeysObj.pFouls,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.foulInfraction && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pThrowIns]: {
    statKey: SoccerStatKeysObj.pThrowIns,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.throwIn && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pThrowInInfractions]: {
    statKey: SoccerStatKeysObj.pThrowInInfractions,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.throwInInfraction && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pSuccessfulThrowIns]: {
    statKey: SoccerStatKeysObj.pSuccessfulThrowIns,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.throwIn && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pGoalKicks]: {
    statKey: SoccerStatKeysObj.pGoalKicks,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isSoccerGoalKickEvent(event) && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pSuccessfulGoalKicks]: {
    statKey: SoccerStatKeysObj.pSuccessfulGoalKicks,
    statType: "player",
    reduce: ({ acc, playerId, event, currentPossessionSetDetails }) => {
      if (isSoccerGoalKickEvent(event) && event.playerId === playerId) {
        //It was a successful goal kick if it wasn't the the last event in the possession set.
        if (currentPossessionSetDetails.info.events.slice(-1).pop()?.id !== event.id) {
          return (acc ?? 0) + 1;
        }
      }
      return acc ?? 0;
    }
  },
  [SoccerStatKeysObj.pSuccessfulGoalKickPerc]: {
    statKey: SoccerStatKeysObj.pSuccessfulGoalKickPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const bottom = stats.playerStats[playerId][SoccerStatKeysObj.pGoalKicks];
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pSuccessfulGoalKicks];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pSuccessfulGoalKicks];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pGoalKicks];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pShotsCaught]: {
    statKey: SoccerStatKeysObj.pShotsCaught,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      if (event.type === SoccerGameEventType.shot && event.defendingGoaliePlayerId === playerId) {
        return (acc ?? 0) + Number(event.shotResultType === ShotResultType.goalieShotCatch);
      }

      return acc;
    }
  },
  [SoccerStatKeysObj.pShotsBlockedByGoalieSelf]: {
    statKey: SoccerStatKeysObj.pShotsBlockedByGoalieSelf,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      if (event.type === SoccerGameEventType.shot && event.defendingGoaliePlayerId === playerId) {
        return (acc ?? 0) + Number(event.shotResultType === ShotResultType.goalieShotBlock);
      }

      return acc;
    }
  },
  [SoccerStatKeysObj.pShotsSaved]: {
    statKey: SoccerStatKeysObj.pShotsSaved,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      if (event.type === SoccerGameEventType.shot && event.defendingGoaliePlayerId === playerId) {
        return (
          (acc ?? 0) +
          Number(
            event.shotResultType === ShotResultType.goalieShotBlock || event.shotResultType === ShotResultType.goalieShotCatch
          )
        );
      }

      return acc;
    }
  },
  [SoccerStatKeysObj.pShotsSavedPerHour]: {
    statKey: SoccerStatKeysObj.pShotsSavedPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId, timeSpentAtPositionByPlayerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pShotsSaved];
      const bottom = (timeSpentAtPositionByPlayerId[playerId]?.goalkeeper ?? 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pShotSavedPerc]: {
    statKey: SoccerStatKeysObj.pShotSavedPerc,
    statType: "player",
    compute: ({ playerId, memoryDB }) => {
      const allShots = memoryDB.presets
        .shotEvents()
        .filter(evt => evt.defendingGoaliePlayerId === playerId && evt.shotIsOnTarget);
      const allGoals = allShots.filter(
        evt =>
          evt.type === SoccerGameEventType.shotWithGoal ||
          (evt.type === SoccerGameEventType.shotWithGoalManualEntry && !evt.idOfPlayerWhoShotOwnGoal) // Count manual goals as goals, but not if it is an own goal.
      );

      if (!allShots.length) {
        return null;
      }

      return 1 - allGoals.length / allShots.length;
    },
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pShotsSaved];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pShotsDefendedAsGK];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pShotsDefendedAsGK]: {
    statKey: SoccerStatKeysObj.pShotsDefendedAsGK,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      if (isShotEvent(event) && event.shotIsOnTarget && event.defendingGoaliePlayerId === playerId) {
        return (acc ?? 0) + 1;
      }

      return acc;
    }
  },
  [SoccerStatKeysObj.pKeyPasses]: {
    statKey: SoccerStatKeysObj.pKeyPasses,
    statType: "player",
    compute: ({ playerId, memoryDB, finalEventIdToPossessionSetDetails }) => {
      return memoryDB.presets
        .shotEvents()
        .filter(evt => !isOpponentPlayer(evt.playerId))
        .filter(evt => {
          if (evt.type === SoccerGameEventType.shotWithGoal) {
            return false;
          } else {
            const assistingPlayerId = _.findLast(
              finalEventIdToPossessionSetDetails[evt.id].info.passChainPlayerIds,
              pid => pid !== evt.playerId
            );
            return assistingPlayerId === playerId;
          }
        }).length;
    }
  },
  [SoccerStatKeysObj.pKeyPassesPerHour]: {
    statKey: SoccerStatKeysObj.pKeyPassesPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pKeyPasses];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pCompletedPassesPerHour]: {
    statKey: SoccerStatKeysObj.pCompletedPassesPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pCompletedPasses];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pSuccessRateWithBallPerc]: {
    statKey: SoccerStatKeysObj.pSuccessRateWithBallPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const theseStats = stats.playerStats[playerId];
      const touches = theseStats[PlayerStatKeys.pTouches] || 0;
      const bottom = touches;
      const top = touches - (theseStats[PlayerStatKeys.pTurnovers] || 0);

      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const touches = playerTotalsAndAverages.total[SoccerStatKeysObj.pTouches] || 0;
      const turnovers = playerTotalsAndAverages.total[SoccerStatKeysObj.pTurnovers] || 0;
      const bottom = touches;
      const top = touches - turnovers;
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pPossessionParticipationPerc]: {
    statKey: SoccerStatKeysObj.pPossessionParticipationPerc,
    statType: "player",
    computeStageTwo: ({ finalPossessionSets, stats, playerId }) => {
      const ownTeamPossessions = finalPossessionSets.filter(set => set.type === "own-team" && set.passChainPlayerIds.length > 1);
      const myPossessions = ownTeamPossessions.filter(poss => poss.passChainPlayerIds.includes(playerId));
      const top = myPossessions.length;
      const bottom = ownTeamPossessions.length;
      const totalGamePossessionParticPerc = top && bottom ? top / bottom : 0;

      const percentPlayed = stats.playerStats[playerId][PlayerStatKeys.pPlayingTimePerc] || 0;

      if (percentPlayed === 0) {
        return null;
      }

      return Math.min(totalGamePossessionParticPerc * (1 / percentPlayed), 1);
    },
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      return playerTotalsAndAverages.avg[SoccerStatKeysObj.pPossessionParticipationPerc] || null;
    }
  },
  [SoccerStatKeysObj.pTouchesPerHour]: {
    statKey: SoccerStatKeysObj.pTouchesPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pTouches];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pShotsPerHour]: {
    statKey: SoccerStatKeysObj.pShotsPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pShots];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pShotsOnTargetPerHour]: {
    statKey: SoccerStatKeysObj.pShotsOnTargetPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pShotsOnTarget];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pShotsOnTargetPerc]: {
    statKey: SoccerStatKeysObj.pShotsOnTargetPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pShotsOnTarget];
      const bottom = stats.playerStats[playerId][SoccerStatKeysObj.pShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pShotsOnTarget];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pShotFinishingPerc]: {
    statKey: SoccerStatKeysObj.pShotFinishingPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pGoals];
      const bottom = stats.playerStats[playerId][SoccerStatKeysObj.pShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pGoals];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pShots];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pGoalsPerHour]: {
    statKey: SoccerStatKeysObj.pGoalsPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pGoals];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pAssistsPerHour]: {
    statKey: SoccerStatKeysObj.pAssistsPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pAssists];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pCrossesPerHour]: {
    statKey: SoccerStatKeysObj.pCrossesPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pCrosses];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pTurnoversPerHour]: {
    statKey: SoccerStatKeysObj.pTurnoversPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pTurnovers];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pTurnoverPerc]: {
    statKey: SoccerStatKeysObj.pTurnoverPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const theseStats = stats.playerStats[playerId];
      const top = theseStats[PlayerStatKeys.pTurnovers] || 0;
      const bottom = theseStats[PlayerStatKeys.pTouches] || 0;

      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pTurnovers];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pTouches];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pRecoveriesPerHour]: {
    statKey: SoccerStatKeysObj.pRecoveriesPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pRecoveries];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pFoulsPerHour]: {
    statKey: SoccerStatKeysObj.pFoulsPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pFouls];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pYellowCards]: {
    statKey: SoccerStatKeysObj.pYellowCards,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.yellowCardInfraction && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pRedCards]: {
    statKey: SoccerStatKeysObj.pRedCards,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.redCardInfraction && event.playerId === playerId);
    }
  },

  [SoccerStatKeysObj.pSuccessfulThrowInsPerc]: {
    statKey: SoccerStatKeysObj.pSuccessfulThrowInsPerc,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pSuccessfulThrowIns];
      const bottom = stats.playerStats[playerId][SoccerStatKeysObj.pThrowIns];
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0,
    computeSeasonAverage: ({ playerTotalsAndAverages }) => {
      const top = playerTotalsAndAverages.total[SoccerStatKeysObj.pSuccessfulThrowIns];
      const bottom = playerTotalsAndAverages.total[SoccerStatKeysObj.pThrowIns];
      return typeof top === "number" && bottom ? top / bottom : null;
    }
  },
  [SoccerStatKeysObj.pPenaltyKicksScored]: {
    statKey: SoccerStatKeysObj.pPenaltyKicksScored,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (
        (acc ?? 0) +
        Number(
          event.type === SoccerGameEventType.shotWithGoal && event.playerId === playerId && event.shotReason === "infraction-pk"
        )
      );
    }
  },
  [SoccerStatKeysObj.pPenaltyKicksAttempted]: {
    statKey: SoccerStatKeysObj.pPenaltyKicksAttempted,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (
        (acc ?? 0) +
        Number(event.type === SoccerGameEventType.shot && event.playerId === playerId && event.shotReason === "infraction-pk")
      );
    }
  },

  [SoccerStatKeysObj.pCornerKicks]: {
    statKey: SoccerStatKeysObj.pCornerKicks,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(isSoccerCornerKickEvent(event) && event.playerId === playerId);
    }
  },
  [SoccerStatKeysObj.pFieldPlayerPlayingTimeMS]: {
    statKey: SoccerStatKeysObj.pFieldPlayerPlayingTimeMS,
    statType: "player",
    computeStageTwo: ({ playerId, timeSpentAtPositionByPlayerId }) => {
      return (
        timeSpentAtPositionByPlayerId?.[playerId]?.defender ??
        0 + timeSpentAtPositionByPlayerId?.[playerId]?.midfielder ??
        0 + timeSpentAtPositionByPlayerId?.[playerId]?.forward ??
        0
      );
    }
  },
  [SoccerStatKeysObj.pQuickSelfTurnoversAndRecoveries]: {
    statKey: SoccerStatKeysObj.pQuickSelfTurnoversAndRecoveries,
    statType: "player",
    reduce: ({ acc, allEvents, index, game, playerId }) => {
      const isOne = isTurnoverWithQuickSelfRecovery({
        playerId,
        allEvents,
        eventIndex: index,
        game: game
      });

      return (acc ?? 0) + Number(isOne);
    }
  },
  [SoccerStatKeysObj.pGameWinningGoals]: {
    statKey: SoccerStatKeysObj.pGameWinningGoals,
    statType: "player",
    computeStageTwo: ({ memoryDB, playerId, stats }) => {
      if (typeof stats.playerStats[playerId]?.[SoccerStatKeysObj.pGoals] === "number") {
        const { gameWinningGoal } = getKeyGoals(memoryDB.presets.goalEvents());

        if (gameWinningGoal?.type !== SoccerGameEventType.ownTeamGoal && gameWinningGoal?.playerId === playerId) {
          return 1;
        }
      }
      return 0;
    }
  },
  [SoccerStatKeysObj.pGoaliePlayingTimeMS]: {
    statKey: SoccerStatKeysObj.pGoaliePlayingTimeMS,
    statType: "player",
    computeStageTwo: ({ stats, playerId, timeSpentAtPositionByPlayerId }) => {
      return timeSpentAtPositionByPlayerId?.[playerId]?.goalkeeper ?? null;
    }
  },
  [SoccerStatKeysObj.pGoalieShutouts]: {
    statKey: SoccerStatKeysObj.pGoalieShutouts,
    statType: "player",
    computeStageTwo: ({ stats, playerId, timeSpentAtPositionByPlayerId }) => {
      const goalieTime = timeSpentAtPositionByPlayerId?.[playerId]?.goalkeeper;
      if (typeof goalieTime === "number") {
        if (
          goalieTime &&
          goalieTime / (stats.gameStats[GameStatKeys.gPlayingTimeMS] || Infinity) >= 0.75 &&
          !stats.teamStats.opponentTeam[TeamStatKeys.tGoals]
        ) {
          return 1;
        } else {
          return 0;
        }
      }

      return null;
    }
  },
  [SoccerStatKeysObj.pGoalsConceded]: {
    statKey: SoccerStatKeysObj.pGoalsConceded,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      if (
        (event.type === SoccerGameEventType.shotWithGoal ||
          (event.type === SoccerGameEventType.shotWithGoalManualEntry && !event.idOfPlayerWhoShotOwnGoal)) &&
        event.defendingGoaliePlayerId === playerId
      ) {
        return (acc ?? 0) + 1;
      }

      return acc;
    }
  },
  [SoccerStatKeysObj.pGoalsConcededPerHour]: {
    statKey: SoccerStatKeysObj.pGoalsConcededPerHour,
    statType: "player",
    computeStageTwo: ({ stats, playerId }) => {
      const top = stats.playerStats[playerId][SoccerStatKeysObj.pGoalsConceded];
      const bottom = (stats.playerStats[playerId][SoccerStatKeysObj.pPlayingTimeMS] || 0) / ONE_HOUR;
      return typeof top === "number" && bottom ? top / bottom : null;
    },
    computePriority: 0
  },
  [SoccerStatKeysObj.pOffsides]: {
    statKey: SoccerStatKeysObj.pOffsides,
    statType: "player",
    reduce: ({ acc, playerId, event }) => {
      return (acc ?? 0) + Number(event.type === SoccerGameEventType.offsideInfraction && event.playerId === playerId);
    }
  }
};

// i18n certified - complete

import _ from "lodash";
import {
  SoccerGameEvent,
  EndedSoccerGame,
  StartedSoccerGame,
  SoccerStatSnapshot,
  PlayerStatKeys,
  SoccerIds,
  SoccerPositionTypes,
  PositionNumStatKeys,
  PrettyPlayer,
  SoccerGameEventType,
  SoccerFormationKeys,
  SoccerFormationsByKey,
  SoccerStatModes,
  SoccerStatKeysObj,
  SoccerPositionNumber,
  PostStartedSoccerGame,
  SoccerStatKeys,
  pickStatRelevantGameFields,
  GameStatKeys,
  TeamStatKeys
} from "@ollie-sports/models";
import { getSoccerStatDefinitions, StatDef } from "./SoccerStatDefinitions";
import { SoccerStatComputations, BaseReduceStatComputationObj, StatFieldsWithNull, StatFields } from "./SoccerStatComputations";
import { createSoccerEventMemoryDB, SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { getBestFitPositionTypeInfo, expensiveComputeSoccerPlayerIdToPlayingTimeMS } from "./SoccerGameClock";
import { isStopClockEvent, isSoccerSubEvent, isSoccerCardEvent } from "./SoccerEventCategories";
import {
  computeAllEventSetInfo,
  SoccerPossessionEventSetInfo,
  getEventPossessionState,
  SoccerPossessionState
} from "./SoccerPossession";
import { ObjectKeys, ObjectValues } from "../utils";
import { computeSoccerGamePlayerRoster, ConvenienceSoccerGamePlayer } from "./SoccerGamePlayerRoster";
import { soccerLogicVersion } from "../soccer-logic-version.json";
import stableStringify from "json-stable-stringify";
import md5 from "md5";
import { getTeamStatType } from "./SoccerFns";
import { common__generateTeamIdWithSquad } from "../api/common.api";

export type FullSoccerStatSnapshotBundle = {
  type: "full";
  all: SoccerStatSnapshot;
  firstHalf: SoccerStatSnapshot;
  secondHalfPlus: SoccerStatSnapshot;
};
export type PartialSoccerStatSnapshotBundle = { type: "partial"; all: SoccerStatSnapshot };

export type SoccerStatSnapshotBundle = FullSoccerStatSnapshotBundle | PartialSoccerStatSnapshotBundle;

type BaseSnapshot = Omit<
  SoccerStatSnapshot,
  | "id"
  | "bestFitPositionTypeToPlayerIds"
  | "bestFitPlayerIdToPositionType"
  | "timeSpentAtPositionByPlayerId"
  | "gameStats"
  | "playerStats"
  | "normalizedPlayerStats"
  | "teamStats"
  | "positionAvgStats"
  | "normalizedPositionStats"
  | "positionNumberStats"
>;

export function createSoccerStatSnapshotBundle(p: {
  events: SoccerGameEvent[];
  game: StartedSoccerGame | EndedSoccerGame;
  shouldStatBeComputed?: (statKey: SoccerStatKeys) => boolean;
  locale: string;
}): SoccerStatSnapshotBundle {
  const start = Date.now();
  let startSecondHalfEventIndex = -1;
  for (let i = 1; i < p.events.length; i++) {
    if (p.events[i]?.type === SoccerGameEventType.startHalf) {
      startSecondHalfEventIndex = i;
      break;
    }
  }

  const beforeSecondHalfStartEvents = p.events.slice(0, startSecondHalfEventIndex);
  const playerAtStartOfSecondHalf =
    startSecondHalfEventIndex >= 0
      ? createPositionMappings({ memoryDB: createSoccerEventMemoryDB(beforeSecondHalfStartEvents), game: p.game })
          .currentPlayerIdToFieldPositionNum
      : {};

  const overallSnapshotInfo = getOverallSnapshotInfo({ ...p, nowMS: p.events.slice(-1).pop()?.createdAtMS || Infinity });

  const bundle: SoccerStatSnapshotBundle =
    p.game.gameStage == "ended" || startSecondHalfEventIndex >= 0
      ? {
          type: "full",
          all: createSoccerStatSnapshot({
            game: p.game,
            events: p.events,
            snapshotType: "all",
            overallSnapshotInfo,
            locale: p.locale
          }),
          firstHalf: createSoccerStatSnapshot({
            game: p.game,
            events: beforeSecondHalfStartEvents,
            snapshotType: "first-half",
            overallSnapshotInfo,
            locale: p.locale
          }),
          secondHalfPlus: createSoccerStatSnapshot({
            game: {
              ...p.game,
              starterIdToPosition: playerAtStartOfSecondHalf as any
            },
            events: p.events.slice(startSecondHalfEventIndex),
            snapshotType: "second-half-plus",
            overallSnapshotInfo,
            locale: p.locale
          })
        }
      : {
          type: "partial",
          all: createSoccerStatSnapshot({
            game: p.game,
            events: p.events,
            snapshotType: "all",
            locale: p.locale,
            ...overallSnapshotInfo
          })
        };

  //TODO: We should remove this once we're sure that stats computation isn't getting too slow
  console.info(
    `Took ${Date.now() - start} ms to compute ${bundle.type === "full" ? 3 : 1} stat snapshot(s) for ${p.events.length} events`
  );

  return bundle;
}

export function createSoccerStatSnapshot(p: {
  events: SoccerGameEvent[];
  game: StartedSoccerGame | EndedSoccerGame;
  snapshotType: SoccerStatSnapshot["snapshotType"];
  shouldStatBeComputed?: (statKey: SoccerStatKeys) => boolean;
  overallSnapshotInfo?: {
    bestFitPlayerIdToPositionType: Record<string, SoccerPositionTypes>;
    bestFitPositionTypeToPlayerIds: Record<SoccerPositionTypes, Record<string, true>>;
    playerIdsWithPlayingTime: string[];
  };
  locale: string;
}): SoccerStatSnapshot {
  const mostlyEmptyPlayersArr = createMostlyEmptyPlayersArray(p.game);
  const relevantEvents = p.events.slice(); //Filter here if we ever decide some events are irrelevant to stats...

  const defs = _.pickBy(getSoccerStatDefinitions(p.locale), def => {
    let isValid = def.statModes.includes(p.game.statMode);

    if (isValid && p.shouldStatBeComputed) {
      isValid = p.shouldStatBeComputed(def.statKey);
    }

    return isValid;
  });
  const computations = _.pickBy(SoccerStatComputations, comp => !!defs[comp.statKey]);

  const lastEvent = relevantEvents[relevantEvents.length - 1];

  const nowMS = lastEvent && isStopClockEvent(lastEvent) ? lastEvent.createdAtMS : Date.now();

  const finalDB = createSoccerEventMemoryDB(relevantEvents);
  const finalPossessionSets = computeAllEventSetInfo(relevantEvents);

  const { bestFitPlayerIdToPositionType, bestFitPositionTypeToPlayerIds, playerIdsWithPlayingTime } = p.overallSnapshotInfo
    ? p.overallSnapshotInfo
    : getOverallSnapshotInfo({ ...p, nowMS });

  // console.info(finalPossessionSets.map(eventSet => eventSet.type + "->" + eventSet.events.map(e => e.type).toString()));

  const finalEventIdToPossessionSetDetails = finalPossessionSets.reduce((acc, possSet) => {
    possSet.events.forEach((evt, i) => {
      acc[evt.id] = {
        eventIndex: i,
        info: possSet
      };
    });
    return acc;
  }, {} as Record<string, { info: SoccerPossessionEventSetInfo; eventIndex: number }>);

  const db = createSoccerEventMemoryDB([]);

  const bestFitFormationKey = db.presets.formationEvents().length
    ? db.presets.formationEvents()[0].newFormation
    : p.game.startingFormation;

  const baseSnapshot = createEmptyPartialSoccerStatSnapshot({
    game: p.game,
    events: p.events,
    bestFitFormationKey,
    snapshotType: p.snapshotType
  });

  const statFieldsWithNull = getEmptyStatStatFieldsWithNull(playerIdsWithPlayingTime);

  let currentMappings = createPositionMappings({ ...p, memoryDB: db });
  const finalAllPositionMappingsOrderedByPlayingTime = [{ playingTimeMS: 0, ...currentMappings }];

  const complexReduceAccumulators: Record<string, any> = {};

  //Stats "reduced" from the raw event stream
  let eventsUpToThisEvent: SoccerGameEvent[] = [];
  let eventsAfterThisEvent = relevantEvents.slice();
  relevantEvents.forEach((event, i) => {
    eventsUpToThisEvent.push(event);
    eventsAfterThisEvent.shift();
    db.insert([event]);

    if (isSoccerSubEvent(event) || isSoccerCardEvent(event)) {
      currentMappings = createPositionMappings({ ...p, memoryDB: db });
      finalAllPositionMappingsOrderedByPlayingTime.push({
        playingTimeMS: event.playingTimeMS,
        ...currentMappings
      });
    }

    const { currentFieldPositionNumToPlayerId, currentPlayerIdToFieldPositionNum } = currentMappings;

    const baseReduceStatComputationObj: Omit<BaseReduceStatComputationObj, "acc" | "teamId" | "oppositeTeamId"> = {
      allEvents: relevantEvents,
      eventsUpToThisEvent,
      currentMemoryDB: db,
      currentFieldPositionNumToPlayerId,
      currentPlayerIdToFieldPositionNum,
      prevKnownEvent:
        _.findLast(
          eventsUpToThisEvent,
          (evt, j) => j !== eventsUpToThisEvent.length - 1 && getEventPossessionState(evt) !== SoccerPossessionState.unknown
        ) || null,
      nextKnownEvent: _.find(eventsAfterThisEvent, evt => getEventPossessionState(evt) !== SoccerPossessionState.unknown) || null,
      event,
      currentPossessionSetDetails: finalEventIdToPossessionSetDetails[event.id],
      finalEventIdToPossessionSetDetails,
      game: p.game,
      rosterPrettyPlayers: mostlyEmptyPlayersArr,
      index: i
    };

    Object.values(computations).forEach(comp => {
      if (comp.statType === "game" && comp.reduce) {
        statFieldsWithNull.gameStats[comp.statKey] = comp.reduce({
          acc: statFieldsWithNull.gameStats[comp.statKey] ?? null,
          teamId: "",
          ...baseReduceStatComputationObj
        });
      } else if (comp.statType === "team" && (comp.reduce || comp.complexReduce)) {
        [p.game.teamId, SoccerIds.opponentTeamId].forEach(teamId => {
          const teamStatType = getTeamStatType(teamId);

          if (comp.complexReduce) {
            const complexAccKey = createComplexReduceKey({ teamId, statKey: comp.statKey });
            if (!complexReduceAccumulators[complexAccKey]) {
              complexReduceAccumulators[complexAccKey] = comp.complexReduce.initialAcc();
            }

            complexReduceAccumulators[complexAccKey] = comp.complexReduce.reduce({
              ...baseReduceStatComputationObj,
              acc: complexReduceAccumulators[complexAccKey],
              teamId
            });
          }

          if (comp.reduce) {
            statFieldsWithNull.teamStats[teamStatType][comp.statKey] =
              comp.reduce({
                ...baseReduceStatComputationObj,
                acc: statFieldsWithNull.teamStats[teamStatType][comp.statKey] ?? null,
                teamId
              }) ?? null;
          }
        });
      } else if (comp.statType === "player" && comp.reduce) {
        playerIdsWithPlayingTime.forEach(playerId => {
          statFieldsWithNull.playerStats[playerId][comp.statKey] =
            comp.reduce?.({
              ...baseReduceStatComputationObj,
              acc: statFieldsWithNull.playerStats[playerId][comp.statKey] ?? null,
              playerId,
              teamId: p.game.teamId
            }) ?? null;
        });
      } else if (comp.statType === "positionNumber" && p.game.statMode !== SoccerStatModes.team) {
        ObjectKeys(SoccerFormationsByKey[bestFitFormationKey]).forEach(positionNum => {
          statFieldsWithNull.positionNumberStats[comp.statKey][positionNum] =
            comp.reduce({
              ...baseReduceStatComputationObj,
              teamId: p.game.teamId,
              acc: statFieldsWithNull.positionNumberStats[comp.statKey][positionNum] ?? null,
              positionNum
            }) ?? null;
        });
      }
    });
  });

  const { timeSpentAtPositionByPlayerId } = getBestFitPositionTypeInfo({
    memoryDB: finalDB,
    game: p.game,
    nowMS,
    players: mostlyEmptyPlayersArr
  });

  //Complex reducers
  Object.values(computations).forEach(comp => {
    if (comp.statType === "team") {
      if (comp.complexReduce) {
        const { finalizeAcc } = comp.complexReduce;
        [SoccerIds.opponentTeamId, p.game.teamId].forEach(teamId => {
          const complexAccKey = createComplexReduceKey({ teamId, statKey: comp.statKey });
          const acc = complexReduceAccumulators[complexAccKey];
          if (acc) {
            statFieldsWithNull.teamStats[getTeamStatType(teamId)][comp.statKey] = finalizeAcc(acc);
          }
        });
      }
    }
  });

  //First stage computation
  Object.values(computations).forEach(comp => {
    if (!("compute" in comp)) {
      return;
    }

    if (comp.statType === "game") {
      statFieldsWithNull.gameStats[comp.statKey] = comp.compute?.({
        allEvents: relevantEvents,
        game: p.game,
        finalEventIdToPossessionSetDetails,
        rosterPrettyPlayers: mostlyEmptyPlayersArr,
        memoryDB: db
      });
    } else if (comp.statType === "player") {
      playerIdsWithPlayingTime.forEach(playerId => {
        statFieldsWithNull.playerStats[playerId][comp.statKey] = comp.compute?.({
          playerId,
          allEvents: relevantEvents,
          finalEventIdToPossessionSetDetails,
          finalPossessionSets,
          game: p.game,
          rosterPrettyPlayers: mostlyEmptyPlayersArr,
          memoryDB: db
        });
      });
    } else if (comp.statType === "team") {
      [SoccerIds.opponentTeamId, p.game.teamId].forEach(teamId => {
        //NOTE: Keep this logic in sync with the possession logic in StatsEntryMemoryController.tsx that is used to set possession percent in live game mode
        const thisTeamFinalPossessionSets = finalPossessionSets.filter(set =>
          teamId === SoccerIds.opponentTeamId ? set.type === "opp-team" : set.type === "own-team"
        );

        statFieldsWithNull.teamStats[getTeamStatType(teamId)][comp.statKey] = comp.compute?.({
          teamId,
          allEvents: relevantEvents,
          game: p.game,
          rosterPrettyPlayers: mostlyEmptyPlayersArr,
          thisTeamFinalPossessionSets,
          finalEventIdToPossessionSetDetails,
          finalPossessionSets,
          memoryDB: db
        });
      });
    }
  });

  //Stage two computation.
  _.orderBy(Object.values(computations), c => {
    return "computePriority" in c ? c.computePriority : 0;
  }).forEach(comp => {
    if (!("computeStageTwo" in comp)) {
      return;
    }

    if (comp.statType === "game") {
      statFieldsWithNull.gameStats[comp.statKey] = comp.computeStageTwo?.({
        game: p.game,
        finalPossessionSets,
        stats: statFieldsWithNull,
        memoryDB: db
      });
    } else if (comp.statType === "team") {
      [SoccerIds.opponentTeamId, p.game.teamId].forEach(teamId => {
        const thisTeamFinalPossessionSets = finalPossessionSets.filter(set =>
          teamId === SoccerIds.opponentTeamId ? set.type === "opp-team" : set.type === "own-team"
        );

        statFieldsWithNull.teamStats[getTeamStatType(teamId)][comp.statKey] = comp.computeStageTwo?.({
          game: p.game,
          thisTeamFinalPossessionSets,
          finalPossessionSets,
          oppositeTeamId: teamId === SoccerIds.opponentTeamId ? p.game.teamId : SoccerIds.opponentTeamId,
          teamId,
          stats: statFieldsWithNull,
          memoryDB: db
        });
      });
    } else if (comp.statType === "player") {
      playerIdsWithPlayingTime.forEach(playerId => {
        statFieldsWithNull.playerStats[playerId][comp.statKey] = comp.computeStageTwo?.({
          game: p.game,
          finalPossessionSets,
          timeSpentAtPositionByPlayerId,
          playerId,
          stats: statFieldsWithNull,
          memoryDB: db
        });
      });
    }
  });

  //Compute normalized stats (aka stats going from min of 0 to max of 1)
  statFieldsWithNull.normalizedPlayerStats = computeNormalizedPlayerStats(statFieldsWithNull.playerStats, p.locale);

  //Compute positional averages
  const playerStatDefs = _.pickBy(defs, def => def?.type === "player");
  statFieldsWithNull.positionAvgStats = _.mapValues(SoccerPositionTypes, positionType => {
    return _.mapValues(playerStatDefs, (def, statKey: PlayerStatKeys) => {
      const relevantPlayerIds = Object.keys(bestFitPositionTypeToPlayerIds[positionType]);

      const relevantStatVals = relevantPlayerIds
        .filter(pid => playerIdsWithPlayingTime.includes(pid))
        .map(playerId => statFieldsWithNull.playerStats[playerId][statKey])
        .filter((a): a is number => typeof a === "number");

      if (relevantStatVals.length) {
        return relevantStatVals.reduce((acc, a) => acc + a, 0) / relevantStatVals.length;
      } else {
        return null;
      }
    });
  });

  //Compute normalized positional averages
  statFieldsWithNull.normalizedPositionStats = computeNormalizedPositionStats({
    bestFitPositionTypeToPlayerIds: bestFitPositionTypeToPlayerIds,
    normalizedPlayerStats: statFieldsWithNull.normalizedPlayerStats
  });

  const snapshot = {
    id: String(Math.random()),
    bestFitPositionTypeToPlayerIds: bestFitPositionTypeToPlayerIds,
    bestFitPlayerIdToPositionType: bestFitPlayerIdToPositionType,
    timeSpentAtPositionByPlayerId,
    ...baseSnapshot,
    ...compactStatFieldsWithNull(statFieldsWithNull)
  };

  //Compute game result
  if (lastEvent?.type === SoccerGameEventType.stopHalf && lastEvent.endsGame && p.game.gameStage === "ended") {
    snapshot.gameResult = p.game.officiallyEndedGameSummaryStats.gameResult;
  }

  //Make the result deterministic since floating point math is not deterministic. But the differences are always removed if we round to 6 decimals
  traverse(snapshot, val => {
    if (typeof val === "number" && val % 1 !== 0) {
      const numDigits = 1000000;
      return Math.round(val * numDigits) / numDigits;
    }
    return val;
  });

  // Basically removes any players that had no playing
  Object.keys(snapshot.playerStats).forEach(playerId => {
    if (Object.keys(snapshot.playerStats[playerId]).length === 0) {
      delete snapshot.playerStats[playerId];
      delete snapshot.normalizedPlayerStats[playerId];
    }
  });

  return snapshot;
}

function createEmptyPartialSoccerStatSnapshot(p: {
  game: StartedSoccerGame | EndedSoccerGame;
  events: SoccerGameEvent[];
  bestFitFormationKey: SoccerFormationKeys;
  snapshotType: SoccerStatSnapshot["snapshotType"];
}) {
  const snapshot: BaseSnapshot = {
    dateOfEventMS: p.events.length ? p.events[0].createdAtMS : p.game.officialStartOfGameMS || p.game.createdAtMS,
    dateSnapGeneratedMS: Date.now(),
    eventCount: p.events.length,
    soccerLogicVersion,
    md5OfEvents: md5(stableStringify(p.events)),
    md5OfStatRelevantGameFields: md5(stableStringify(pickStatRelevantGameFields(p.game))),
    snapshotType: p.snapshotType,
    calendarEntryId: p.game.calendarEntryId,
    soccerGameId: p.game.id,
    teamId: p.game.teamId,
    bestFitFormationKey: p.bestFitFormationKey,
    teamIdWithSquad: common__generateTeamIdWithSquad(p.game.teamId, p.game.squad)
  };

  return snapshot;
}

function createPositionMappings(p: { memoryDB: SoccerEventMemoryDB; game: StartedSoccerGame | EndedSoccerGame }) {
  const roster = computeSoccerGamePlayerRoster({
    memoryDB: p.memoryDB,
    players: createMostlyEmptyPlayersArray(p.game),
    soccerGame: p.game
  }).roster;

  const currentPlayerIdToFieldPositionNum: { [str in string]?: SoccerPositionNumber | null } = {};
  const currentFieldPositionNumToPlayerId: { [num in SoccerPositionNumber]?: string } = {};
  roster.forEach(pl => {
    currentPlayerIdToFieldPositionNum[pl.id] = pl.fieldPositionNumber;
    if (pl.fieldPositionNumber) {
      currentFieldPositionNumToPlayerId[pl.fieldPositionNumber] = pl.id;
    }
  });

  return {
    currentPlayerIdToFieldPositionNum,
    currentFieldPositionNumToPlayerId
  };
}

function traverse(jsonObj: any, fn: (val: any) => any, parentObj?: any, parentKey?: any) {
  if (jsonObj !== null && typeof jsonObj == "object") {
    Object.entries(jsonObj).forEach(([key, value]) => {
      // key is either an array index or object key
      traverse(value, fn, jsonObj, key);
    });
  } else if (parentObj && parentKey) {
    parentObj[parentKey] = fn(jsonObj);
  }
}

function computeNormalizedPlayerStats(
  playerStats: StatFieldsWithNull["playerStats"],
  locale: string
): StatFieldsWithNull["normalizedPlayerStats"] {
  const normalizedPlayerStats = _.cloneDeep(playerStats);

  ObjectValues(PlayerStatKeys).forEach(statKey => {
    if (getSoccerStatDefinitions(locale)[statKey].formatCategory === "percent") {
      return;
    }

    let min: number = Infinity;
    let max: number = -Infinity;
    Object.keys(playerStats).forEach(playerId => {
      const val = playerStats[playerId][statKey];
      if (typeof val !== "number") {
        return;
      }

      if (val > max) {
        max = val;
      }
      if (val < min) {
        min = val;
      }
    });

    if (!isFinite(min) || !isFinite(max)) {
      return;
    }

    Object.keys(playerStats).forEach(playerId => {
      const origVal = playerStats[playerId][statKey];
      const divisor = max - min;
      if (typeof origVal !== "number" || divisor <= 0) {
        delete normalizedPlayerStats[playerId][statKey];
        return;
      }

      const newVal = (origVal - min) / divisor;
      normalizedPlayerStats[playerId] = normalizedPlayerStats[playerId] || {};
      normalizedPlayerStats[playerId][statKey] = newVal;
    });
  });

  return normalizedPlayerStats;
}

function computeNormalizedPositionStats(p: {
  bestFitPositionTypeToPlayerIds: SoccerStatSnapshot["bestFitPositionTypeToPlayerIds"];
  normalizedPlayerStats: StatFieldsWithNull["normalizedPlayerStats"];
}): StatFieldsWithNull["positionAvgStats"] {
  const normPositionStats: SoccerStatSnapshot["positionAvgStats"] = {
    [SoccerPositionTypes.defender]: {},
    [SoccerPositionTypes.forward]: {},
    [SoccerPositionTypes.midfielder]: {},
    [SoccerPositionTypes.goalkeeper]: {}
  };

  ObjectKeys(p.bestFitPositionTypeToPlayerIds).forEach(position => {
    ObjectKeys(normPositionStats[position]).forEach(statKey => {
      const normVals = Object.keys(p.bestFitPositionTypeToPlayerIds[position]).map(playerId => {
        return p.normalizedPlayerStats[playerId][statKey];
      });

      if (normVals.length) {
        normPositionStats[position][statKey] =
          normVals.reduce<number>((acc, b) => {
            if (typeof b === "number") {
              return acc + b;
            } else {
              return acc;
            }
          }, 0) / normVals.length;
      }
    });
  });

  return normPositionStats;
}

//NOTE: We have to use this mostly empty player array because before the soccer game
//has started, the soccer game is independent from the players in the game. Thus before the
//soccer game the soccerGame roster property is not the source of truth.
function createMostlyEmptyPlayersArray(game: PostStartedSoccerGame) {
  return Object.keys(game.roster).map(playerId => createMostlyEmptyPrettyPlayer({ playerId, playerTeamId: game.teamId }));
}

function createMostlyEmptyPrettyPlayer(p: { playerId: string; playerTeamId: string }): PrettyPlayer {
  return {
    player: {
      createdAtMS: Date.now(),
      deletedAtMS: 0,
      id: p.playerId,
      jerseyNumber: "",
      teamId: p.playerTeamId,
      virtualAthleteAccount: {
        email: "",
        firstName: "",
        lastName: ""
      }
    },
    derived: {
      accountInfo: {
        email: "",
        firstName: "",
        lastName: ""
      },
      accountInfoSource: "player"
    }
  };
}

function createComplexReduceKey(p: { statKey: SoccerStatKeys; teamId: string }) {
  return p.statKey + p.teamId;
}

function compactStatFieldsWithNull(a: StatFieldsWithNull): StatFields {
  return {
    gameStats: _.pickBy(a.gameStats, b => typeof b === "number") as StatFields["gameStats"],
    playerStats: _.mapValues(a.playerStats, b => _.pickBy(b, c => typeof c === "number")),
    normalizedPlayerStats: _.mapValues(a.normalizedPlayerStats, b => _.pickBy(b, c => typeof c === "number")),
    teamStats: _.mapValues(a.teamStats, b => _.pickBy(b, c => typeof c === "number")),
    positionAvgStats: _.mapValues(a.positionAvgStats, b => _.pickBy(b, c => typeof c === "number")),
    normalizedPositionStats: _.mapValues(a.normalizedPositionStats, b => _.pickBy(b, c => typeof c === "number")),
    positionNumberStats: _.mapValues(a.positionNumberStats, b => _.pickBy(b, c => typeof c === "number"))
  };
}

function getEmptyStatStatFieldsWithNull(playerIdsWithPlayingTime: string[]): StatFieldsWithNull {
  const rosterObj = _.mapValues(_.invert(playerIdsWithPlayingTime), () => ({}));
  return {
    gameStats: {},
    playerStats: _.mapValues(rosterObj, () => ({})),
    normalizedPlayerStats: _.mapValues(rosterObj, () => ({})),
    teamStats: {
      ownTeam: {},
      opponentTeam: {}
    },
    positionAvgStats: _.mapValues(SoccerPositionTypes, () => ({})),
    normalizedPositionStats: _.mapValues(SoccerPositionTypes, () => ({})),
    positionNumberStats: _.mapValues(_.invert(PositionNumStatKeys), () => ({})) as any
  };
}

function getOverallSnapshotInfo(p: { nowMS: number; game: StartedSoccerGame | EndedSoccerGame; events: SoccerGameEvent[] }) {
  const finalDB = createSoccerEventMemoryDB(p.events);

  const mostlyEmptyPlayersArr = createMostlyEmptyPlayersArray(p.game);
  const { playerIdToPositionType: bestFitPlayerIdToPositionType, positionTypeToPlayerIds: bestFitPositionTypeToPlayerIds } =
    getBestFitPositionTypeInfo({
      memoryDB: finalDB,
      game: p.game,
      nowMS: p.nowMS,
      players: mostlyEmptyPlayersArr
    });

  const playerIdToPlayingTimeMS = expensiveComputeSoccerPlayerIdToPlayingTimeMS({
    memoryDB: finalDB,
    game: p.game,
    nowMS: p.nowMS,
    players: mostlyEmptyPlayersArr
  });

  const playerIdsWithPlayingTime =
    p.game.statMode === SoccerStatModes.team
      ? mostlyEmptyPlayersArr?.map(a => a.player.id)
      : Object.keys(playerIdToPlayingTimeMS).filter(playerId => playerIdToPlayingTimeMS[playerId]);

  return {
    playerIdsWithPlayingTime,
    bestFitPlayerIdToPositionType,
    bestFitPositionTypeToPlayerIds
  };
}

// i18n certified - complete

import { SoccerGameEvent, SoccerGameEventType, PostStartedSoccerGame, SoccerStatModes } from "@ollie-sports/models";
import { BaseReduceStatComputationObj } from "./types";
import { isPlayerGeneric } from "../SoccerFns";

type Props = Omit<BaseReduceStatComputationObj, "acc"> & { playerId?: string };

export function isTurnover(p: Props): boolean {
  const possSet = p.finalEventIdToPossessionSetDetails[p.event.id];

  if (!possSet.info.type) {
    return false;
  }

  if (TEMPORARY__isBlacklistTurnoverActionType(p.event)) {
    return false;
  }

  if (
    possSet.eventIndex === possSet.info.events.length - 1 && //Must be the end of a possession set
    "playerTeamId" in p.event &&
    p.event.playerTeamId === p.teamId &&
    p.nextKnownEvent
  ) {
    if (p.game.statMode !== SoccerStatModes.team && p.playerId && "playerId" in p.event && p.event.playerId !== p.playerId) {
      return false;
    }

    const nextPossDetails = p.finalEventIdToPossessionSetDetails[p.nextKnownEvent.id];
    const nextPossType = nextPossDetails.info.type;

    return nextPossType ? nextPossType !== possSet.info.type : false;
  }

  return false;
}

export function isRecovery(p: Props): boolean {
  const possSet = p.finalEventIdToPossessionSetDetails[p.event.id];

  if (TEMPORARY__isBlacklistRecoveryActionType(p.event)) {
    return false;
  }

  if (
    possSet.info.type &&
    "playerTeamId" in p.event &&
    p.teamId === p.event.playerTeamId &&
    possSet.eventIndex === 0 && //Must be the beginning of a possession set
    p.prevKnownEvent
  ) {
    if (p.game.statMode !== SoccerStatModes.team && p.playerId && "playerId" in p.event && p.event.playerId !== p.playerId) {
      return false;
    }

    const prevPossDetails = p.finalEventIdToPossessionSetDetails[p.prevKnownEvent.id];
    const prevPossType = prevPossDetails.info.type;

    return prevPossType ? prevPossType !== possSet.info.type : false;
  }

  return false;
}

//TODO: We rely on possessions of type "null" to indicate non-recovery/turnover actions (like a clearance, cross, or shot).
//But a shot followed by a catch does not have that null possession spacer. The long term fix is likely to not rely on that null possession spacer
function TEMPORARY__isBlacklistTurnoverActionType(event: SoccerGameEvent) {
  switch (event.type) {
    case SoccerGameEventType.shotWithGoal:
    case SoccerGameEventType.shot:
    case SoccerGameEventType.clearance:
    case SoccerGameEventType.cross:
    case SoccerGameEventType.ownTeamGoal:
      return true;
    default:
      return false;
  }
}

function TEMPORARY__isBlacklistRecoveryActionType(event: SoccerGameEvent) {
  switch (event.type) {
    case SoccerGameEventType.throwIn:
    case SoccerGameEventType.cornerKickCross:
    case SoccerGameEventType.cornerKickShort:
    case SoccerGameEventType.goalKickLong:
    case SoccerGameEventType.goalKickPass:
    case SoccerGameEventType.postGoalKickoff:
    case SoccerGameEventType.freeKickCross:
    case SoccerGameEventType.freeKickLong:
    case SoccerGameEventType.freeKickPass:
    case SoccerGameEventType.shot:
      return true;
    default:
      return false;
  }
}

// i18n certified - complete

import { translate } from "@ollie-sports/i18n";
import { SoccerStatKeysObj, SoccerStatModes, TeamStatKeys } from "@ollie-sports/models";
import { StatDefBase } from "./types";
import _ from "lodash";

export enum TeamStatCategories {
  offense = "offense",
  defense = "defense",
  turnovers = "turnovers",
  setPieces = "setPieces",
  hidden = "hidden"
}

export const getPrettyTeamStatCategories = _.memoize((locale: string) => {
  return {
    [TeamStatCategories.offense]: translate.common(locale).Offense,
    [TeamStatCategories.setPieces]: translate({ defaultMessage: "Set Pieces", serverLocale: locale }),
    [TeamStatCategories.defense]: translate.common(locale).Defense,
    [TeamStatCategories.turnovers]: translate.common(locale).Turnovers,
    [TeamStatCategories.hidden]: "----hidden----"
  };
});

export const TeamStatCategoriesVisualTabOrdering = [
  TeamStatCategories.offense,
  TeamStatCategories.setPieces,
  TeamStatCategories.defense,
  TeamStatCategories.turnovers
];

export interface TeamStatDef extends StatDefBase {
  type: "team";
  tabCategory: TeamStatCategories;
  statKey: TeamStatKeys;
}

export const getTeamSoccerStatDefs = _.memoize((locale: string): Record<TeamStatKeys, TeamStatDef> => {
  return {
    /****
     *
     *
     *  Offense
     *
     * ****/
    [SoccerStatKeysObj.tShotDifferential]: {
      statKey: SoccerStatKeysObj.tShotDifferential,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shot Differential", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shot Diff",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of shots subtract shots of opponent.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tGoalDifferential]: {
      statKey: SoccerStatKeysObj.tGoalDifferential,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goal Differential", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goal Diff",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of goals subtract goals of opponent.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tPkShootoutKicksScored]: {
      statKey: SoccerStatKeysObj.tPkShootoutKicksScored,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "PK Shootout Score", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Pk Shootout",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Total number of shots scored during the PK shootout.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tPossessionPerc]: {
      statKey: SoccerStatKeysObj.tPossessionPerc,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Possession %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Possess. %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Total number of completed passes divided by all completed passes by both teams.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tCompletedPasses]: {
      statKey: SoccerStatKeysObj.tCompletedPasses,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Completed Passes", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Compl. Passes",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Successful transfers of possession between teammates. Includes successful crosses, clearances, and throw-ins.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tTouches]: {
      statKey: SoccerStatKeysObj.tTouches,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Touches", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Touches",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Total number of times a player on the team had the opportunity to make a controlled decision with the ball such as a pass, etc. Does NOT mean the literal number of times the player touched the ball.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tSuccessRateWithBallPerc]: {
      statKey: SoccerStatKeysObj.tSuccessRateWithBallPerc,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Success Rate w/ Ball", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Succ. Rate w/ Ball",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Approximate pass completion rate. Calculated by subtracting turnovers from total touches and dividing the result by total touches.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tPossessionsWithShotChancePerc]: {
      statKey: SoccerStatKeysObj.tPossessionsWithShotChancePerc,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Possessions Ending in Shot/Cross %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Poss. Ending Shot/Cross %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of possessions that include a cross or shot.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tAvgPassesPerPoss]: {
      statKey: SoccerStatKeysObj.tAvgPassesPerPoss,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Average Passes / Possession", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Avg. Passes / Possess.",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Average number of passes over all team possessions.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tAvgPassesPerPossStartingWithGoalKick]: {
      statKey: SoccerStatKeysObj.tAvgPassesPerPossStartingWithGoalKick,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Average Passes After A Goal Kick", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Avg. Passes From Goal Kick",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Average number of passes over all team possessions that start from a Goal Kick.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tAvgPassesAfterGoalieTouch]: {
      statKey: SoccerStatKeysObj.tAvgPassesAfterGoalieTouch,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Average Passes After A Goalie Touch", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Avg. Passes After Goalie",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Average number of passes over all team possessions after a goalie touches the ball.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tAvgPassesOnGoals]: {
      statKey: SoccerStatKeysObj.tAvgPassesOnGoals,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Average Passes on Goals", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Avg. Passes on Goals",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Average number of passes on only those possessions that end with a goal.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tLongestPassingChain]: {
      statKey: SoccerStatKeysObj.tLongestPassingChain,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Longest Passing Chain", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Longest Passing Chain",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "From all team possessions, the single possession with the most consecutive completed passes.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tGoals]: {
      statKey: SoccerStatKeysObj.tGoals,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 1,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goals", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goals",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Number of goals.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tShots]: {
      statKey: SoccerStatKeysObj.tShots,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 2,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shots", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total shots.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tShotsOnTarget]: {
      statKey: SoccerStatKeysObj.tShotsOnTarget,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 3,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shots on Goal", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots on Goal",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Shots that would have scored had there been no goalie present. E.g. shots heading directly towards the net.",
        serverLocale: locale
      })
    },

    [SoccerStatKeysObj.tShotsOnTargetPerc]: {
      statKey: SoccerStatKeysObj.tShotsOnTargetPerc,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Shots on Goal %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots on Goal %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Shots on goal divided by total shots.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tShotFinishingPerc]: {
      statKey: SoccerStatKeysObj.tShotFinishingPerc,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Finish Rate", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Finish Rate",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total goals divided by total shots.", serverLocale: locale })
    },

    [SoccerStatKeysObj.tCrosses]: {
      statKey: SoccerStatKeysObj.tCrosses,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Crosses", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Crosses",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Pass attempts played from a wide position intended for a teammate in front of the goal.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tSuccessfulCrossPerc]: {
      statKey: SoccerStatKeysObj.tSuccessfulCrossPerc,
      tabCategory: TeamStatCategories.offense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Cross to Shot %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Cross to Shot %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of crosses resulting in a shot (on the same possession).",
        serverLocale: locale
      })
    },

    [SoccerStatKeysObj.tSuccessfulCrosses]: {
      statKey: SoccerStatKeysObj.tSuccessfulCrosses,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },

    [SoccerStatKeysObj.tPossessions]: {
      statKey: SoccerStatKeysObj.tPossessions,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },
    [SoccerStatKeysObj.tPossessionsWithShotChance]: {
      statKey: SoccerStatKeysObj.tPossessionsWithShotChance,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Possessions Ending in Shot/Cross", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Poss. Ending Shot/Cross",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Possessions that include a cross or shot.", serverLocale: locale })
    },
    /***
     *
     *
     * Defense
     *
     *
     *  **/
    [SoccerStatKeysObj.tRecoveries]: {
      statKey: SoccerStatKeysObj.tRecoveries,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 7,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Recoveries", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Recover.",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Actions that result in regaining possession from the opposing team.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tSaves]: {
      statKey: SoccerStatKeysObj.tSaves,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 5,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Saves", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Saves",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of caught or blocked shots.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tGoalieCatches]: {
      statKey: SoccerStatKeysObj.tGoalieCatches,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Caught Saves", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Caught Saves",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of caught shots.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tGoalieBlocks]: {
      statKey: SoccerStatKeysObj.tGoalieBlocks,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Blocked Saves", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Blocked Saves",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of blocked shots.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tFouls]: {
      statKey: SoccerStatKeysObj.tFouls,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 6,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Fouls", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Fouls",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of fouls.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tCards]: {
      statKey: SoccerStatKeysObj.tCards,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },
    [SoccerStatKeysObj.tYellowCards]: {
      statKey: SoccerStatKeysObj.tYellowCards,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 12,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Yellow Cards", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Yellow Cards",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of yellow cards.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tRedCards]: {
      statKey: SoccerStatKeysObj.tRedCards,
      tabCategory: TeamStatCategories.defense,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 11,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Red Cards", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Red Cards",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of red cards.", serverLocale: locale })
    },

    /****
     *
     *
     *
     *  Turnovers
     *
     *
     *  ****/
    [SoccerStatKeysObj.tTurnovers]: {
      statKey: SoccerStatKeysObj.tTurnovers,
      tabCategory: TeamStatCategories.turnovers,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 8,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Turnovers", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Turnovers",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Total number of times the team lost possession. Includes bad throw-ins, passes, tackles, etc. Does not include losses of possesion from crosses.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tTurnoverPerc]: {
      statKey: SoccerStatKeysObj.tTurnoverPerc,
      tabCategory: TeamStatCategories.turnovers,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Turnover %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Turnover %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Turnovers divided by total touches", serverLocale: locale })
    },
    [SoccerStatKeysObj.tOffsides]: {
      statKey: SoccerStatKeysObj.tOffsides,
      tabCategory: TeamStatCategories.turnovers,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 9,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Offsides", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Offsides",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of offside infractions.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tHandballs]: {
      statKey: SoccerStatKeysObj.tHandballs,
      tabCategory: TeamStatCategories.turnovers,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Handballs", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Handballs",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of handball infractions.", serverLocale: locale })
    },

    /****
     *
     *
     *
     *  Set Pieces
     *
     *
     * ****/
    [SoccerStatKeysObj.tCornerKicks]: {
      statKey: SoccerStatKeysObj.tCornerKicks,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 4,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Corner Kicks", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Corner Kicks",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of corner kicks.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tSuccessfulCornerKicks]: {
      statKey: SoccerStatKeysObj.tSuccessfulCornerKicks,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },
    [SoccerStatKeysObj.tSuccessfulCornerKickPerc]: {
      statKey: SoccerStatKeysObj.tSuccessfulCornerKickPerc,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Corner Kick to Shot %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Corn. Kick to Shot %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of corner kicks that result in a shot on the same possession.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tFreeKicks]: {
      statKey: SoccerStatKeysObj.tFreeKicks,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Free Kicks", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Free Kicks",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total free kick count.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tThrowIns]: {
      statKey: SoccerStatKeysObj.tThrowIns,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Throw-Ins", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Throw-Ins",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total throw-in count.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tSuccessfulThrowIns]: {
      statKey: SoccerStatKeysObj.tSuccessfulThrowIns,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Successful Throw-ins", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Successful Throw-ins",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of successful throw-ins.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tSuccessfulThrowInPerc]: {
      statKey: SoccerStatKeysObj.tSuccessfulThrowInPerc,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Throw-In Success %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Throw-In Success %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of throw-ins that did not result in a change of possession on the next action.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tThrowInInfractions]: {
      statKey: SoccerStatKeysObj.tThrowInInfractions,
      tabCategory: TeamStatCategories.turnovers,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Throw-In Infractions", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Throw-In Infractions",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of throw-in infractions.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tGoalKicks]: {
      statKey: SoccerStatKeysObj.tGoalKicks,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goal Kicks", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goal Kicks",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of goal kicks.", serverLocale: locale })
    },
    [SoccerStatKeysObj.tSuccessfulGoalKicks]: {
      statKey: SoccerStatKeysObj.tSuccessfulGoalKicks,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: "",
      prettyShortName: "",
      prettyDefinition: ""
    },
    [SoccerStatKeysObj.tSuccessfulGoalKickPerc]: {
      statKey: SoccerStatKeysObj.tSuccessfulGoalKickPerc,
      tabCategory: TeamStatCategories.setPieces,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Goal Kick Success %", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goal Kick Success %",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of goal kicks that were received by a member of the same team.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tPenaltyKicksScored]: {
      statKey: SoccerStatKeysObj.tPenaltyKicksScored,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Penalty Kicks Scored", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Penalty Kicks Scored",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Penalty kicks that resulted in a goal", serverLocale: locale })
    },
    [SoccerStatKeysObj.tPenaltyKicksAttempted]: {
      statKey: SoccerStatKeysObj.tPenaltyKicksAttempted,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Penalty Kicks Attempted", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Penalty Kicks Attmpts.",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Number of penalty kicks taken during the game", serverLocale: locale })
    },
    [SoccerStatKeysObj.tGoalieShutouts]: {
      statKey: SoccerStatKeysObj.tGoalieShutouts,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shutouts", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shutouts",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Number of games in which the team held the opposing team to a score of zero",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.tGoalsConceded]: {
      statKey: SoccerStatKeysObj.tGoalsConceded,
      tabCategory: TeamStatCategories.hidden,
      type: "team",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goals Conceded", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goals Conceded",
        description: "Must be a short value. Soccer stat label",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Number of goals successfuly scored by the opposing team",
        serverLocale: locale
      })
    }
  };
});

// i18n certified - complete

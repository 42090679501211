import _ from "lodash";
import { SoccerGameEventType, SoccerGame } from "@ollie-sports/models";
import { expensivelyGetStatsInputUXMode, StatsInputUXMode } from "./SoccerUXMode";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { translate } from "@ollie-sports/i18n";

export function computeSoccerInstructions(p: { memoryDB: SoccerEventMemoryDB; game: SoccerGame; locale: string }): string {
  //Nice little extra to tell the user what to do after the half starts
  const allEvents = p.memoryDB.getRawEvents();
  if (allEvents.slice(-1).pop()?.type === SoccerGameEventType.startHalf) {
    return "Tap or swipe to record events.";
  }

  const uxMode = expensivelyGetStatsInputUXMode(p.memoryDB);
  const instructionMap: Record<StatsInputUXMode, string> = {
    pendingOffTargetShot: translate({ defaultMessage: 'Tap a player or "Out" to indicate the result.', serverLocale: p.locale }),
    pendingPass: translate({ defaultMessage: "Tap the first player to gain possession.", serverLocale: p.locale }),
    pendingRefAssignmentOrDropBall: translate({
      defaultMessage: "Tap a player or the drop ball button to resume play.",
      serverLocale: p.locale
    }),
    pendingKickoffAfterRecentGoal: translate({
      defaultMessage: "When play begins, tap the player taking the kickoff.",
      serverLocale: p.locale
    }),
    inBetweenHalves: translate({
      defaultMessage: "When play begins, tap the player taking the kickoff.",
      serverLocale: p.locale
    }),
    videoPaused: translate({ defaultMessage: "Recording paused. Tap the green play button to resume.", serverLocale: p.locale }),
    default: "",
    gameEnded: translate({ defaultMessage: "Review stats to analyze the game.", serverLocale: p.locale }),
    gameNotYetStarted: translate({
      defaultMessage: "When play begins, tap the player taking the kickoff.",
      serverLocale: p.locale
    })
  };

  return instructionMap[uxMode];
}

// i18n certified - complete

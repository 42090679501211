import { SoccerGameEvent, SoccerGameEventType, SoccerPossessionState } from "@ollie-sports/models";
import { isGoalEvent, isPendingPassEvent, SoccerPauseEvent } from "./SoccerEventCategories";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { getEventPossessionState } from "./SoccerPossession";

function gameHasRecentGoalWithoutKickoff(allEvents: readonly SoccerGameEvent[]) {
  for (let i = allEvents.length - 1; i >= 0; i--) {
    const thisEvent = allEvents[i];
    const possState = getEventPossessionState(thisEvent);
    if (possState !== SoccerPossessionState.unknown && possState !== SoccerPossessionState.deadBall) {
      return false;
    }

    if (isGoalEvent(thisEvent)) {
      return true;
    } else {
      continue;
    }
  }

  return false;
}

function gameHasPendingOffTargetShot(allEvents: readonly SoccerGameEvent[]) {
  for (let i = allEvents.length - 1; i >= 0; i--) {
    const thisEvent = allEvents[i];

    const possState = getEventPossessionState(thisEvent);
    if (possState === SoccerPossessionState.unknown) {
      continue;
    }

    if (possState !== SoccerPossessionState.looseBall) {
      return false;
    } else {
      return thisEvent.type === SoccerGameEventType.shot;
    }
  }

  return false;
}

function gameIsInBetweenHalves(allEvents: readonly SoccerGameEvent[]) {
  for (let i = allEvents.length - 1; i >= 0; i--) {
    const thisEvent = allEvents[i];
    const possState = getEventPossessionState(thisEvent);
    if (possState !== SoccerPossessionState.unknown && possState !== SoccerPossessionState.deadBall) {
      return false;
    }

    if (thisEvent.type === SoccerGameEventType.stopHalf) {
      return true;
    } else {
      continue;
    }
  }

  return false;
}

function gameHasPendingPass(allEvents: readonly SoccerGameEvent[]) {
  for (let i = allEvents.length - 1; i >= 0; i--) {
    const thisEvent = allEvents[i];
    const possState = getEventPossessionState(thisEvent);
    if (possState === SoccerPossessionState.unknown) {
      continue;
    }

    return possState === SoccerPossessionState.player && isPendingPassEvent(thisEvent);
  }

  return false;
}

export type StatsInputUXMode =
  | "default"
  | "gameEnded"
  | "gameNotYetStarted"
  | "inBetweenHalves"
  | "pendingKickoffAfterRecentGoal"
  | "pendingOffTargetShot"
  | "pendingPass"
  | "videoPaused"
  | "pendingRefAssignmentOrDropBall";

//This is a bit misleadingly named. It's not THAT expensive. But we want to discourage people from calling this function willy nilly. Most callers
//that need to use the uxMode should use the currentUXModeEquals function on the DerivedGameState
export function expensivelyGetStatsInputUXMode(memoryDB: SoccerEventMemoryDB): StatsInputUXMode {
  const allEvents = memoryDB.getRawEvents();
  if (allEvents.length === 0) {
    return "gameNotYetStarted";
  }

  const lastEvent = allEvents.slice(-1).pop();
  if (lastEvent?.type === SoccerGameEventType.stopHalf && lastEvent.endsGame) {
    return "gameEnded";
  }

  if (gameIsInBetweenHalves(allEvents)) {
    return "inBetweenHalves";
  }

  const pendingGamePauseEvent =
    memoryDB.presets.pauseAndResumeEvents().length % 2 === 1
      ? (memoryDB.presets.pauseAndResumeEvents().slice(-1).pop()! as SoccerPauseEvent)
      : null;

  if (pendingGamePauseEvent?.reason === "video-pause") {
    return "videoPaused";
  }

  if (pendingGamePauseEvent?.reason === "game-delay" || pendingGamePauseEvent?.reason === "manual-main-official-stoppage") {
    return "pendingRefAssignmentOrDropBall";
  }

  if (gameHasPendingPass(allEvents)) {
    return "pendingPass";
  }

  if (gameIsInBetweenHalves(allEvents)) {
    return "inBetweenHalves";
  }

  if (gameHasRecentGoalWithoutKickoff(allEvents)) {
    return "pendingKickoffAfterRecentGoal";
  }

  if (gameHasPendingOffTargetShot(allEvents)) {
    return "pendingOffTargetShot";
  }

  //Important: This if must be last. Auto card pauses only require assignment/drop ball if there is no other pending action.
  if (pendingGamePauseEvent?.reason === "auto-card") {
    return "pendingRefAssignmentOrDropBall";
  }

  return "default";
}

// i18n certified - complete

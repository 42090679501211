import { translate } from "@ollie-sports/i18n";
import { SoccerStatKeysObj, SoccerStatModes, PlayerStatKeys } from "@ollie-sports/models";
import { StatDefBase } from "./types";
import _ from "lodash";

export enum PlayerStatCategories {
  passing = "passing",
  offense = "offense",
  defense = "defense",
  workrate = "workrate",
  shots = "shots",
  turnovers = "turnovers",
  keeper = "keeper",
  hidden = "hidden"
}

export const PlayerStatCategoriesVisualTabOrdering = [
  PlayerStatCategories.passing,
  PlayerStatCategories.offense,
  PlayerStatCategories.shots,
  PlayerStatCategories.workrate,
  PlayerStatCategories.defense,
  PlayerStatCategories.turnovers,
  PlayerStatCategories.keeper
];

export const getPrettyPlayerStatCategories = _.memoize((locale: string) => {
  return {
    [PlayerStatCategories.workrate]: translate({ defaultMessage: "Workrate", serverLocale: locale }),
    [PlayerStatCategories.passing]: translate({ defaultMessage: "Passing", serverLocale: locale }),
    [PlayerStatCategories.shots]: translate.common(locale).Shots,
    [PlayerStatCategories.offense]: translate.common(locale).Offense,
    [PlayerStatCategories.defense]: translate.common(locale).Defense,
    [PlayerStatCategories.turnovers]: translate.common(locale).Turnovers,
    [PlayerStatCategories.keeper]: translate({ defaultMessage: "Keeper", serverLocale: locale, description: "As in goalkeeper" }),
    [PlayerStatCategories.hidden]: "----hidden----"
  };
});

export interface PlayerStatDef extends StatDefBase {
  type: "player";
  tabCategorySpaceLimited: PlayerStatCategories;
  tabCategory: PlayerStatCategories;
  statKey: PlayerStatKeys;
}

export const getPlayerSoccerStatDefs = _.memoize((locale: string): Record<PlayerStatKeys, PlayerStatDef> => {
  return {
    /*****
     *
     *  Workrate
     *
     *
     */
    [SoccerStatKeysObj.pPlayingTimeMS]: {
      statKey: SoccerStatKeysObj.pPlayingTimeMS,
      type: "player",
      tabCategory: PlayerStatCategories.workrate,
      tabCategorySpaceLimited: PlayerStatCategories.workrate,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "ms",
      displayPriority: 8,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Playing Time", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Playing Time",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Total number of minutes played. Includes time played during stoppage time.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pPlayingTimePerc]: {
      statKey: SoccerStatKeysObj.pPlayingTimePerc,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Playing Time %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Playing Time %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of the total game minutes (including stoppage time) played",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pTouches]: {
      statKey: SoccerStatKeysObj.pTouches,
      type: "player",
      tabCategory: PlayerStatCategories.workrate,
      tabCategorySpaceLimited: PlayerStatCategories.workrate,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Touches", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Touches",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Total number of times the player had the opportunity to make a controlled decision with the ball such as a pass, etc. Does NOT mean the literal number of times the player touched the ball.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pPossessionParticipationPerc]: {
      statKey: SoccerStatKeysObj.pPossessionParticipationPerc,
      tabCategory: PlayerStatCategories.workrate,
      tabCategorySpaceLimited: PlayerStatCategories.workrate,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({
        defaultMessage: "Possession Participation %",
        description: "Soccer Stat Label",
        serverLocale: locale
      }),
      prettyShortName: translate({
        defaultMessage: "Possess. Particip. %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Percent of the total team possessions with at least one completed pass that the player participated in while playing.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pTouchesPerHour]: {
      statKey: SoccerStatKeysObj.pTouchesPerHour,
      tabCategory: PlayerStatCategories.workrate,
      tabCategorySpaceLimited: PlayerStatCategories.workrate,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Touches / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Touches / Hour",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of touches divided by playing time", serverLocale: locale })
    },
    /*****
     *
     *  Passing
     *
     *
     */
    [SoccerStatKeysObj.pCompletedPasses]: {
      statKey: SoccerStatKeysObj.pCompletedPasses,
      type: "player",
      tabCategory: PlayerStatCategories.passing,
      tabCategorySpaceLimited: PlayerStatCategories.passing,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Completed Passes", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Compl. Passes",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Successful transfers of possession to a teammate. Includes successful crosses, clearances, and throw-ins.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pCompletedPassesPerHour]: {
      statKey: SoccerStatKeysObj.pCompletedPassesPerHour,
      tabCategory: PlayerStatCategories.passing,
      tabCategorySpaceLimited: PlayerStatCategories.passing,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({
        defaultMessage: "Completed Passes / Hour",
        description: "Soccer Stat Label",
        serverLocale: locale
      }),
      prettyShortName: `${translate({
        defaultMessage: "Compl. Passes",
        description: "Short for Completed Passes",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Completed passes divided by total playing time", serverLocale: locale })
    },
    [SoccerStatKeysObj.pSuccessRateWithBallPerc]: {
      statKey: SoccerStatKeysObj.pSuccessRateWithBallPerc,
      tabCategory: PlayerStatCategories.passing,
      tabCategorySpaceLimited: PlayerStatCategories.passing,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Success Rate w/ Ball", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Succ. Rate w/ Ball",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Approximate pass completion rate. Calculated by subtracting turnovers from total touches and dividing the result by total touches.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pKeyPasses]: {
      statKey: SoccerStatKeysObj.pKeyPasses,
      type: "player",
      tabCategory: PlayerStatCategories.passing,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Key Passes", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Key Passes",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Passes that immediately led to an unsuccessful shot on the opposing goal.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pKeyPassesPerHour]: {
      statKey: SoccerStatKeysObj.pKeyPassesPerHour,
      tabCategory: PlayerStatCategories.passing,
      tabCategorySpaceLimited: PlayerStatCategories.passing,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Key Passes / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Key Passes",
        description: "Must be a short value. Key passes are important passes in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Key passes divided by playing time.", serverLocale: locale })
    },
    /*****
     *
     *  Shots
     *
     *
     */
    [SoccerStatKeysObj.pShots]: {
      statKey: SoccerStatKeysObj.pShots,
      type: "player",
      tabCategory: PlayerStatCategories.shots,
      tabCategorySpaceLimited: PlayerStatCategories.shots,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 5,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shots", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Shots on the opposing goal.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pShotsPerHour]: {
      statKey: SoccerStatKeysObj.pShotsPerHour,
      tabCategory: PlayerStatCategories.shots,
      tabCategorySpaceLimited: PlayerStatCategories.shots,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Shots / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Shots",
        description: "Must be a short value. Shots in a soccer game.",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Shots divided by playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pShotsOnTarget]: {
      statKey: SoccerStatKeysObj.pShotsOnTarget,
      type: "player",
      tabCategory: PlayerStatCategories.shots,
      tabCategorySpaceLimited: PlayerStatCategories.shots,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 4,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shots on Goal", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots on Goal",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Shots that would have resulted in a goal without the intervention of a goalkeeper or field player.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pShotsOnTargetPerHour]: {
      statKey: SoccerStatKeysObj.pShotsOnTargetPerHour,
      tabCategory: PlayerStatCategories.shots,
      tabCategorySpaceLimited: PlayerStatCategories.shots,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Shots On Goal / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Shots on Goal",
        description: "Must be a short value. Shots that are directed towards goal in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Shots on goal divided by total playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pShotsOnTargetPerc]: {
      statKey: SoccerStatKeysObj.pShotsOnTargetPerc,
      tabCategory: PlayerStatCategories.shots,
      tabCategorySpaceLimited: PlayerStatCategories.shots,
      type: "player",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Shots on Goal %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots on Goal %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Shots on goal divided by total shots.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pShotFinishingPerc]: {
      statKey: SoccerStatKeysObj.pShotFinishingPerc,
      tabCategory: PlayerStatCategories.shots,
      tabCategorySpaceLimited: PlayerStatCategories.shots,
      type: "player",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Finish Rate", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Finish Rate",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total goals divided by total shots.", serverLocale: locale })
    },

    /*****
     *
     *  Offense
     *
     *
     */
    [SoccerStatKeysObj.pGoals]: {
      statKey: SoccerStatKeysObj.pGoals,
      type: "player",
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 7,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goals", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goals",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Shots resulting in a goal for the player's team.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pGoalsPerHour]: {
      statKey: SoccerStatKeysObj.pGoalsPerHour,
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.offense,
      type: "player",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Goals / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Goals",
        description: "Must be a short value. Stat for goals in a soccer game.",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Goals divided by total playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pAssists]: {
      statKey: SoccerStatKeysObj.pAssists,
      type: "player",
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 6,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Assists", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Assists",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Completed passes in which the receiving player immediately scores a goal.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pAssistsPerHour]: {
      statKey: SoccerStatKeysObj.pAssistsPerHour,
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.offense,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Assists / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Assists",
        description: "Must be a short value. Stat for assists in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Assists divided by total playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pCrosses]: {
      statKey: SoccerStatKeysObj.pCrosses,
      type: "player",
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Crosses", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Crosses",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Pass attempts from a wide position intended for a teammate in front of the goal.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pCrossesPerHour]: {
      statKey: SoccerStatKeysObj.pCrossesPerHour,
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.offense,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Crosses / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Crosses",
        description: "Must be a short value. stat for crosses in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Crosses divided by total playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pSuccessfulCrosses]: {
      statKey: SoccerStatKeysObj.pSuccessfulCrosses,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Successful Crosses", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Successful Crosses",
        description: "Must be a short value. Crosses that are successful in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Crosses resulting in a shot.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pSuccessfulCrossesPerc]: {
      statKey: SoccerStatKeysObj.pSuccessfulCrossesPerc,
      type: "player",
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.offense,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Cross to Shot %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Cross to Shot %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of crosses that result in a shot on the same possession.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pDribbles]: {
      statKey: SoccerStatKeysObj.pDribbles,
      type: "player",
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Dribbles", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Dribbles",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Total number of times a player beats an opponent one on one through superior ball handling.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pThrowIns]: {
      statKey: SoccerStatKeysObj.pThrowIns,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Throw-Ins", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Throw-Ins",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Total number of times throwing in the ball in after an out of bounds.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pSuccessfulThrowIns]: {
      statKey: SoccerStatKeysObj.pSuccessfulThrowIns,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Successful Throw-Ins", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Succ. Throw-Ins",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Throw-ins which result in the team retaining possession.",
        serverLocale: locale
      })
    },

    [SoccerStatKeysObj.pSuccessfulThrowInsPerc]: {
      statKey: SoccerStatKeysObj.pSuccessfulThrowInsPerc,
      tabCategory: PlayerStatCategories.offense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Successful Throw-In %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Success. Throw-In %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of throws in which the team retains possession.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pThrowInInfractions]: {
      statKey: SoccerStatKeysObj.pThrowInInfractions,
      type: "player",
      tabCategory: PlayerStatCategories.turnovers,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Throw-In Infractions", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Throw-In Infractions",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of throw-in infractions.", serverLocale: locale })
    },
    /*****
     *
     *  Defense
     *
     *
     */

    [SoccerStatKeysObj.pRecoveries]: {
      statKey: SoccerStatKeysObj.pRecoveries,
      type: "player",
      tabCategory: PlayerStatCategories.defense,
      tabCategorySpaceLimited: PlayerStatCategories.defense,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Recoveries", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Recoveries",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Actions that result in regaining possession from the opposing team.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pRecoveriesPerHour]: {
      statKey: SoccerStatKeysObj.pRecoveriesPerHour,
      tabCategory: PlayerStatCategories.defense,
      tabCategorySpaceLimited: PlayerStatCategories.defense,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Recoveries / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Recoveries",
        description: "Must be a short value. Stat for in a soccer game when a player recovers the ball from the other team",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Recoveries divided by total playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pFouls]: {
      statKey: SoccerStatKeysObj.pFouls,
      type: "player",
      tabCategory: PlayerStatCategories.defense,
      tabCategorySpaceLimited: PlayerStatCategories.defense,
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Fouls", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Fouls",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of fouls incurred.", serverLocale: locale })
    },

    [SoccerStatKeysObj.pFoulsPerHour]: {
      statKey: SoccerStatKeysObj.pFoulsPerHour,
      tabCategory: PlayerStatCategories.defense,
      tabCategorySpaceLimited: PlayerStatCategories.defense,
      type: "player",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Fouls / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Fouls",
        description: "Must be a short value. Foul infractions in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Fouls divided by total playing time.", serverLocale: locale })
    },

    [SoccerStatKeysObj.pOffsides]: {
      statKey: SoccerStatKeysObj.pOffsides,
      tabCategory: PlayerStatCategories.turnovers,
      type: "player",
      tabCategorySpaceLimited: PlayerStatCategories.turnovers,
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Offsides", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Offsides",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of offside infractions.", serverLocale: locale })
    },

    [SoccerStatKeysObj.pYellowCards]: {
      statKey: SoccerStatKeysObj.pYellowCards,
      tabCategory: PlayerStatCategories.defense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      type: "player",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 3,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Yellow Cards", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Yellow Cards",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of yellow cards incurred.", serverLocale: locale })
    },

    [SoccerStatKeysObj.pRedCards]: {
      statKey: SoccerStatKeysObj.pRedCards,
      tabCategory: PlayerStatCategories.defense,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      type: "player",
      statModes: [SoccerStatModes.team, SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 2,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Red Cards", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Red Cards",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of red cards incurred.", serverLocale: locale })
    },
    /*****
     *
     *  Turnovers
     *
     *
     */

    [SoccerStatKeysObj.pTurnovers]: {
      statKey: SoccerStatKeysObj.pTurnovers,
      type: "player",
      tabCategory: PlayerStatCategories.turnovers,
      tabCategorySpaceLimited: PlayerStatCategories.turnovers,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Turnovers", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Turnov.",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Total number of times the player lost possession. Includes bad throw-ins, passes, tackles, etc. Does not include losses of possession from crosses.",
        serverLocale: locale
      })
    },

    [SoccerStatKeysObj.pTurnoversPerHour]: {
      statKey: SoccerStatKeysObj.pTurnoversPerHour,
      tabCategory: PlayerStatCategories.turnovers,
      tabCategorySpaceLimited: PlayerStatCategories.turnovers,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Turnovers / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: `${translate({
        defaultMessage: "Turnov.",
        description: "Must be a short value. Short for turnovers in a soccer game",
        serverLocale: locale
      })} \n/${translate({
        defaultMessage: "Hour",
        description: "Unit of time, 60 minutes",
        serverLocale: locale
      })}`,
      prettyDefinition: translate({ defaultMessage: "Turnovers divided by total playing time.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pTurnoverPerc]: {
      statKey: SoccerStatKeysObj.pTurnoverPerc,
      tabCategory: PlayerStatCategories.turnovers,
      tabCategorySpaceLimited: PlayerStatCategories.turnovers,
      type: "player",
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Turnover %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Turnov. %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Turnovers divided by total touches", serverLocale: locale })
    },
    /*****
     *
     *  Keeper
     *
     *
     */
    [SoccerStatKeysObj.pShotsSaved]: {
      statKey: SoccerStatKeysObj.pShotsSaved,
      type: "player",
      tabCategory: PlayerStatCategories.keeper,
      tabCategorySpaceLimited: PlayerStatCategories.keeper,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 1,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Saves", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Saves",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Total number of times the goalkeeper prevented the ball from going into the net. Calculated from caught and blocked shots.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pShotsSavedPerHour]: {
      statKey: SoccerStatKeysObj.pShotsSavedPerHour,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "perHour",
      prettyName: translate({ defaultMessage: "Saves / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Saves / Hour",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Saves divided by total game time spent as goalkeeper.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pShotSavedPerc]: {
      statKey: SoccerStatKeysObj.pShotSavedPerc,
      type: "player",
      tabCategory: PlayerStatCategories.keeper,
      tabCategorySpaceLimited: PlayerStatCategories.keeper,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Shots Saved %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots Saved %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Saves divided by total shots on goal (while defending the goal).",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pSuccessfulGoalKickPerc]: {
      statKey: SoccerStatKeysObj.pSuccessfulGoalKickPerc,
      type: "player",
      tabCategory: PlayerStatCategories.keeper,
      tabCategorySpaceLimited: PlayerStatCategories.keeper,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "percent",
      displayPriority: 0,
      formatCategory: "percent",
      prettyName: translate({ defaultMessage: "Goal Kick Success %", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goal Kick Success %",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Percent of goal kicks that result in our team retaining possession.",
        serverLocale: locale
      })
    },

    [SoccerStatKeysObj.pShotsCaught]: {
      statKey: SoccerStatKeysObj.pShotsCaught,
      type: "player",
      tabCategory: PlayerStatCategories.keeper,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Caught Saves", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Caught Saves",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Saves from catches.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pShotsBlockedByGoalieSelf]: {
      statKey: SoccerStatKeysObj.pShotsBlockedByGoalieSelf,
      type: "player",
      tabCategory: PlayerStatCategories.keeper,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Blocked Saves", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Blocked Saves",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Saves from blocks.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pGoalKicks]: {
      statKey: SoccerStatKeysObj.pGoalKicks,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goal Kicks", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goal Kicks",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of goal kicks.", serverLocale: locale })
    },
    [SoccerStatKeysObj.pSuccessfulGoalKicks]: {
      statKey: SoccerStatKeysObj.pSuccessfulGoalKicks,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Successful Goal Kicks", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Successful Goal Kicks",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Goal kicks that result in our team retaining possession.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pShotsDefendedAsGK]: {
      statKey: SoccerStatKeysObj.pShotsDefendedAsGK,
      type: "player",
      tabCategory: PlayerStatCategories.keeper,
      tabCategorySpaceLimited: PlayerStatCategories.keeper,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shots Defended", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shots Defended",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Total number of shots defended as goalkeeper.", serverLocale: locale })
    },

    /*** Miscellaneous */
    [SoccerStatKeysObj.pQuickSelfTurnoversAndRecoveries]: {
      statKey: SoccerStatKeysObj.pQuickSelfTurnoversAndRecoveries,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({
        defaultMessage: "Self Initiated Turnover-Recovery",
        description: "Soccer Stat Label",
        serverLocale: locale
      }),
      prettyShortName: translate({
        defaultMessage: "Self Turnover-Recovery",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Turnovers that are immediately followed by a recovery by the same player.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pGameWinningGoals]: {
      statKey: SoccerStatKeysObj.pGameWinningGoals,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Game Winning Goals", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Game Winning Goals",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Awarded to the player scoring the goal that is one more than the opposing teamʼs final total score.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pPenaltyKicksScored]: {
      statKey: SoccerStatKeysObj.pPenaltyKicksScored,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Penalty Kicks Scored", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "PKs Scored",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Number of successfully scoring penalty kicks", serverLocale: locale })
    },
    [SoccerStatKeysObj.pPenaltyKicksAttempted]: {
      statKey: SoccerStatKeysObj.pPenaltyKicksAttempted,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({
        defaultMessage: "Penalty Kicks Attempted",
        description: "Soccer Stat Label",
        serverLocale: locale
      }),
      prettyShortName: translate({
        defaultMessage: "PKs Attempted",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Number of penalty kick attempts made", serverLocale: locale })
    },
    [SoccerStatKeysObj.pCornerKicks]: {
      statKey: SoccerStatKeysObj.pCornerKicks,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Corner Kicks", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Corner Kicks",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Number of corner kicks made", serverLocale: locale })
    },
    [SoccerStatKeysObj.pFieldPlayerPlayingTimeMS]: {
      statKey: SoccerStatKeysObj.pFieldPlayerPlayingTimeMS,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "ms",
      displayPriority: 1,
      formatCategory: "number",
      prettyName: translate({
        defaultMessage: "Field Player Playing Time",
        description: "Soccer Stat Label",
        serverLocale: locale
      }),
      prettyShortName: translate({
        defaultMessage: "Field Playing Time",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Amount of time played in a field position", serverLocale: locale })
    },
    [SoccerStatKeysObj.pGoaliePlayingTimeMS]: {
      statKey: SoccerStatKeysObj.pGoaliePlayingTimeMS,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "ms",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goalie Playing Time", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goalie Playing Time",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({ defaultMessage: "Amount of time played as a goalie", serverLocale: locale })
    },
    [SoccerStatKeysObj.pGoalsConcededPerHour]: {
      statKey: SoccerStatKeysObj.pGoalsConcededPerHour,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "ms",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goals Conceded / Hour", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goals Conceded\n/ Hour",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Number of goals scored on our team per hour while playing as a goalie.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pGoalieShutouts]: {
      statKey: SoccerStatKeysObj.pGoalieShutouts,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Shutouts", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Shutouts",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage:
          "Number of games where the opponent scored no goals and the player was goalie for at least 75% of the game.",
        serverLocale: locale
      })
    },
    [SoccerStatKeysObj.pGoalsConceded]: {
      statKey: SoccerStatKeysObj.pGoalsConceded,
      type: "player",
      tabCategory: PlayerStatCategories.hidden,
      tabCategorySpaceLimited: PlayerStatCategories.hidden,
      statModes: [SoccerStatModes.individual, SoccerStatModes.expert],
      stringFormatHint: "number",
      displayPriority: 0,
      formatCategory: "number",
      prettyName: translate({ defaultMessage: "Goals Conceded", description: "Soccer Stat Label", serverLocale: locale }),
      prettyShortName: translate({
        defaultMessage: "Goals Conceded",
        description: "Must be a short value. Soccer stat label.",
        serverLocale: locale
      }),
      prettyDefinition: translate({
        defaultMessage: "Number of goals scored on our team while playing as a goalie.",
        serverLocale: locale
      })
    }
  };
});

// i18n certified - complete

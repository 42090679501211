import { SoccerStatKeysObj, SoccerStatKeys, SoccerStatModes } from "@ollie-sports/models";

import { isDevelopmentEnv } from "../../internal-utils/misc";
import { StatComputation } from "./types";
import { GameSoccerStatComputations } from "./GameSoccerStatComputations";
import { TeamSoccerStatComputations } from "./TeamSoccerStatComputations";
import { PlayerSoccerStatComputations } from "./PlayerSoccerStatComputations";
import { PositionNumSoccerStatComputations } from "./PositionNumSoccerStatComputations";

export const SoccerStatComputations: Record<SoccerStatKeys, StatComputation> = {
  ...GameSoccerStatComputations,
  ...TeamSoccerStatComputations,
  ...PlayerSoccerStatComputations,
  ...PositionNumSoccerStatComputations
};

if (isDevelopmentEnv()) {
  Object.keys(SoccerStatComputations).forEach(key => {
    const comp = SoccerStatComputations[key as SoccerStatKeys];
    const hasReduce = "reduce" in comp;
    const hasComplexReduce = "complexReduce" in comp;
    const hasCompute = "compute" in comp;
    const hasComputeStageTwo = "computeStageTwo" in comp;

    if ([hasReduce, hasCompute, hasComputeStageTwo, hasComplexReduce].filter(a => a).length !== 1) {
      throw new Error(`Invalid computation defined for ${key}`);
    }

    if (comp.statKey !== key) {
      throw new Error(
        `There is a mismatch between the statKey field of ${comp.statKey} and the record id of ${key} in your computation definitions. There is no way to verify this statically, so this is your warning.`
      );
    }
  });
}

export * from "./types";

// i18n certified - complete

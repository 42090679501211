import {
  SoccerGameEventType,
  SoccerGameEvent,
  SGE_forfeitInfractionPK,
  SoccerIds,
  SGE_shot,
  SGE_shotWithGoal,
  SGE_ownTeamGoal,
  SGE_stopHalf,
  SUB_OUT_MAGIC_CONSTANT,
  SoccerGame,
  SGE_substitute,
  SoccerPositionNumber,
  SGE_shotWithGoalManualEntry,
  SoccerStatModes,
  PlayerId,
  SGE_shotShootoutPK,
  ShotResultType,
  SoccerGameEventTypeToSGE
} from "@ollie-sports/models";
import { ConvenienceSoccerGamePlayer, GET_STOCK_PLAYERS } from "./SoccerGamePlayerRoster";
import { computeSoccerPossessionInfo } from "./SoccerPossession";
import {
  isOpponentPlayer,
  arePlayersSameTeam,
  getPlayerTeamId,
  isOpponentTeamId,
  isFieldLocationInsidePlayerPenaltyBox,
  isPlayerGeneric
} from "./SoccerFns";
import _ from "lodash";
import { isDevelopmentEnv } from "../internal-utils/misc";
import { ObjectKeys } from "../utils/object-keys";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { combineArrayWithCommasAndAnd } from "../utils";
import { computeSoccerGameScore } from "./SoccerGameMiscLogic";
import { SoccerPauseEvent, SoccerResumeEvent } from "./SoccerEventCategories";
import { translate } from "@ollie-sports/i18n";

type Args<T> = {
  completeRoster: ConvenienceSoccerGamePlayer[];
  thisEvent: T;
  events: SoccerGameEvent[];
  player?: ConvenienceSoccerGamePlayer;
  verbose?: boolean;
  soccerGame?: SoccerGame;
  memoryDB: SoccerEventMemoryDB;
  thisEventIndex: number;
  locale: string;
};

const getSoccerEventDescription = _.memoize(
  (
    locale: string
  ): {
    [type in SoccerGameEventType]: (a: Args<SoccerGameEventTypeToSGE[type]>) => string;
  } => {
    return {
      [SoccerGameEventType.stab]: () => "",
      [SoccerGameEventType.header]: () => "",
      [SoccerGameEventType.missedPassTarget]: () => "",
      [SoccerGameEventType.goaliePunt]: () => "",
      [SoccerGameEventType.goalieThrow]: () => "",
      [SoccerGameEventType.basicTouch]: p => {
        if (p.thisEventIndex > 0) {
          const previousEvent = p.events[p.thisEventIndex - 1];
          if (
            previousEvent &&
            previousEvent.type === SoccerGameEventType.shot &&
            previousEvent.shotResultType === ShotResultType.goalieShotCatch
          ) {
            return computeLastSoccerEventDescription({ ...p, eventIndex: p.thisEventIndex - 1 });
          }
        }
        const { possessingPlayerId, previouslyPossessingPlayerId } = computeSoccerPossessionInfo(p.events);

        const player = possessingPlayerId ? findPlayerWithFallback(possessingPlayerId, p.completeRoster, locale) : null;
        const prevPlayer = previouslyPossessingPlayerId
          ? findPlayerWithFallback(previouslyPossessingPlayerId, p.completeRoster, locale)
          : null;

        if ("includeInTimeline" in p.thisEvent && p.verbose && p.thisEvent.type === SoccerGameEventType.basicTouch) {
          const whyIncludeInTimelineString =
            p.thisEvent.whyIncludedInTimeline === "corner"
              ? "corner"
              : p.thisEvent.whyIncludedInTimeline === "free-kick"
              ? "free kick"
              : "cross";
          return whyIncludeInTimelineString === "corner"
            ? translate(
                {
                  defaultMessage: `{playerName} controlled the ball after a corner kick.`,
                  serverLocale: locale
                },
                {
                  playerName: `*${
                    player ? player.firstNamePlus : translate({ defaultMessage: "A Player", serverLocale: locale })
                  }*`
                }
              )
            : whyIncludeInTimelineString === "cross"
            ? translate(
                {
                  defaultMessage: `{playerName} controlled the ball after a cross.`,
                  serverLocale: locale
                },
                {
                  playerName: `*${
                    player ? player.firstNamePlus : translate({ defaultMessage: "A Player", serverLocale: locale })
                  }*`
                }
              )
            : translate(
                {
                  defaultMessage: `{playerName} controlled the ball after a free kick.`,
                  serverLocale: locale
                },
                {
                  playerName: `*${
                    player ? player.firstNamePlus : translate({ defaultMessage: "A Player", serverLocale: locale })
                  }*`
                }
              );
        }

        if (player && prevPlayer) {
          if (player.id === prevPlayer.id) {
            return translate(
              { defaultMessage: `{prevPlayerName} passed the ball.`, serverLocale: locale },
              { prevPlayerName: `*${prevPlayer.firstNamePlus}*` }
            );
          } else if (arePlayersSameTeam(player, prevPlayer)) {
            return translate(
              { defaultMessage: `{prevPlayerName} passed the ball to {playerName}.`, serverLocale: locale },
              { prevPlayerName: `*${prevPlayer.firstNamePlus}*`, playerName: `*${player.firstNamePlus}*` }
            );
          } else {
            return translate(
              { defaultMessage: `{playerName} recovered the ball from {prevPlayerName}.`, serverLocale: locale },
              { playerName: `*${player.firstNamePlus}*`, prevPlayerName: `*${prevPlayer.firstNamePlus}*` }
            );
          }
        } else if (player) {
          return translate(
            { defaultMessage: `{playerName} got the ball.`, serverLocale: locale },
            { playerName: `*${player.firstNamePlus}*` }
          );
        }

        console.error("Problem computing soccer event description for basic touch.");

        return translate({ defaultMessage: "A touch occurred.", serverLocale: locale });
      },
      [SoccerGameEventType.cross]: p =>
        translate(
          { defaultMessage: `{playerName} crossed the ball.`, serverLocale: locale },
          { playerName: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.offsideInfraction]: p =>
        translate(
          { defaultMessage: `{playerName} was offside.`, serverLocale: locale },
          { playerName: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.foulInfraction]: p => {
        const foulEvent = p.thisEvent;
        return foulEvent.didResultInPK
          ? translate(
              { defaultMessage: `{name} committed a foul and a penalty kick was awarded.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            )
          : translate(
              { defaultMessage: `{name} committed a foul.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
      },
      [SoccerGameEventType.handballInfraction]: p => {
        const foulEvent = p.thisEvent;
        return foulEvent.didResultInPK
          ? translate(
              { defaultMessage: `{name} handled the ball and a penalty kick was awarded.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            )
          : translate(
              { defaultMessage: `{name} handled the ball.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
      },
      [SoccerGameEventType.yellowCardInfraction]: p =>
        translate(
          { defaultMessage: `{name} received a yellow card.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.redCardInfraction]: p =>
        translate(
          { defaultMessage: `{name} received a red card.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.goalKickLong]: p =>
        translate(
          { defaultMessage: `{name} cleared the ball off a goal kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.goalKickPass]: p =>
        translate(
          { defaultMessage: `{name} passed off of a goal kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.clearance]: p => {
        return p.thisEvent.type === SoccerGameEventType.clearance && p.verbose && "includeInTimeline" in p.thisEvent
          ? p.thisEvent.whyIncludedInTimeline === "corner"
            ? translate(
                { defaultMessage: `{name} cleared the ball after a corner kick.`, serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              )
            : p.thisEvent.whyIncludedInTimeline === "cross"
            ? translate(
                { defaultMessage: `{name} cleared the ball after a cross.`, serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              )
            : translate(
                { defaultMessage: `{name} cleared the ball after a free kick.`, serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              )
          : translate(
              { defaultMessage: `{name} cleared the ball.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
      },
      [SoccerGameEventType.freeKickPass]: p =>
        translate(
          { defaultMessage: `{name} passed off of a free kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.freeKickCross]: p =>
        translate(
          { defaultMessage: `{name} crossed the ball off a free kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.freeKickLong]: p =>
        translate(
          { defaultMessage: `{name} took a long free kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.dribble]: p =>
        translate(
          { defaultMessage: `{name} dribbled an opponent.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.throwIn]: p =>
        translate(
          { defaultMessage: `{name} threw the ball in.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.throwInInfraction]: p =>
        translate(
          { defaultMessage: `{name} was called for an illegal throw-in.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.cornerKickShort]: p =>
        translate(
          { defaultMessage: `{name} passed off of a corner kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.cornerKickCross]: p =>
        translate(
          { defaultMessage: `{name} crossed the ball off a corner kick.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.substitute]: p => {
        let substitutionString = translate({ defaultMessage: "Substitutions were made.", serverLocale: locale });
        if (
          p.verbose &&
          p.thisEvent.type === SoccerGameEventType.substitute &&
          p.thisEvent.playerIdToNewPosition &&
          p.thisEvent.playerIdToOldPosition
        ) {
          const detailedString = generateDetailedSubsitutionEventDescription({
            completeRoster: p.completeRoster,
            playerIdToNewPosition: p.thisEvent.playerIdToNewPosition,
            playerIdToOldPosition: p.thisEvent.playerIdToOldPosition,
            queued: false,
            locale
          });
          substitutionString = substitutionString + detailedString;
        }
        return substitutionString;
      },
      [SoccerGameEventType.startHalf]: p => {
        let startingLineupString = "";
        // If the event starts the game and we pass in verbose and a soccer game, include the starting lineup for the timeline
        if (
          p.verbose &&
          p.thisEvent.type === SoccerGameEventType.startHalf &&
          p.thisEvent.startsGame &&
          p.soccerGame &&
          p.soccerGame.starterIdToPosition &&
          p.soccerGame.statMode !== SoccerStatModes.team
          // SCOTT YOU NEED TO FIX THIS
          // && ObjectKeys(p.soccerGame.starterIdToPosition).length
        ) {
          startingLineupString = `\n\n*${translate({
            defaultMessage: "Starters",
            description: "The players starting on the field at the start of the game",
            serverLocale: locale
          })}:* ${ObjectKeys(p.soccerGame.starterIdToPosition ?? {})
            .map(pId => {
              const player = findPlayerWithFallback(pId as any, p.completeRoster, locale);
              return player ? player.fullName : "";
            })
            .join(", ")}.`;
        }

        const allStartHalfEvents = p.memoryDB.presets.clockEvents().filter(e => e.type === SoccerGameEventType.startHalf);
        const thisEventStartHalfIndex = allStartHalfEvents.findIndex(e => e.id === p.thisEvent.id);

        return `${
          p.verbose
            ? thisEventStartHalfIndex === 0
              ? translate(
                  { defaultMessage: `{name} took the kickoff to start the first half!`, serverLocale: locale },
                  { name: `*${p.player?.firstNamePlus}*` }
                )
              : thisEventStartHalfIndex === 1
              ? translate(
                  { defaultMessage: `{name} took the kickoff to start the second half!`, serverLocale: locale },
                  { name: `*${p.player?.firstNamePlus}*` }
                )
              : thisEventStartHalfIndex === 2
              ? translate(
                  { defaultMessage: `{name} took the kickoff to start the first overtime!`, serverLocale: locale },
                  { name: `*${p.player?.firstNamePlus}*` }
                )
              : thisEventStartHalfIndex === 3
              ? translate(
                  { defaultMessage: `{name} took the kickoff to start the second overtime!`, serverLocale: locale },
                  { name: `*${p.player?.firstNamePlus}*` }
                )
              : translate(
                  { defaultMessage: `{name} took the kickoff!`, serverLocale: locale },
                  { name: `*${p.player?.firstNamePlus}*` }
                )
            : translate(
                { defaultMessage: `{name} took the kickoff!`, serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              )
        }${startingLineupString}`;
      },
      [SoccerGameEventType.stopHalf]: p => {
        const thisEvent = p.thisEvent as SGE_stopHalf;
        const { endsGame } = thisEvent;
        const { ownTeam, opponentTeam } = computeSoccerGameScore({ goalEvents: p.memoryDB.presets.goalEvents() });
        let gameResultMsg = "";
        if (thisEvent.type === SoccerGameEventType.stopHalf && thisEvent.endsGame) {
          gameResultMsg =
            ownTeam > opponentTeam
              ? translate({ defaultMessage: "The game ended. Your team won!", serverLocale: locale })
              : opponentTeam > ownTeam
              ? translate({ defaultMessage: "The game ended. Your team lost.", serverLocale: locale })
              : !thisEvent.pkScore
              ? translate({ defaultMessage: "The game ended in a tie.", serverLocale: locale })
              : "";
          const pkScore = thisEvent.pkScore;
          const pkMessage = pkScore
            ? pkScore.ownTeam > pkScore.opponentTeam
              ? ` ${translate({ defaultMessage: "Your team won in a shootout", serverLocale: locale })} (${pkScore.ownTeam}-${
                  pkScore.opponentTeam
                })`
              : ` ${translate({ defaultMessage: "Your team lost in a shootout", serverLocale: locale })} (${pkScore.ownTeam}-${
                  pkScore.opponentTeam
                })`
            : "";
          gameResultMsg = gameResultMsg + pkMessage;
        }

        const stopHalfActionIndex = _.findIndex(
          p.memoryDB.presets.clockEvents().filter(e => e.type === SoccerGameEventType.stopHalf),
          thisEvent
        );
        const specificHalfString =
          stopHalfActionIndex === 0
            ? translate({ defaultMessage: "The first half ended.", serverLocale: locale })
            : stopHalfActionIndex === 1
            ? translate({ defaultMessage: "The second half ended.", serverLocale: locale })
            : stopHalfActionIndex === 2
            ? translate({ defaultMessage: "The first overtime ended.", serverLocale: locale })
            : stopHalfActionIndex === 3
            ? translate({ defaultMessage: "The second overtime ended.", serverLocale: locale })
            : "";
        return endsGame ? gameResultMsg : specificHalfString;
      },
      [SoccerGameEventType.postGoalKickoff]: p =>
        translate({ defaultMessage: `{name} took the kickoff!`, serverLocale: locale }, { name: `*${p.player?.firstNamePlus}*` }),
      [SoccerGameEventType.startOfficialStoppage]: p => {
        if (p.thisEvent.reason === "game-delay") {
          return translate({ defaultMessage: "The ref called an official game delay.", serverLocale: locale });
        } else if (p.thisEvent.reason === "manual-main-official-stoppage") {
          return translate({ defaultMessage: "The ref interrupted play with an official stoppage.", serverLocale: locale });
        } else {
          return computeLastSoccerEventDescription({ ...p, eventIndex: p.thisEventIndex - 1, locale });
        }
      },
      [SoccerGameEventType.pauseGameAndStopClock]: p => {
        if (p.thisEvent.reason === "game-delay") {
          return translate({ defaultMessage: "The ref called an official game delay.", serverLocale: locale });
        } else if (p.thisEvent.reason === "manual-main-official-stoppage") {
          return translate({ defaultMessage: "The ref interrupted play with an official stoppage.", serverLocale: locale });
        } else {
          return computeLastSoccerEventDescription({ ...p, eventIndex: p.thisEventIndex - 1, locale });
        }
      },
      [SoccerGameEventType.resumeGameOnRunningClock]: getResumeEventDescription,
      [SoccerGameEventType.resumeGameOnStoppedClock]: getResumeEventDescription,
      [SoccerGameEventType.stopOfficialStoppageWithDropBall]: getResumeEventDescription,
      [SoccerGameEventType.resumeGameWithDropBallOnStoppedClock]: getResumeEventDescription,
      [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: getResumeEventDescription,
      [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: getResumeEventDescription,
      [SoccerGameEventType.outOfBounds]: p =>
        translate(
          { defaultMessage: `{name} kicked the ball out of bounds.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.looseBallOutOfBounds]: () =>
        translate({ defaultMessage: `A loose ball went out of bounds.`, serverLocale: locale }),
      [SoccerGameEventType.tackle]: p =>
        translate(
          { defaultMessage: `{name} tackled for possession.`, serverLocale: locale },
          { name: `*${p.player?.firstNamePlus}*` }
        ),
      [SoccerGameEventType.shot]: p => {
        const shotEvent = p.thisEvent as SGE_shot;
        let shotString = ``;

        const postfixStr =
          shotEvent.shotReason === "free-kick"
            ? " off a free kick"
            : shotEvent.shotReason === "infraction-pk"
            ? " off a penalty kick"
            : "";

        let shotResultString = ".";
        const defendingGoalie = shotEvent.defendingGoaliePlayerId
          ? p.completeRoster.find(pl => pl.id === shotEvent.defendingGoaliePlayerId)
          : undefined;
        switch (shotEvent.shotResultType) {
          case ShotResultType.goalieShotBlock:
            if (shotEvent.shotReason === "free-kick") {
              shotString =
                isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                  ? translate(
                      {
                        defaultMessage: "{name} took a shot off a free kick but it was blocked by {gkName}.",
                        serverLocale: locale
                      },
                      { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                    )
                  : translate(
                      { defaultMessage: "{name} took a shot off a free kick but it was blocked.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*` }
                    );
            } else if (shotEvent.shotReason === "infraction-pk") {
              shotString =
                isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                  ? translate(
                      {
                        defaultMessage: "{name} took a shot off a penalty kick but it was blocked by {gkName}.",
                        serverLocale: locale
                      },
                      { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                    )
                  : translate(
                      { defaultMessage: "{name} took a shot off a penalty kick but it was blocked.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*` }
                    );
            } else {
              shotString =
                isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                  ? translate(
                      { defaultMessage: "{name} took a shot but it was blocked by {gkName}.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                    )
                  : translate(
                      { defaultMessage: "{name} took a shot but it was blocked.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*` }
                    );
            }
            break;
          case ShotResultType.goalieShotCatch:
            if (shotEvent.shotReason === "free-kick") {
              shotString =
                isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                  ? translate(
                      {
                        defaultMessage: "{name} took a shot off a free kick but it was caught by {gkName}.",
                        serverLocale: locale
                      },
                      { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                    )
                  : translate(
                      { defaultMessage: "{name} took a shot off a free kick but it was caught.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*` }
                    );
            } else if (shotEvent.shotReason === "infraction-pk") {
              shotString =
                isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                  ? translate(
                      {
                        defaultMessage: "{name} took a shot off a penalty kick but it was caught by {gkName}.",
                        serverLocale: locale
                      },
                      { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                    )
                  : translate(
                      { defaultMessage: "{name} took a shot off a penalty kick but it was caught.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*` }
                    );
            } else {
              shotString =
                isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                  ? translate(
                      { defaultMessage: "{name} took a shot but it was caught by {gkName}.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                    )
                  : translate(
                      { defaultMessage: "{name} took a shot but it was caught.", serverLocale: locale },
                      { name: `*${p.player?.firstNamePlus}*` }
                    );
            }
            break;
          case ShotResultType.offTargetShotMiss:
            if (shotEvent.shotReason === "free-kick") {
              shotString = translate(
                { defaultMessage: "{name} took a shot off a free kick but it was off target.", serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              );
            } else if (shotEvent.shotReason === "infraction-pk") {
              shotString = translate(
                { defaultMessage: "{name} took a shot off a penalty kick but it was off target.", serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              );
            } else {
              shotString = translate(
                { defaultMessage: "{name} took a shot but it was off target.", serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              );
            }
            break;
          case ShotResultType.fieldPlayerShotBlock:
            if (shotEvent.shotReason === "free-kick") {
              shotString = translate(
                {
                  defaultMessage: "{name} took a shot off a free kick but it was blocked by a field player.",
                  serverLocale: locale
                },
                { name: `*${p.player?.firstNamePlus}*` }
              );
            } else if (shotEvent.shotReason === "infraction-pk") {
              shotString = translate(
                {
                  defaultMessage: "{name} took a shot off a penalty kick but it was blocked by a field player.",
                  serverLocale: locale
                },
                { name: `*${p.player?.firstNamePlus}*` }
              );
            } else {
              shotString = translate(
                { defaultMessage: "{name} took a shot but it was blocked by a field player.", serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              );
            }
            break;
        }

        return shotString;
      },
      [SoccerGameEventType.shotWithGoalManualEntry]: p => {
        const { assistingPlayerId, playerTeamId } = p.thisEvent as SGE_shotWithGoalManualEntry;

        if ("idOfPlayerWhoShotOwnGoal" in p.thisEvent && p.thisEvent.idOfPlayerWhoShotOwnGoal) {
          const shootingPlayer = findPlayerWithFallback(p.thisEvent.idOfPlayerWhoShotOwnGoal, p.completeRoster, locale);
          return translate(
            { defaultMessage: `{name} scored on their own goal.`, serverLocale: locale },
            { name: `*${shootingPlayer?.firstNamePlus}*` }
          );
        }

        const assistingPlayer = assistingPlayerId
          ? findPlayerWithFallback(assistingPlayerId, p.completeRoster, locale)
          : undefined;

        if (isOpponentTeamId(playerTeamId)) {
          return translate({ defaultMessage: "{name} scored.", serverLocale: locale }, { name: `*${p.player?.firstNamePlus}*` });
        }

        if (assistingPlayer) {
          if (isPlayerGeneric(assistingPlayer) && !isOpponentPlayer(assistingPlayer)) {
            return translate(
              { defaultMessage: "{name} scored with an assist from a teammate!", serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
          } else {
            return translate(
              { defaultMessage: "{name} scored with {assistName} assisting!", serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*`, assistName: `*${assistingPlayer.firstNamePlus}*` }
            );
          }
        }

        return translate({ defaultMessage: "{name} scored!", serverLocale: locale }, { name: `*${p.player?.firstNamePlus}*` });
      },
      [SoccerGameEventType.shotWithGoal]: p => {
        const { shotReason, assistingPlayerId, playerTeamId } = p.thisEvent as SGE_shotWithGoal;

        const assistingPlayer = assistingPlayerId
          ? findPlayerWithFallback(assistingPlayerId, p.completeRoster, locale)
          : undefined;

        if (isOpponentTeamId(playerTeamId)) {
          return shotReason === "free-kick"
            ? translate(
                { defaultMessage: "{name} scored off a free kick.", serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              )
            : shotReason === "infraction-pk"
            ? translate(
                { defaultMessage: "{name} scored off a penalty kick.", serverLocale: locale },
                { name: `*${p.player?.firstNamePlus}*` }
              )
            : translate({ defaultMessage: "{name} scored.", serverLocale: locale }, { name: `*${p.player?.firstNamePlus}*` });
        }

        if (assistingPlayer) {
          if (isPlayerGeneric(assistingPlayer) && !isOpponentPlayer(assistingPlayer)) {
            // Can't have an assist of a free kick or penalty kick so no need to check for free kick or penalty kick here
            return translate(
              { defaultMessage: "{name} scored with an assist from a teammate!", serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
          } else {
            return translate(
              // Can't have an assist of a free kick or penalty kick so no need to check for free kick or penalty kick here
              { defaultMessage: "{name} scored with {assistName} assisting!", serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*`, assistName: `*${assistingPlayer.firstNamePlus}*` }
            );
          }
        }

        return shotReason === "free-kick"
          ? translate(
              { defaultMessage: "{name} scored off a free kick!", serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            )
          : shotReason === "infraction-pk"
          ? translate(
              { defaultMessage: "{name} scored off a penalty kick!", serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            )
          : translate({ defaultMessage: "{name} scored!", serverLocale: locale }, { name: `*${p.player?.firstNamePlus}*` });
      },
      [SoccerGameEventType.shotShootoutPK]: p => {
        const shotEvent = p.thisEvent as SGE_shotShootoutPK;
        let shotString = "";
        const defendingGoalie = shotEvent.defendingGoaliePlayerId
          ? p.completeRoster.find(pl => pl.id === shotEvent.defendingGoaliePlayerId)
          : undefined;
        switch (shotEvent.shotResultType) {
          case ShotResultType.goalieShotBlock:
            shotString =
              isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                ? translate(
                    { defaultMessage: `{name} took a penalty kick but it was blocked by {gkName}.`, serverLocale: locale },
                    { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                  )
                : translate(
                    { defaultMessage: `{name} took a penalty kick but it was blocked.`, serverLocale: locale },
                    { name: `*${p.player?.firstNamePlus}*` }
                  );
            break;
          case ShotResultType.goalieShotCatch:
            shotString =
              isOpponentTeamId(shotEvent.playerTeamId) && defendingGoalie
                ? translate(
                    { defaultMessage: `{name} took a penalty kick but it was caught by {gkName}.`, serverLocale: locale },
                    { name: `*${p.player?.firstNamePlus}*`, gkName: `${defendingGoalie.firstNamePlus}` }
                  )
                : translate(
                    { defaultMessage: `{name} took a penalty kick but it was caught.`, serverLocale: locale },
                    { name: `*${p.player?.firstNamePlus}*` }
                  );
            break;
          case ShotResultType.offTargetShotMiss:
            shotString = translate(
              { defaultMessage: `{name} took a penalty kick but it was off target.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
            break;
          case ShotResultType.fieldPlayerShotBlock:
            shotString = translate(
              { defaultMessage: `{name} took a penalty kick but it was blocked by a field player.`, serverLocale: locale },
              { name: `*${p.player?.firstNamePlus}*` }
            );
            break;
        }
        return shotString;
      },
      [SoccerGameEventType.ownTeamGoal]: p => {
        const { idOfPlayerWhoShotOwnGoal } = p.thisEvent as SGE_ownTeamGoal;
        const shootingPlayer = findPlayerWithFallback(idOfPlayerWhoShotOwnGoal, p.completeRoster, locale);
        return translate(
          { defaultMessage: `{name} scored on their own goal.`, serverLocale: locale },
          { name: `*${shootingPlayer?.firstNamePlus}*` }
        );
      },
      [SoccerGameEventType.forfeitInfractionPK]: p => {
        const event = p.thisEvent as SGE_forfeitInfractionPK;
        return translate(
          { defaultMessage: `{name} forfeited their penalty kick.`, serverLocale: locale },
          { name: `*${getTeamGenericName(event.playerTeamId, locale)}*` }
        );
      },
      [SoccerGameEventType.switchFormation]: evt =>
        translate(
          { defaultMessage: `The formation was changed to {formation}.`, serverLocale: locale },
          { formation: `${evt.thisEvent.newFormation.replace(/^1-/, "")}` }
        )
    };
  }
);

export function computeLastSoccerEventDescription(p: {
  completeRoster: ConvenienceSoccerGamePlayer[];
  eventIndex: number;
  memoryDB: SoccerEventMemoryDB;
  verbose?: boolean;
  soccerGame?: SoccerGame;
  locale: string;
}) {
  //This not obvious but if thisEventIndex is defined, get the description of the prior event

  const events = p.memoryDB.getRawEvents().slice(0, p.eventIndex + 1);
  if (!events.length) {
    return "";
  }

  const lastEvent = events.slice(-1).pop() as SoccerGameEvent;

  let player;
  if ("playerId" in lastEvent) {
    player = findPlayerWithFallback(lastEvent.playerId, p.completeRoster, p.locale);
  }

  const evt: any = {
    events: events,
    completeRoster: p.completeRoster,
    thisEvent: lastEvent,
    player,
    verbose: p.verbose,
    soccerGame: p.soccerGame,
    memoryDB: p.memoryDB,
    thisEventIndex: p.eventIndex
  };

  return getSoccerEventDescription(p.locale)[lastEvent.type](evt);
}

function getTeamGenericName(teamId: string, locale: string) {
  if (teamId === SoccerIds.opponentTeamId) {
    return GET_STOCK_PLAYERS(locale).opponentGenericPlayer.firstNamePlus;
  } else {
    return GET_STOCK_PLAYERS(locale).ownTeamGenericPlayer(teamId).firstNamePlus;
  }
}

export function findPlayerWithFallback(
  playerId: string,
  players: ConvenienceSoccerGamePlayer[],
  locale: string
): ConvenienceSoccerGamePlayer | undefined {
  const { ownTeamGenericPlayer: ownTeamPlayerGetter, ...opponentPlayers } = GET_STOCK_PLAYERS(locale);
  const ownTeamPlayer = ownTeamPlayerGetter("TEAM ID DOES NOT MATTER IN THIS FILE");
  let player = players.concat(Object.values(opponentPlayers).concat(ownTeamPlayer)).find(pl => pl.id === playerId);

  if (!player) {
    if (isDevelopmentEnv()) {
      console.error(
        `Attempted to find a player for player id ${playerId} but was not found in complete roster. Logic is bad somewhere.`
      );
    }
    player = ownTeamPlayer;
  }

  return player;
}

export function generateDetailedSubsitutionEventDescription(p: {
  playerIdToOldPosition: {
    [x: string]: SoccerPositionNumber | "SUB_OUT_MAGIC_CONSTANT" | undefined;
  };
  playerIdToNewPosition: {
    [x: string]: SoccerPositionNumber | "SUB_OUT_MAGIC_CONSTANT" | undefined;
  };
  completeRoster: ConvenienceSoccerGamePlayer[];
  queued: boolean;
  locale: string;
}) {
  let detailedString = "";
  const playersSubbingIn: string[] = ObjectKeys(p.playerIdToOldPosition).filter(pId => {
    if (p.playerIdToOldPosition[pId] === SUB_OUT_MAGIC_CONSTANT) {
      return true;
    } else {
      return false;
    }
  }) as string[];
  const playersSubbingOut: string[] = ObjectKeys(p.playerIdToOldPosition).filter(pId => {
    if (p.playerIdToNewPosition[pId] === SUB_OUT_MAGIC_CONSTANT) {
      return true;
    } else {
      return false;
    }
  }) as string[];
  const playersChangingPositions: string[] = ObjectKeys(p.playerIdToOldPosition).filter(pId => {
    if (p.playerIdToOldPosition[pId] !== SUB_OUT_MAGIC_CONSTANT && p.playerIdToNewPosition[pId] !== SUB_OUT_MAGIC_CONSTANT) {
      return true;
    } else {
      return false;
    }
  }) as string[];

  const playersAccountedFor: PlayerId[] = [];

  playersSubbingIn.forEach(id => {
    const playerIn = findPlayerWithFallback(id, p.completeRoster, p.locale);
    const newPosition = p.playerIdToNewPosition[id];
    if (playerIn && newPosition) {
      let playerIdWhoSubbedOut: string | undefined = undefined;
      let numberOfIterations = 0;
      let nextPositionToCheck = newPosition;
      while (!playerIdWhoSubbedOut && numberOfIterations < 5) {
        const playerIdAtNextPositionToCheck: string | undefined = ObjectKeys(p.playerIdToOldPosition ?? {}).find(
          pid => p.playerIdToOldPosition?.[pid] === nextPositionToCheck
        ) as string | undefined;
        if (playerIdAtNextPositionToCheck) {
          const newPositionOfPlayer = p.playerIdToNewPosition[playerIdAtNextPositionToCheck];
          if (newPositionOfPlayer) {
            if (newPositionOfPlayer === "SUB_OUT_MAGIC_CONSTANT") {
              playerIdWhoSubbedOut = playerIdAtNextPositionToCheck;
            } else {
              nextPositionToCheck = newPositionOfPlayer;
            }
          }
        }
        numberOfIterations++;
      }
      playersAccountedFor.push(playerIn.id);
      if (playerIdWhoSubbedOut) {
        const playerOut = findPlayerWithFallback(playerIdWhoSubbedOut, p.completeRoster, p.locale);
        if (playerOut) {
          detailedString = `${detailedString}\n${
            p.queued
              ? translate(
                  {
                    defaultMessage: `{playerInName} subbing in for {playerOutName}`,
                    serverLocale: p.locale
                  },
                  {
                    playerInName: `*${playerIn.firstNamePlus}*`,
                    playerOutName: `*${playerOut.firstNamePlus}*`
                  }
                )
              : translate(
                  {
                    defaultMessage: `{playerInName} subbed in for {playerOutName}`,
                    serverLocale: p.locale
                  },
                  {
                    playerInName: `*${playerIn.firstNamePlus}*`,
                    playerOutName: `*${playerOut.firstNamePlus}*`
                  }
                )
          }`;
          playersAccountedFor.push(playerOut.id);
        }
      } else {
        detailedString = `${detailedString}\n${
          p.queued
            ? translate(
                {
                  defaultMessage: `{playerInName} subbing in`,
                  serverLocale: p.locale
                },
                {
                  playerInName: `*${playerIn.firstNamePlus}*`
                }
              )
            : translate(
                {
                  defaultMessage: `{playerInName} subbed in`,
                  serverLocale: p.locale
                },
                {
                  playerInName: `*${playerIn.firstNamePlus}*`
                }
              )
        }`;
      }
    }
  });

  playersSubbingOut
    .filter(playerId => !playersAccountedFor.includes(playerId))
    .forEach(id => {
      const playerOut = findPlayerWithFallback(id, p.completeRoster, p.locale);
      if (playerOut) {
        detailedString = `${detailedString}\n${
          p.queued
            ? translate(
                {
                  defaultMessage: `{playerInName} subbing out`,
                  serverLocale: p.locale
                },
                {
                  playerInName: `*${playerOut.firstNamePlus}*`
                }
              )
            : translate(
                {
                  defaultMessage: `{playerInName} subbed out`,
                  serverLocale: p.locale
                },
                {
                  playerInName: `*${playerOut.firstNamePlus}*`
                }
              )
        }`;
      }
    });
  const playersChangingPositionsNames: string[] = playersChangingPositions
    .map(pid => {
      const player = findPlayerWithFallback(pid, p.completeRoster, p.locale);
      return player ? player.firstNamePlus : null;
    })
    .filter(pl => pl !== null) as string[];
  if (playersChangingPositionsNames.length) {
    detailedString = `${detailedString}\n${
      p.queued
        ? translate(
            { defaultMessage: `{playerNames} changing positions`, serverLocale: p.locale },
            {
              playerNames: `${combineArrayWithCommasAndAnd(
                playersChangingPositionsNames.map(n => `*${n}*`),
                p.locale
              )}`
            }
          )
        : translate(
            { defaultMessage: `{playerNames} changed positions`, serverLocale: p.locale },
            {
              playerNames: `${combineArrayWithCommasAndAnd(
                playersChangingPositionsNames.map(n => `*${n}*`),
                p.locale
              )}`
            }
          )
    }.`;
  }

  return detailedString;
}

function getResumeEventDescription<T extends SoccerResumeEvent>(p: Args<T>): string {
  const withStartingPlayer =
    p.thisEvent.type === SoccerGameEventType.stopOfficialStoppageWithStartingPlayer ||
    p.thisEvent.type === SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock;

  const withDroppedBall =
    p.thisEvent.type === SoccerGameEventType.stopOfficialStoppageWithDropBall ||
    p.thisEvent.type === SoccerGameEventType.resumeGameWithDropBallOnStoppedClock;

  if (withStartingPlayer) {
    return translate(
      { defaultMessage: `{name} was given the ball to resume play.`, serverLocale: p.locale },
      { name: `*${p.player?.firstNamePlus}*` }
    );
  }

  if (withDroppedBall) {
    return translate({ defaultMessage: `Play resumed with a dropped ball.`, serverLocale: p.locale });
  }

  return computeLastSoccerEventDescription({
    ...p,
    eventIndex: p.thisEventIndex - 1
  });
}

// i18n certified - complete

import { SoccerStatKeysObj, SoccerStatKeys, SoccerStatModes } from "@ollie-sports/models";

import { getPlayerSoccerStatDefs, PlayerStatDef } from "./PlayerSoccerStatDefs";
import { getTeamSoccerStatDefs, TeamStatDef } from "./TeamSoccerStatDefs";
import { GameStatDef, getGameSoccerStatDefs } from "./GameSoccerStatDefs";
import { getPositionNumSoccerStatDefs, PositionNumStatDef } from "./PositionNumSoccerStatDefs";

export * from "./PlayerSoccerStatDefs";
export * from "./TeamSoccerStatDefs";
export * from "./GameSoccerStatDefs";

export { StringFormatHint, StatFormatTypes } from "./types";

export function getSoccerStatDefinitions(locale: string): Record<SoccerStatKeys, StatDef> {
  return {
    ...getGameSoccerStatDefs(locale),
    ...getPlayerSoccerStatDefs(locale),
    ...getTeamSoccerStatDefs(locale),
    ...getPositionNumSoccerStatDefs(locale)
  };
}

export type StatDef = PlayerStatDef | TeamStatDef | GameStatDef | PositionNumStatDef;

// i18n certified - complete

import { SoccerGameEventType, SoccerGameEvent } from "@ollie-sports/models";
import { SoccerEventMemoryDB } from "./SoccerEventMemoryDB";
import { SoccerCornerKickEventTypeObject } from "./SoccerEventCategories";
import { isOpponentTeamId } from "./SoccerFns";

interface BasicStats {
  shots: [number, number];
  shotsOnGoal: [number, number];
  cornerKicks: [number, number];
  fouls: [number, number];
  cards: [number, number];
}

export function extractBasicStats(memoryDB: SoccerEventMemoryDB): BasicStats {
  return {
    shots: getOwnTeamOpponentArrayPair(memoryDB.presets.shotEvents()),
    shotsOnGoal: getOwnTeamOpponentArrayPair(memoryDB.presets.shotEvents().filter(evt => evt.shotIsOnTarget)),
    cornerKicks: getOwnTeamOpponentArrayPair(memoryDB.find(SoccerCornerKickEventTypeObject)),
    fouls: getOwnTeamOpponentArrayPair(
      memoryDB.find({ [SoccerGameEventType.foulInfraction]: true, [SoccerGameEventType.handballInfraction]: true })
    ),
    cards: getOwnTeamOpponentArrayPair(
      memoryDB.find({ [SoccerGameEventType.redCardInfraction]: true, [SoccerGameEventType.yellowCardInfraction]: true })
    )
  };
}

function getOwnTeamOpponentArrayPair(events: Extract<SoccerGameEvent, { playerTeamId: string }>[]): [number, number] {
  return events.reduce(
    (acc, evt) => {
      if (isOpponentTeamId(evt.playerTeamId)) {
        acc[1]++;
      } else {
        acc[0]++;
      }
      return acc;
    },
    [0, 0]
  );
}

// i18n certified - complete

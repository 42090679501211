import {
  SoccerGameEvent,
  SoccerGameEventType,
  SGE_switchFormation,
  SGE_ownTeamGoal,
  SGE_shot,
  SGE_shotWithGoal
} from "@ollie-sports/models";
import _ from "lodash";
import Emittery from "emittery";
import {
  SoccerClockEvent,
  SoccerSubEvent,
  SoccerCardEvent,
  SoccerClockEventTypeObject,
  SoccerSubEventTypeObject,
  SoccerCardEventTypeObject,
  SoccerInfractionEventTypeObject,
  SoccerInfractionEvent,
  SoccerGoalEvent,
  SoccerGoalEventTypeObject,
  SoccerShotEventTypeObject,
  SoccerShotEvent,
  SoccerPauseEvent,
  SoccerResumeEvent,
  SoccerPauseOrResumeEventTypeObject
} from "./SoccerEventCategories";

export type SoccerEventMemoryDB = {
  insert: (events: SoccerGameEvent[]) => void;
  remove: (events: SoccerGameEvent[]) => void;
  edit: (events: SoccerGameEvent[]) => void;
  getById: (id: string) => SoccerGameEvent | null;
  getByCreatedAtMS: (id: number) => SoccerGameEvent | null;
  find<TypeObj extends Record<any, boolean>>(types: TypeObj): Extract<SoccerGameEvent, { type: keyof TypeObj }>[];
  findOneAfter<TypeObj extends Record<any, boolean>>(
    startingEvent: SoccerGameEvent,
    searchTypes: TypeObj
  ): Extract<SoccerGameEvent, { type: keyof TypeObj }> | null;
  getRawEvents: () => readonly SoccerGameEvent[];
  getRawEventsUpToAndIncluding: (event: SoccerGameEvent) => SoccerGameEvent[];
  presets: {
    clockEvents: () => SoccerClockEvent[];
    pauseAndResumeEvents: () => (SoccerResumeEvent | SoccerPauseEvent)[];
    subEvents: () => SoccerSubEvent[];
    cardEvents: () => SoccerCardEvent[];
    shotEvents: () => SoccerShotEvent[];
    goalEvents: () => SoccerGoalEvent[];
    formationEvents: () => SGE_switchFormation[];
    infractionEvents: () => SoccerInfractionEvent[];
  };
  onChange: (fn: (a: OnChangeProps) => void) => Unsubscribe;
};

type Unsubscribe = () => void;

type OnChangeProps = {
  addedEvents?: readonly SoccerGameEvent[];
  deletedEvents?: readonly SoccerGameEvent[];
  editedEvents?: readonly SoccerGameEvent[];
  previousAllEvents: readonly SoccerGameEvent[];
};

export function createSoccerEventMemoryDB(initialEvents: SoccerGameEvent[]) {
  const indexByType: { [key in SoccerGameEventType]?: SoccerGameEvent[] } = {};
  const indexById: { [key in string]?: SoccerGameEvent } = {};
  const indexByTimestamp: { [key in string]?: SoccerGameEvent } = {};

  /** Initialize */
  const rawEvents: SoccerGameEvent[] = initialEvents.slice();
  initialEvents.forEach(evt => {
    //indexByType
    const thisIndexByTypeArr = indexByType[evt.type] || [];
    indexByType[evt.type] = thisIndexByTypeArr;
    thisIndexByTypeArr.push(evt);
    //indexById
    indexById[evt.id] = evt;
    //indexByTimestamp
    indexByTimestamp[evt.createdAtMS] = evt;
  });

  /*** Declare Updater Function (it ensures changes also update the indices)**/
  let previousAllEvents = initialEvents;
  function updateDB(a: { editedEvents?: SoccerGameEvent[]; deletedEvents?: SoccerGameEvent[]; addedEvents?: SoccerGameEvent[] }) {
    //Set previous all events
    previousAllEvents = rawEvents.slice();
    if (a.editedEvents) {
      previousAllEvents = previousAllEvents.map(b => {
        if (a.editedEvents?.find(c => c.id === b.id)) {
          return _.cloneDeep(b); //Clone it so it doesn't get mutated
        }
        return b;
      });
    }

    //Update edited items
    if (a.editedEvents) {
      a.editedEvents.forEach(updatedEvent => {
        const evtToEdit = indexById[updatedEvent.id];
        if (evtToEdit) {
          //Do some chicanery to edit the event without changing the object reference
          Object.keys(evtToEdit).forEach(key => delete (evtToEdit as any)[key]);
          Object.assign(evtToEdit, updatedEvent);
        }
        //Note: we don't need to update the indices because we edited the object in place AND createdAtMS and event id are guaranteed to not change.
      });
    }

    //Update removed items
    if (a.deletedEvents) {
      a.deletedEvents.forEach(evt => {
        //indexById
        delete indexById[evt.id];
        //indexByTimestamp
        delete indexByTimestamp[evt.createdAtMS];
        //indexByType
        const siblingElements = indexByType[evt.type];
        const byTypeArrIndex = _.findLastIndex(siblingElements || [], b => b.id === evt.id);
        if (byTypeArrIndex !== -1) {
          siblingElements?.splice(byTypeArrIndex, 1);
        }
        //rawEvents
        const rawEventsArrIndex = _.findLastIndex(rawEvents, b => b.id === evt.id);
        if (rawEventsArrIndex !== -1) {
          rawEvents.splice(rawEventsArrIndex, 1);
        }
      });
    }

    //Update inserted items
    if (a.addedEvents) {
      a.addedEvents.forEach(evt => {
        evt = _.cloneDeep(evt); //Memory DB mutates events for efficiency. We must clone here so that these mutations don't accidentally leak back to the calling component
        //rawEvents
        let rawEventsInsertionIndex = _.findLastIndex(rawEvents, b => evt.createdAtMS > b.createdAtMS);
        rawEvents.splice(rawEventsInsertionIndex + 1, 0, evt);
        //indexByType
        const thisIndexByTypeArr = indexByType[evt.type] || [];
        indexByType[evt.type] = thisIndexByTypeArr;
        const indexByTypeInsertionIndex = _.findLastIndex(thisIndexByTypeArr, b => evt.createdAtMS > b.createdAtMS);
        thisIndexByTypeArr.splice(indexByTypeInsertionIndex + 1, 0, evt);
        //indexById
        indexById[evt.id] = evt;
        //indexByTimestamp
        indexByTimestamp[evt.createdAtMS] = evt;
      });
    }
  }

  const onChangeEventBus = new Emittery();
  function emitChange(a: Omit<OnChangeProps, "previousAllEvents">) {
    const changeObj: OnChangeProps = {
      ...a,
      previousAllEvents
    };
    onChangeEventBus.emit("change", changeObj);
  }

  const db: SoccerEventMemoryDB = {
    insert(events: SoccerGameEvent[]) {
      updateDB({ addedEvents: events });
      emitChange({ addedEvents: events });
    },
    remove(events: SoccerGameEvent[]) {
      updateDB({ deletedEvents: events });
      emitChange({ deletedEvents: events });
    },
    edit(events: SoccerGameEvent[]) {
      updateDB({ editedEvents: events });
      emitChange({ editedEvents: events });
    },
    getByCreatedAtMS(createdAtMS: number) {
      return indexByTimestamp[createdAtMS] || null;
    },
    getById(id: string) {
      return indexById[id] || null;
    },
    find<TypeObj extends Record<any, boolean>>(types: TypeObj, debug?: boolean) {
      return mergeSortedArrays(Object.keys(types).map(type => indexByType[type as SoccerGameEventType] || [])) as Extract<
        SoccerGameEvent,
        { type: keyof TypeObj }
      >[];
    },
    findOneAfter<TypeObj extends Record<any, boolean>>(startingEvt: SoccerGameEvent, types: TypeObj) {
      const startingIndex = _.sortedIndexBy(rawEvents, startingEvt, "createdAtMS");
      if (startingIndex !== -1) {
        for (let i = startingIndex; i < rawEvents.length; i++) {
          const searchEvt = rawEvents[i];
          if (types[searchEvt.type]) {
            return searchEvt as any;
          }
        }
      }
      return null;
    },
    getRawEvents() {
      return rawEvents;
    },
    getRawEventsUpToAndIncluding(event: SoccerGameEvent) {
      const eventIndex = _.sortedIndexBy(rawEvents, event, "createdAtMS");
      return rawEvents.slice(0, eventIndex + 1);
    },
    onChange(fn: (a: OnChangeProps) => void) {
      return onChangeEventBus.onAny((__, a) => {
        fn(a);
      });
    },
    presets: {
      clockEvents: () => db.find(SoccerClockEventTypeObject),
      shotEvents: () => db.find(SoccerShotEventTypeObject),
      goalEvents: () => db.find(SoccerGoalEventTypeObject),
      pauseAndResumeEvents: () => db.find(SoccerPauseOrResumeEventTypeObject),
      subEvents: () => db.find(SoccerSubEventTypeObject),
      cardEvents: () => db.find(SoccerCardEventTypeObject),
      formationEvents: () => db.find({ [SoccerGameEventType.switchFormation]: true }),
      infractionEvents: () => db.find(SoccerInfractionEventTypeObject)
    }
  };

  return db;
}

function mergeSortedArrays(arrays: SoccerGameEvent[][]) {
  arrays = arrays.filter(a => a.length);
  if (arrays.length === 1) {
    return arrays[0].slice();
  }

  return arrays.reduce((acc, arr) => {
    return mergeTwo(acc, arr);
  }, [] as SoccerGameEvent[]);
}

function mergeTwo(arr1: SoccerGameEvent[], arr2: SoccerGameEvent[]) {
  let merged = [];
  let index1 = 0;
  let index2 = 0;
  let current = 0;

  while (current < arr1.length + arr2.length) {
    let isArr1Depleted = index1 >= arr1.length;
    let isArr2Depleted = index2 >= arr2.length;

    if (!isArr1Depleted && (isArr2Depleted || arr1[index1].createdAtMS < arr2[index2].createdAtMS)) {
      merged[current] = arr1[index1];
      index1++;
    } else {
      merged[current] = arr2[index2];
      index2++;
    }

    current++;
  }

  return merged;
}

// i18n certified - complete
